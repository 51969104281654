import {useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {useEffect, useState} from "react";
import {Alert, alpha, Button, Container, Grid, Stack} from "@mui/material";
import PuzzleCard from "../components/puzzle-card/PuzzleCard";
import PuzzleListMenu from "../components/PuzzleListMenu";
import Typography from "@mui/material/Typography";
import {resolveErrorMessage} from "../utils/errors";
import {isGuestFromStore} from "../util/guestStorage";
import {useTranslation} from "react-i18next";
import LangModal from "../components/LangModal";
import ResultsDialog from "../components/results-dialog/ResultsDialog";
import InstructionsModal from "../components/InstructionsModal";
import gameRoomApiV2 from "../api/gameRoomApiV2";
import {useRoomContext} from "../context/RoomContextProvider";

export default function PuzzleListPage() {
    const { myMemberId } = useRoomContext();
    const { roomId } = useParams();

    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [showLangModal, setShowLangModal] = useState(false);
    const [showResultsDialog, setShowResultsDialog] = useState(false);
    const { t, i18n } = useTranslation();
    const { t: puzzleTrans } = useTranslation('puzzle');
    const [instructionsOpen, setInstructionsOpen] = useState(false);

    const toggleInstructionModal = () => {
        setInstructionsOpen(prevOpen => !prevOpen)
    }

    useEffect(() => {
        gameRoomApiV2.getRoomDetails(roomId, myMemberId)
            .then(d => {
                setData(d);
                if (d?.lang === null) {
                    setShowLangModal(true);
                } else {
                    i18n.changeLanguage(d?.lang);
                }
            })
            .catch(({ response }) => {
                navigate('/error', { state: { title: resolveErrorMessage(response) }})
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomId, navigate, i18n]);

    const getPlayText = () => {
        if (data?.completedPuzzles > 0) {
            return t('continue');
        }
        return t('playNow');
    }

    const onPlayClick = () => {
        const isGuestStore = isGuestFromStore();
        if (isGuestStore === true) {
            console.log("Navigating to game as a guest! ", isGuestStore)
            let url = new URL(data?.playUrl, window.location.origin);

            // Modify only the pathname
            url.pathname = `${url.pathname}/guest`;

            // The search parameters are retained automatically, including any additional ones
            window.location.href = url.href;
            return;
        }
        console.log("Navigating to game as main player! ", isGuestStore)
        navigate(data?.playUrl);
    }

    const onResultsClick = () => {
        setShowResultsDialog(true);
    }

    const getActionBtn = (onClick, text) => (
        <Button
            fullWidth
            size="large"
            variant="contained"
            color="success"
            sx={{marginBottom: 2}}
            onClick={onClick}
        >
            {text}
        </Button>
    )

    const resolveTranslationPrefix = (gameTitle) => {
        switch (gameTitle) {
            case 'A Toxic Case': {
                return 'translate.game.toxic_case'
            }
            case 'Paradise': {
                return 'translate.game.paradise'
            }
            default: {
                return 'translate.game.toxic_case'
            }
        }
    }

    return (
        <>
        <InstructionsModal
            open={instructionsOpen}
            toggle={toggleInstructionModal}
            translationPrefix={resolveTranslationPrefix(data?.gameTitle)}
        />
        <PuzzleListMenu toggleInstructionModal={toggleInstructionModal} />
        <ResultsDialog
            results={{gameStatsByMemberId: data?.gameStatsByMemberId, nextPuzzleLink: null }}
            open={showResultsDialog}
            onClose={() => setShowResultsDialog(false)}
            allowToUnlockNextPuzzle={false}
            playerNames={data?.playerNames}
            gameName={data?.gameTitle}
            initShowGrandResult
        />
        <Stack sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }} spacing={2}>
            <Typography variant="h3" component="div" align="center" sx={{ color: 'white' }}>
                {data?.gameTitle}
            </Typography>
            { data?.totalPuzzles && <Typography variant="h5" component="div" align="center" sx={{ color: alpha('#fff', 0.6) }}>
                {t('completed')}{` ${data?.completedPuzzles} / ${data?.totalPuzzles}`}
            </Typography> }
        </Stack>
        <Container sx={{marginTop: 2, marginBottom: 2 }}>
            { <LangModal open={showLangModal} callback={() => setShowLangModal(false)} /> }
            { (data?.completedPuzzles !== data?.totalPuzzles) && data?.playUrl && showLangModal === false  && !data?.isKicked
                && getActionBtn(onPlayClick, getPlayText())
            }
            {data?.isKicked && (
                <Alert severity="error" sx={{ marginBottom: 2 }}>
                    {t('kickedAlert')}
                </Alert>
            )}
            { data?.playUrl === null && data?.gameStatsByMemberId !== null && showLangModal === false && getActionBtn(onResultsClick, t('seeResults'))}
            { data?.puzzles?.length === 0 && <Alert severity="warning">{t('noPuzzles')}</Alert> }
            <Stack sx={{ background: '#192235', border: '2px solid rgba(255, 255, 255, 0.06)'}} p={1.25} borderRadius="20px">
                <Grid container spacing={2}>
                    {data?.puzzles?.map(p => {
                        return (
                        <Grid key={p.id} item xs={12} md={6}>
                            <PuzzleCard
                                gameTitle={puzzleTrans(data?.gameTitle)}
                                puzzleName={puzzleTrans(p.name)}
                                cover={puzzleTrans(p.cover)}
                                puzzleId={p.id}
                                roomId={roomId}
                                expiresAt={p.expiresAt}
                                completedIn={p.completedIn}
                                difficulty={p.difficulty}
                                playerStats={p.playerStats}
                            />
                        </Grid>
                    )})}
                </Grid>
            </Stack>
        </Container>
        </>
    );
}

import { coreClient } from './config';

const versionPrefix = '/v2/game';

const gameRoomApiV2 = {
    getRoomDetails: (roomId, memberId) => coreClient
            .get(`${versionPrefix}/rooms/${roomId}`.concat(memberId ? `?memberId=${memberId}` : ''))
            .then(({data}) => data),
};
export default gameRoomApiV2;

import {Stack} from "@mui/material";
import * as React from "react";
import GameSeat from "./GameSeat";
import {useRoomContext} from "../../context/RoomContextProvider";
import {usePuzzleContext} from "../../context/PuzzleContextProvider";

const PuzzleSeatList = () => {
    const { myMemberId, isHost } = useRoomContext();
    const { puzzleStateResult } = usePuzzleContext();
    const { data } = puzzleStateResult;

    if (!data) {
        return null;
    }

    const getPreviousSeatOccupant = (index) => {
        const prevPlayer = data.previousPuzzlePlayers?.find(it => it.seat === index);
        if (!prevPlayer) {
            return undefined;
        }
        return ({
            ...prevPlayer,
            isPrevious: true
        });
    }

    const seats = [...Array(data.maxPlayers)]
        .map((it, index) => data.players.find(it => it.seat === index)
            || getPreviousSeatOccupant(index))


    const sortedSeats = seats.sort((a, b) => {
        // Handle undefined objects and move them to the end
        if (!a || !b) {
            return a ? -1 : 1;
        }

        // Sort by isGuest (false should come first)
        if (a.isGuest !== b.isGuest) {
            return a.isGuest ? 1 : -1;
        }

        // Sort by name alphabetically (if isGuest is the same)
        if (a.name && b.name) {
            return a.name.localeCompare(b.name);
        }

        // If one name is undefined, the defined one goes first
        if (!a.name) return 1;
        if (!b.name) return -1;

        return 0;
    });

    return (
        <Stack gap={1} py={1}>
            {sortedSeats.map((it, index) => [
                <GameSeat
                    player={it}
                    key={index}
                    myMemberId={myMemberId}
                    showHelp={isHost && data?.players.length >= 2}
                />
            ])}
        </Stack>
    )
}

export default PuzzleSeatList;
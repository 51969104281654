import useJoinRoom from "../hooks/useJoinRoom";
import useIsGuest from "../hooks/useIsGuest";
import * as React from "react";
import {createContext, useContext, useEffect, useMemo} from "react";
import {useParams} from "react-router-dom";
import useKickMember from "../hooks/useKickMember";

export const RoomContext = createContext({});

export const useRoomContext = () => useContext(RoomContext);

const RoomContextProvider = ({children}) => {
    const {gameId: roomId} = useParams();
    const {isGuest, isHost} = useIsGuest();
    const {
        myMemberId,
        joinRoomAsGuest,
        joinRoomAsHost,
        loading,
        error,
    } = useJoinRoom();

    const {
        mutate: kickMember,
        isPending: kickMemberLoading,
        isSuccess: kickMemberSuccess,
        reset
    } = useKickMember();

    useEffect(() => {
        if (kickMemberSuccess) {
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kickMemberSuccess]);


    useEffect(() => {
        if (isHost && roomId) {
            joinRoomAsHost();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHost, roomId])

    const roomContextValues = useMemo(() => (
            {
                roomId,
                joinRoomAsGuest,
                joinRoomLoading: loading,
                joinRoomError: error,
                myMemberId,
                isGuest,
                isHost,
                kickMember,
                kickMemberLoading,
                kickMemberSuccess,
                kickMemberReset: reset,
            }
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            roomId,
            joinRoomAsGuest,
            myMemberId,
            loading,
            error,
            isGuest,
            kickMember,
            kickMemberLoading,
            kickMemberSuccess,
        ]);

    return (
        <RoomContext.Provider value={roomContextValues}>
            {children}
        </RoomContext.Provider>
    )
}

export default RoomContextProvider;
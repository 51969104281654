import {alpha, Button, Stack} from "@mui/material";
import {modalStyle} from "../../style/modalStyle";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import * as React from "react";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {usePuzzleContext} from "../../context/PuzzleContextProvider";
import gameApiV2 from "../../api/gameApiV2";
import {resolveErrorMessage} from "../../utils/errors";
import {useRoomContext} from "../../context/RoomContextProvider";
import {useNavigate} from "react-router-dom";

const OngoingGameInviteModal = ({ open, onClose}) => {
    const { t } = useTranslation('ongoingGameInviteModal');
    const {roomId} = useRoomContext();
    const {puzzleId} = usePuzzleContext();
    const [loading, setLoading] = useState(false);
    const [showRestarted, setShowRestarted] = useState(false);
    const navigate = useNavigate();

    const onPuzzleRestart = () => {
        setLoading(true)
        gameApiV2.restartPuzzle(roomId, puzzleId)
            .then(() => setShowRestarted(true))
            .catch(({ response }) => navigate('/error', { state: { title: resolveErrorMessage(response) }}))
            .finally(() => setLoading(false))
    }

    const onContinue = () => {
        onClose();
        window.location.reload();
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            slotProps={{ backdrop: { sx: { background: alpha('#000', 0.9) }} }}
        >
            <Stack sx={modalStyle} gap={2}>
                <Typography variant="h6" component="h2">
                    {showRestarted
                        ? t('restarted.title')
                        : t('invite.title')
                    }
                </Typography>
                {!showRestarted && (
                    <Stack gap={2}>
                        <Typography variant="body6" sx={{color: alpha('#fff', 0.6)}}>
                            {t('invite.description')}
                        </Typography>

                        <Typography variant="body6" sx={{color: alpha('#fff', 0.6)}}>
                            {t('invite.areYouSure')}
                        </Typography>

                        <Stack direction="row" gap={1}>
                            <Button variant="outlined" onClick={onClose} fullWidth disabled={loading}>
                                {t('invite.buttonCancel')}
                            </Button>
                            <Button variant="contained" onClick={onPuzzleRestart} fullWidth disabled={loading}>
                                {t('invite.buttonConfirm')}
                            </Button>
                        </Stack>
                    </Stack>
                )}
                {showRestarted && (
                    <Stack gap={2}>
                        <Typography id="modal-problem-body-1" variant="body6" sx={{color: alpha('#fff', 0.6)}}>
                            {t('restarted.description')}
                        </Typography>
                        <Button variant="contained" onClick={onContinue} fullWidth disabled={loading}>
                            {t('restarted.buttonContinue')}
                        </Button>
                    </Stack>
                )}
            </Stack>
        </Modal>
    )
}

export default OngoingGameInviteModal;
import React from 'react';
import {alpha, Stack, Typography} from '@mui/material';
import {Trans, useTranslation} from "react-i18next";

export default function TermsAndConditionsPage() {
    const { t } = useTranslation('termsConditions');
    return (
        <Stack
            py={3}
            px={2}
            sx={{
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                minHeight: '100vh',
            }}
            gap={2}
        >
            <Typography variant="h3" component="h1" sx={{ color: 'white' }}>
                {t('title')}
            </Typography>
            <Stack width="100%">
                <Typography variant="body6" sx={{ color: alpha('#fff', 0.6) }} m={1}>
                    <Trans i18nKey={t('body')}/>
                </Typography>
            </Stack>
        </Stack>
    );
}

const enPuzzle = (
    {
        'translate.game.toxic_case.instructions.instruction1': `
            How to play<br/>
            <br/>A Toxic Case is a cooperative escape room about a missing person case. To play the game, each player needs their own smartphone.
            <br/>Each player will receive different information about the case and a question they will need to answer. Together you will watch a video about the case. You will need to work together to solve the challenge.
            <br/>Each video has an introduction, which is part of the story and then shows information about the question you have to answer. The information is repeated multiple times, so you won't have to restart the video.
        `,
        'translate.game.toxic_case.instructions.instruction2': `
            Rules and options<br/>
            <br/>When you start a puzzle, a timer will start. You can try to solve the challenges as fast as possible in order to receive the most points. Or you can ignore the score and take as long as you like.
            <br/>If you don't understand a challenge, you can get a hint. Each challenge has an explanation hint, which tells you more about what you need to do, and two hints that help you with the actual puzzle.
            <br/>If the hints are not enough to solve the puzzle, you can click the Solution option to read the answer.
            <br/>Your score is determined by the time you spent, wrong answers and which hints were used. It does not matter if more people look at the same hint.
            <br/>If you experience any problems, please ask the main player to press Problem. This will reset the challenge.
        `,
        'translate.game.toxic_case.instructions.instruction3': `
            Video setup<br/>
            <br/>There are two ways that you can watch the videos, on Vimeo (recommended) or Youtube. We recommend you watch them on your TV, but you can use any other screen as well.
            <br/>For Vimeo, we suggest using another device if you have one. Go to vimeo.com/showcase/11475329, click Start Watching and stream it to your television.
            <br/>For Youtube, you can enter the following link in your browser: t.ly/wQQRY . 
            <br/>After you completed this setup you can click continue and start playing the game.
         `,
        'translate.game.toxic_case.instructions.btnText': 'Continue',

        'translate.game.toxic_case.puzzle_name.intro1': "Intro 1",
        'translate.game.toxic_case.puzzle_name.intro2': "Intro 2",
        'translate.game.toxic_case.puzzle_name.deceased_persons': "Deceased people",
        'translate.game.toxic_case.puzzle_name.drone': "Drone",
        'translate.game.toxic_case.puzzle_name.enclose': "Enclose",
        'translate.game.toxic_case.puzzle_name.open_the_door': "Open the door",
        'translate.game.toxic_case.puzzle_name.password': "Password",
        'translate.game.toxic_case.puzzle_name.poison': "Poison",
        'translate.game.toxic_case.puzzle_name.who_did_it': "Who did it",


        'translate.toxic_case.1_intro.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/coverintro1.webp",

        'translate.toxic_case.1_intro.2p.message.title': "Hi {{playerName}}! Welcome to Couch Clues! Before you start, make sure that both players have a pen and paper.  You will learn the game by playing 2 quick intro challenges with {{partnerName}}. Open the menu on the top right of this screen, read the instructions and set up the video.",
        'translate.toxic_case.1_intro.345p.message.title': "Hi {{playerName}}! Welcome to Couch Clues! Before you start, make sure that each player has a pen and paper.  You will learn the game by playing 2 quick intro challenges with your fellow player(s). Open the menu on the top right of this screen, read the instructions and set up the video.",
        'translate.toxic_case.1_intro.message2.title': "Have you set up the video? Then start the Playlist A Toxic Case. It should start with Intro 1. Select ENG subtitles and follow the instructions on the Intro 1 video. Your goal is to send me a 6 digit code.",
        'translate.toxic_case.1_intro.message2.errors': "That is not the right answer, but don't worry, this is just practice. Just a quick check. Are you both watching Youtube or Vimeo? Have you found the channel 'CouchClues' and the Playlist 'A Toxic Case'? And are you watching Intro 1? Then open the menu on the top right of your app, open explanation, then hint 1 and then hint 2.",
        'translate.toxic_case.1_intro.message3.title': "Well done! Now you can pause the video and click the results button below.",

        'translate.toxic_case.1_intro.hint.explanation': "Every puzzle has an explanation. An explanation will explain the puzzle if you have no idea what to do. For this intro 1 challenge, you have to find the same 6 digit number. To find it, all should click hint 1.",
        'translate.toxic_case.1_intro.23p.player.hint.hint1': "The first two digits of the code are 96. Make sure to share this information. Communication is key in this game! Now check hint 2.",
        'translate.toxic_case.1_intro.2p.guest01.hint.hint1': "The last two digits are 32. Make sure to share this with your partner. This game is all about talking to each other! Now check hint 2.",
        'translate.toxic_case.1_intro.345p.guest01.hint.hint1': "The 5th digit is a 3. Make sure to share this with your partners. Communication is key in this game! Now check hint 2.",
        'translate.toxic_case.1_intro.345p.guest02.hint.hint1': "The last digit is a 2. Make sure to share this with your partners. Communication is key in this game! Now check hint 2.",
        'translate.toxic_case.1_intro.45p.player.hint.hint1': "The first digit is a 9. Make sure to share this with your partners. Communication is key in this game! Now check hint 2.",
        'translate.toxic_case.1_intro.45p.guest03.hint.hint1': "The second digit is a 6. Make sure to share this with your partners. Communication is key in this game! Now check hint 2.",
        'translate.toxic_case.1_intro.5p.guest04.hint.hint1': "The third digit is a 1. Make sure to share this with your partner. Communication is key in this game! Now check hint 2.",

        'translate.toxic_case.1_intro.2p.hint.hint2': "Did your partner tell you the other numbers? The middle digits are 11. You can now solve the puzzle, or check the solution to be sure. If you choose to view the solution in the actual game, you won't earn any points for that challenge.",
        'translate.toxic_case.1_intro.34p.hint.hint2': "Did your partners tell you the other numbers? The middle digits are 11. You can now solve the puzzle, or check the solution to be sure. If you choose to view the solution in the actual game, you won't earn any points for that challenge.",
        'translate.toxic_case.1_intro.5p.hint.hint2': "Did your partners tell you the other numbers? The fourth digit is 1. You can now solve the puzzle, or check the solution to be sure. If you choose to view the solution in the actual game, you won't earn any points for that challenge.",

        'translate.toxic_case.1_intro.234p.hint.solution': "The solution is 961132. <br> Each player received a different first hint showing part of the code. If you shared all the information you would have gotten the first two digits (96) and the last two digits (32). The second hint showed the same information for all, which is that the middle digits were 11.",
        'translate.toxic_case.1_intro.5p.hint.solution': "The solution is 961132. <br> Each player received a different first hint showing part of the code. If you shared all the information you would have gotten the first three digits (961) and the last two digits (32). The second hint showed the same information for all, which is that the fourth is a 1.",


        'translate.toxic_case.2_intro.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/coverintro2.webp",

        'translate.toxic_case.2_intro.timer.title': "Intro 2 - A Toxic Case",
        'translate.toxic_case.2_intro.timer.subtitle': "You will receive more information in:",
        'translate.toxic_case.2_intro.message1.title': "Now it's time for a real practice puzzle. You will receive some images below, try to zoom in on them by tapping on the image and then pinching your fingers.",
        'translate.toxic_case.2_intro.message2.title': "Look at the Intro 2 video. Your goal is to find a five digit code.",
        'translate.toxic_case.2_intro.message2.error': "That is not the right code. To try again you have to use the TAG ANSWERS link in the message above to select a different answer.",
        'translate.toxic_case.2_intro.message3.title': "That's right! You will now continue to the real game. Have fun together!",

        'translate.toxic_case.2_intro.timer.message1': 'During the game you sometimes get an additional message like this one. These messages contain information from the detective that can help you. If you do not want to receive these messages, the main player can turn them off in the menu.',

        'translate.toxic_case.2_intro.hint.explanation': "Your goal is to find a 5 digit number. You will have to tell each other what you see on your phone and match that with what you see on television. Select the labels in the right order and press submit.",
        'translate.toxic_case.2_intro.hint.hint1': "The first digit is 5.",
        'translate.toxic_case.2_intro.hint.hint2': "The order on the television is Yellow, Red, Blue, Green, Grey. Which numbers match these colours?",
        'translate.toxic_case.2_intro.hint.solution': "The solution is Five, Nine, Seven, One, Four. You will have to select the labels in that order. <br> The order of the colours on the television is Yellow, Red, Blue, Green,Grey. The yellow number is a 5, the red number is a 9, the blue number a 7, the green number a 1 and the grey number a 4.",

        'translate.toxic_case.2_intro.four.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/intro2four.webp",
        'translate.toxic_case.2_intro.five.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/intro2five.webp",
        'translate.toxic_case.2_intro.seven.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/intro2seven.webp",
        'translate.toxic_case.2_intro.one.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/intro2one.webp",
        'translate.toxic_case.2_intro.nine.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/intro2nine.webp",

        'translate.toxic_case.2_intro.option1': "One",
        'translate.toxic_case.2_intro.option2': "Two",
        'translate.toxic_case.2_intro.option3': "Three",
        'translate.toxic_case.2_intro.option4': "Four",
        'translate.toxic_case.2_intro.option5': "Five",
        'translate.toxic_case.2_intro.option6': "Six",
        'translate.toxic_case.2_intro.option7': "Seven",
        'translate.toxic_case.2_intro.option8': "Eight",
        'translate.toxic_case.2_intro.option9': "Nine",
        'translate.toxic_case.2_intro.option10': "Zero",


        'translate.open_the_door.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/1cover.webp",

        'translate.open_the_door.timer.title': "1. Open the Door - A Toxic Case",
        'translate.open_the_door.timer.subtitle': "The challenge will start in:",
        'translate.open_the_door.message1.title': "We need to get in. He texted me over the last couple of days, but his previous codes aren't working. I'll send you his messages.",
        'translate.open_the_door.message2.title': "I need the code he used today to open his door. The code has 4 digits.",
        'translate.open_the_door.message2.error': "The door doesn't open. Is there another code you can think of?",
        'translate.open_the_door.message3.title': "Thanks! That's the right code, I can open the door now.",

        'translate.open_the_door.timer.message1': 'You may have already noticed this, but on the footage I see him doing the same four things each day. Is there a connection between the order he does things and the code?',
        'translate.open_the_door.timer.message2': 'I think I found something. If you look at the number 4. You see that it is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. On the security footage, the first thing he does on Friday, the last thing he does on Saturday and the second thing he does on Sunday are all the same.',
        'translate.open_the_door.timer.message3': 'Check the clock times. He is using the time on the clock as part of the code for that day.',

        'translate.open_the_door.hint.explanation': "You need to enter a 4-digit code. To do this you need to match the codes from Friday, Saturday and Sunday to the security footage from those days. Each day, John does four things. Each number he uses on a day is connected to one of the things that he is doing. You have to find out how this works and what the code for today would be.",
        'translate.open_the_door_challenging.hint.explanation': "You need to enter a 4-digit code. To do this you need to match the codes from Friday, Saturday and Sunday to the security footage from those days. Each day, John does four things. Do you see a pattern? And can you connect this to the door codes?",

        'translate.open_the_door.hint.hint1': "The numbers 1,4 and 9 are used each day. Only the other number differs. If you look at the number 4. You see that it is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. On the security footage, the first thing he does on Friday, the last thing he does on Saturday and the second thing he does on Sunday are all the same.",
        'translate.open_the_door.hint.hint2': "The time on the clock can be used to determine one of the numbers of the code for that day. For example, if the time on one of the days was 8 o'clock, one of the numbers will be an 8.",
        'translate.open_the_door_challenging.hint.hint1': "If you look at the number 4. You see that it is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. Can you match this to the security footage?",
        'translate.open_the_door_challenging.hint.hint2': "John uses the time on the clock as part of his code for that day",
        'translate.open_the_door.hint.solution': "1459 is the code for today. <br> Each day, John does four things in a different order: blowing out a candle, looking at the clock, wiping his feet and hanging something on the coat rack. Each of these 4 things are connected to a number. He blows out a candle first on Friday, last on Saturday and second on Sunday. The number 4 is the first number in the code for Friday, the last number in the code for Saturday and the second number in the code for Sunday. So blowing out the candle matches the number 4 and the position is determined by the order he blew out the candles that day. So the second number for today is 4. This works the same for the other 3 numbers, the only difference is looking at the clock. Here he uses the time on the clock as the number for that day. So Friday the last thing he did was looking at the clock, the time was 8. So the code for Friday ends with 8. Saturday the second number was 2, Sunday the third number was a 6 and today the third number is 5.",

        'translate.open_the_door.friday.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/1friday.webp",
        'translate.open_the_door.saturday.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/1saturday.webp",
        'translate.open_the_door.sunday.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/1sunday.webp",


        'translate.deceased_person.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2cover.webp",

        'translate.deceased_person.timer.title': "2. Deceased persons - A Toxic Case",
        'translate.deceased_person.timer.subtitle': "The challenge will start in:",
        'translate.deceased_person.timer.message1': 'I went through more of his things. He noted that you can be certain 9 people were not poisoned. If you have 3 people left, remember the rule that only one has a heart condition.',
        'translate.deceased_person.timer.message2': 'On the back of the photo of Simon Carlson, it says that he was poisoned. Can you figure out who the other one is?',

        'translate.deceased_person.message1.title': "What a mess. He was researching unexplained deaths, apparently 2 of them were poisoned. I'll send you the notes he made.",
        'translate.deceased_person.message2.title': "Let me know if you figure out which two were poisoned. I will try to look around in the meantime.",
        'translate.deceased_person.message2.error': "I checked, but I think this contradicts some of his notes. Can you check again for me, using the TAG ANSWER link above?",
        'translate.deceased_person.message3.title': "Yes, that makes sense. Now, let’s see out why he was investigating these two.",

        'translate.deceased_person.hint.explanation': "On the screen you see images of 12 people. Your goal is to deduce which 2 of them were poisoned. Each player has different descriptions and you will need all descriptions to find the two people you are looking for. Focus first on the age difference and the people that have a heart condition.",
        'translate.deceased_person.hint.hint1': "Focus first on eliminating the 9 people who were not poisoned. For the last three, remember the rule that only one has a heart condition.",
        'translate.deceased_person.hint.hint2': "One of the 2 poisoned people is Simon Carlson",
        'translate.deceased_person.hint.solution': "Simon and Sam. <br> People that have tattoos on their face, have glasses (an accident, so they were not poisoned) and Jim and Rahul (they don't have prescriptions) are excluded. Then, if you focus on 30 years apart, one of the two has to be Simon. Simon has a heart condition (earring), which means that the younger person does not have a heart condition (no earring). So it must be Sam.",

        'translate.deceased_person_challenging.hint.hint1': "Charlie Yang and Joe Lopez have not been poisoned.",
        'translate.deceased_person_challenging.hint.hint2': "One of the 2 poisoned people is Simon Carlson",
        'translate.deceased_person_challenging.hint.solution': "Simon and Sam. <br> People that are blonde, have glasses (an accident, so they were not poisoned) and Rahul (he does not have prescriptions) are excluded. Then, if you focus on 30 years apart, one of the two has to be Joe or Simon. If it is Joe, the younger person does not have tattoos (both cannot have tattoos) and has to have a heart condition. Only Charlie matches this, but he has a tattoo. This means that it cannot be Joe and one of the two must be Simon. This means that the younger person does not have a heart condition and cannot be family (not Jim). So it must be Sam.",

        'translate.deceased_person.list.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2list.webp",
        'translate.deceased_person.glasses.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2glasses.webp",
        'translate.deceased_person.30.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/230.webp",
        'translate.deceased_person.rahuljim.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2rahuljim.webp",
        'translate.deceased_person.earrings.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2earrings.webp",
        'translate.deceased_person.tattooface.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2tattooface.webp",
        'translate.deceased_person.oneheart.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2oneheart.webp",
        'translate.deceased_person.prescription.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2prescription.webp",

        'translate.deceased_person_challenging.onenottattoo.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2onenottattoo.webp",
        'translate.deceased_person_challenging.nephew.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2nephew.webp",
        'translate.deceased_person_challenging.blonde.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2blonde.webp",
        'translate.deceased_person_challenging.rahul.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2rahul.webp",
        'translate.deceased_person_challenging.notfamily.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2notfamily.webp",


        'translate.password.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3cover.webp",

        'translate.password.timer.title': "3. Password - A Toxic Case",
        'translate.password.timer.subtitle': "The challenge will start in:",
        'translate.password.message1.title': "Can't login. He left these notes for me, I'll send them over.",
        'translate.password.message2.title': "We need a 5 letter password. I think we need to find 5 of these items and use one letter from each item to make a word. But which item matches which symbol?",
        'translate.password_challenging.message2.title': "I think that we are looking for a 5 letter password. Can you figure it out?",
        'translate.password.message2.error': "Wrong password. We have to try again.",
        'translate.password.message3.title': "Awake? Anyway, I'm in. Let's see what he has been up to.",

        'translate.password.timer.message1': 'Maybe you already figured this out, but I think he left these markers intentionally. But how are these markers connected to the symbols?',
        'translate.password.timer.message2': 'There are 5 markers scattered around. One of them is pointing at a watch. The watch is in the spot marked by the X.',
        'translate.password.timer.message3': 'I think the markers are pointing at a watch, a bowl, a vase, a book and a bottle. For watch the 2nd letter is an "a", so the word should start with "a"',

        'translate.password.hint.explanation': "We are looking for a 5 letter word. To find it you need to find 5 different items that are located in the room. A photo gives a clue how to find the 5 different items. There is a map that tells you more about their position in the room. The other note shows you which letter to use from each item.",
        'translate.password.hint.hint1': "Focus on the markers. There are 5 of them in the room. One of them is pointing at a watch. The watch is located in the spot marked by the X.",
        'translate.password.hint.hint2': "One marker is pointing at a watch on position X, so we need to use the 2nd letter: 'a'. The other markers point at a bowl, a vase, a book and a bottle.",
        'translate.password_challenging.hint.explanation': "We are looking for a 5 letter word. To find it you need to find 5 different items that are located in the room and use one letter from each of these items. You have a photo that helps you to find the 5 items and a note to determine the position of these items in the room.",
        'translate.password_challenging.hint.hint1': "Focus on finding the 5 markers. Where are they located and where do they point to?",
        'translate.password_challenging.hint.hint2': "A marker points to a watch on location X. The 2nd letter of watch is an A, so the 5 letter word that you are looking for starts with an A.",

        'translate.password.hint.solution': "The password is awake. <br> One note shows a rough layout of the room. The symbols tell you where the markers are located. On each location there is a marker pointing at an item that you need. The other note, different for each player, shows which letter you need from each item. The location of the X, has a marker pointing at the watch. The 2nd letter of watch is a. The marker in the location of the triangle, points at a bowl (3rd letter of bowl: w). I is the marker pointing at the vase (2nd letter of vase: a), the dot points at a book (4th letter of book:k), and the marker on the location of the square symbol points at a bottle (6th letter of bottle: e).",

        'translate.password.marker.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3marker.webp",
        'translate.password.map.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3map.webp",
        'translate.password_challenging.cmap.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3cmap.webp",
        'translate.password.itemsthread.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3itemsthread.webp",
        'translate.password.symbols135.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3symbols135.webp",
        'translate.password.itemsplant.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3itemsplant.webp",
        'translate.password.symbols24.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3symbols24.webp",
        'translate.password.symbols4.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3symbols4.webp",
        'translate.password.symbols15.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3symbols15.webp",
        'translate.password.symbols1.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3symbols1.webp",
        'translate.password.symbols5.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3symbols5.webp",
        'translate.password.symbols2.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3symbols2.webp",
        'translate.password.symbols3.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3symbols3.webp",


        'translate.drone.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4cover.webp",

        'translate.drone.timer.title': "4. Drone - A Toxic Case",
        'translate.drone.timer.subtitle': "The challenge will start in:",
        'translate.drone.2p.message1.title': "This area is patrolled by a drone. I've managed to hack the footage, but I still don't know how to get in undetected. John made two sketches of the area, one of the office buildings and the other of the research facilities. I will send you and {{partnerName}} one of these sketches each. ",
        'translate.drone.345p.message1.title': "This area is patrolled by a drone. I've managed to hack the footage, but I still don't know how to get in undetected. I will send you a sketch that John made of the area.",
        'translate.drone.message2.title': "The drone flies through 6 zones. Let me know which zones and in what order, so I can find a way to evade the drone.",
        'translate.drone.message2.error': "I saw the drone moving in a different zone than you said. We have to be sure. Please check again. Use the TAG ANSWER link above and let me know.",
        'translate.drone.message3.title': "Thanks. I'm going to make a run for it.",

        'translate.drone.timer.message1': 'I focused on the roads and identified the first two zones. The drone first flies over zone Y and then over zone L.',
        'translate.drone.timer.message2': 'There are some zones where the drone does not fly over. These are zones C, E, P, Q, T and V. I still need to figure out the correct order for the other zones before I can move.',

        'translate.drone.hint.explanation': "The drone flies in 6 zones. Figure out the correct order by analyzing the roads and the shape of the buildings.",
        'translate.drone_challenging.hint.explanation': "The drone flies in 6 zones. Figure out the correct order by analyzing the shape of the buildings.",

        'translate.drone.hint.hint1': "The first zone is Y and the second zone is L.",
        'translate.drone.hint.hint2': "The zones that are not shown are C, E, P, Q, T and V.",
        'translate.drone.hint.solution': "The correct order is Y - L - B - A - M - S. <br> The first zone you see three buildings, two smaller ones and a bigger one in the center, with grass to the right. The building on the right seems to be missing a block in the top right and bottom left of the building. The second zone has a slanted road, so it must be zone L. The third zone shows 3 buildings, with one in the middle with a recognizable shape. The fourth zone you see a very big building. The fifth zone shows three buildings decreasing in size from left to right and the sixth zone shows two buildings with shapes that can be recognized in zone S.",

        'translate.drone.mapred.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4mapred.webp",
        'translate.drone.mapblue.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4mapblue.webp",
        'translate.drone.mapall.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4mapall.webp",

        'translate.drone_challenging.cmaprednoroads.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4cmaprednoroads.webp",
        'translate.drone_challenging.cmapbluenoroads.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4cmapbluenoroads.webp",
        'translate.drone_challenging.cmapnoroads.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4cmapnoroads.webp",


        'translate.poison.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/5cover.webp",

        'translate.poison.timer.title': "5. Poison - A Toxic Case",
        'translate.poison.timer.subtitle': "The challenge will start in:",

        'translate.poison.message1.title': "He is dead. There is no sign of blunt trauma, so I suspect he was poisoned. I'll examine the scene. Here is some information about possible poisons.",
        'translate.poison.message2.title': "Can you find a poison that matches this situation? Just send me the number.",
        'translate.poison.message2.error': "That poison doesn't match what I'm seeing at the crime scene. We're missing something...",
        'translate.poison.message3.title': "This matches all his symptoms. I think it is the same poison that killed the other two people. This isn’t an off-the-shelf poison. Maybe it reacts to another substance?",

        'translate.poison.timer.message1': 'The size of his pupils looks regular to me. But how did he come into contact with the poison?',
        'translate.poison.timer.message2': 'I see sweat on his forehead. An effect of the poison perhaps?',

        'translate.poison.hint.explanation': "You need to enter the number of the poison that was used. The poison has five different attributes (the five columns in the table). Each player has different information about these attributes. You will need to use the information of the players with clues that you see on the video in order to find the right poison.",
        'translate.poison.hint.hint1': "There are 5 clues to find in the video. There is a coffee cup on the floor, he has sweat on his forehead, his fist is clenched, he is drooling and you can see the shape of his pupils.",
        'translate.poison.hint.hint2': "The coffee cup suggests that his mouth came into contact with the poison. His pupil size is regular.",
        'translate.poison.hint.solution': "71164 matches this situation. <br> John has a closed fist (pugnus - asphyxiate), is drooling (kitosis - minutes), has regular sized pupils (other type) and sweat on his forehead (synthetic). The coffee cup indicates his mouth came into contact with the poison.",

        'translate.poison.list.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/5list.webp",
        'translate.poison.fingerseyestime.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/5fingerseyestime.webp",
        'translate.poison.sweatmouthtype.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/5sweatmouthtype.webp",
        'translate.poison.eyestime.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/5eyestime.webp",
        'translate.poison.sweattype.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/5sweattype.webp",
        'translate.poison.fingers.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/5fingers.webp",
        'translate.poison.mouth.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/5mouth.webp",
        'translate.poison.time.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/5time.webp",
        'translate.poison.eyes.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/5eyes.webp",


        'translate.who_did_it.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/6cover.webp",

        'translate.who_did_it.timer.title': "6. Who did it - A Toxic Case",
        'translate.who_did_it.timer.subtitle': "The challenge will start in:",

        'translate.who_did_it.message1.title': "There was a security camera in the corner. Still works with disks. If we know the right order of these recordings, maybe we can figure out who poisoned John.",
        'translate.who_did_it.message2.title': "See if you can find something which can help us place the recordings in the right order.",
        'translate.who_did_it.message2.error': "This isn't the correct order, I see some changes between recordings I cannot explain. Can you check again? Use the TAG ANSWER link above.",
        'translate.who_did_it.message3.title': "Makes sense. Let me try arranging the recordings in that order.",

        'translate.who_did_it.timer.message1': 'I think we need to focus on the doormats and the cups at the beginning and end of each video. Maybe you can divide up the tasks? I will try to make some notes as well.',
        'translate.who_did_it.timer.message2': 'I have made some notes that I can share with you. I believe that 31 must be the first recording, it ends with one cup and no doormat. Tape 98 starts with 2 cups and no mat, and ends with 1 cup and a mat. 22 starts with 2 cups and a mat and ends with 3 cups and a mat.',
        'translate.who_did_it.timer.message3': 'The second video should start with 1 cup and no doormat and end with 2 cups and a doormat. The third video is 22. This one starts with 2 cups and a doormat and ends with 3 cups and a doormat.',

        'translate.who_did_it.hint.explanation': "There are six videos that need to be placed in the right order. To determine the order, you should try to focus on what is there at the start and what at the end of each video. It can help to have each player focus on a different thing.",
        'translate.who_did_it.hint.hint1': "31 is the first recording. This recording ends with one cup but without a doormat. 98 starts with 2 cups without the mat and ends with 1 cup and a mat. 22 starts with 2 cups and a mat and ends with 3 cups and a mat.",
        'translate.who_did_it_challenging.hint.hint1': "The first video starts with no coffee cups on the counter and a doormat to the right. It ends with 1 cup on the counter and without a doormat.",

        'translate.who_did_it.hint.hint2': "The second video should start with 1 cup and no doormat and end with 2 cups and a doormat. The third video is 22. This one starts with 2 cups and a doormat and ends with 3 cups and a doormat.",
        'translate.who_did_it.hint.solution': "The order is 31-84-22-17-98-56. <br> If you focus on the number of cups on the counter and the doormat on the right, you will see that the start of one video matches the end of another. There is only one possible order. 31: start (0 cups, a mat), end (1 cup, no mat). 84 (start: 1 cup, no mat), end (2 cups, a mat). 22: start (2 cups, a mat), end (3 cups, a mat). 17: start (3 cups, a mat), end (2 cups, no mat). 98: start (2 cups no mat), end (1 cup, a mat). 56: start (1 cup, a mat) end (1 cup, a mat).",

        'translate.enclose.timer.title': "7. Enclose - A Toxic Case",
        'translate.enclose.timer.subtitle': "The challenge will start in:",

        'translate.enclose.message1.title': "We need to deactivate the bombs before she can set them off. There are two people present in the building who can help us. Jennifer, another security guard and Justin, one of the researchers. I have told them all I know.",
        'translate.enclose.message2.title': "Can you figure out the route that Linda takes? She starts in room 270. Try to identify the rooms we have got on camera and pay close attention to what Justin and Jennifer are saying.",
        'translate.enclose_challenging.message2.title': "I managed to get access to only a few of the security camera's. Try to identify which rooms we can see on camera, so we know if she moves through those rooms. I think she moves through 8 rooms in total, but we need to figure out which route she takes!",
        'translate.enclose.message2.error': "I'm not sure that this is right. We have only one shot, can you check again? Use the TAG ANSWER link above and let me know the route she takes.",
        'translate.enclose.message3.title': "OK, let's do this. When Linda enters 204, Justin can go to 266 to deactive the bomb. I will wait for as long as I can and take out the bomb in 270. Play the next video '8. The End' before pressing results.",

        'translate.enclose.timer.message1': 'I have counted how many times I heard a door open and close. In total we need to identify 8 rooms, including her starting point 270.',
        'translate.enclose.timer.message2': 'Jennifer says she did not hear anything, not even in the rooms next to her. This means Linda has not been in rooms 222 and 240. Did you spot her on camera in room 201?',

        'translate.enclose.hint.explanation': "Your goal is to figure out the route that Linda is taking. You need to identify 8 rooms, including her starting point 270. Focus first on Jennifer and Justin (the researcher). Where are they and do they hear something in the room or rooms next to them?",
        'translate.enclose_challenging.hint.explanation': "Your goal is to figure out the route that Linda is taking. In total you need to identify 8 rooms in the right order. Focus first on finding out where Jennifer and Justin (the researcher) are on the map. When do they hear something and when not? Do they hear something in the room or rooms next to them? What does this mean for where they are located?",

        'translate.enclose.hint.hint1': "Justin, the researcher, is in the toilet near room 281. He only heard something in the room next to him once Linda passed Steve. In total we need to identify 8 rooms, including her starting point 270.",
        'translate.enclose_challenging.hint.hint1': "In total we need to identify 8 rooms, including her starting point 270. Find the rooms where we have camera access by focussing on where the doors are. Which room do we have access, but is she never seen on camera?",
        'translate.enclose.hint.hint2': "Jennifer says she did not hear anything, not even in the rooms next to her. This means Linda has not been in rooms 222 and 240. She is not seen on camera in room 201, so she must have gone through 217 to the hallway.",
        'translate.enclose_challenging.hint.hint2': "We have access to the camera in room 201 (the one with the computers), but she is never seen on video there. Jennifer is in the closet in the top right corner and did not hear anything, not even in the rooms next to her. Justin is in the toilet next to room 281.",

        'translate.enclose.hint.solution': "270 - 228 - 266 -  204 - 217 - 281 - 247 - 292. <br> You see Linda first in the a big room with which is not an office (270). Then you don't see her (so she didn't go to 292) and then she appears in another location that has windows and doors both opening outside (it must be 266, so she went through 228). At some point after that, Linda is in the hallway, Steve hears her pass him and only after that does Justin hear her for the first time, in the room next to him. Justin is in the toilet next to 281, so she passes through 281. From 281 she is seen on camera again (must be room 292) and from there she goes back to 270. Because Jennifer didn't hear anything the whole time, not even in the room next to her, the only possibility is that Linda went through room 217. She cannot have gone through 201, because this is a room that we have access to (with the computers), but he wasn't seen on video there.",

        'translate.enclose.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7cover.webp",
        'translate.enclose.map.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7map.webp",
        'translate.enclose_challenging.cmapall.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7cmapall.webp",
        'translate.enclose_challenging.mapstorage.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7cmapstorage.webp",
        'translate.enclose_challenging.maptoilet.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7cmaptoilet.webp",


    }
);

export default enPuzzle;

const enGeneral = ({
    tagOptionsDialog: {
        availableOptions: "Available options ({{count}})",
        submitButton: "Submit",
        clearBtn: "Clear",
        selected: "Selected",
        inOrder: "in order",
    },
    dragOptionsDialog: {
        availableOptions: "Available options ({{count}})",
        textChallengeTitle: "Fill in the gaps",
        textChallengeDescription: "Drag'n'drop options to fill in the missing parts",
        submitButton: "Submit",
    },
    taggerCarousel: {
        altText: "tag image",
        confirmButton: "Confirm",
        submitButtonText: "Submit",
        tagAllImages: "Tag all the images"
    },
    resultsDialog: {
        resultsTitle: "Results",
        resultsScoreDifficultyCap: "You are smart detectives! We believe you deserve a higher difficulty level. You will get more points and different information. And also no more help from the detective... The main player can change the difficulty level in the menu, so you can always go back to standard difficulty.",
        hintsUsed: "Total Hints used",
        incorrectScore: "Total Incorrect score",
        solutionsTried: "Total Solutions tried",
        timeSpent: "Time Spent",
        totalPlayerHintsUsed: "Total Player Hints Used",
        totalGuestHintsUsed: "Total Guest Hints Used",
        totalPlayerSolutionsTried: "Total Incorrect Attempts",
        grandTotalResults: "Grand total results",
        totalScore: "Total score",
        congratulationsText: "Congratulations! You've successfully cracked the case together!",
        waitMessage: "Wait for the other player to press results and try again!",
        goToMenu: "Go To Menu",
        continueButton: "Continue",
        curiousFriends: "Do you want to know your friends score?",
        shareWithFriends: "Invite them and see how they did!",
        shareMessage: "{{playerName}} and {{partnerName}} challenge you for a tv escape room! They scored {{score}} for {{puzzleName}} on {{gameName}}. Do you accept this challenge? Go to {{link}}",
        shareGrandTotalMessage: "{{playerName}} and {{partnerName}} challenge you for a tv escape room! They scored {{score}} for on {{gameName}}. Do you accept this challenge? Go to {{link}}",
        solutionExplanation: {
            button: "See the solution",
            modal: {
                title: "Solution",
                confirm: "Ok"
            }
        },
    },
    continuePuzzleVideo: {
        'Intro 1': 'Is everybody ready? Then start the next video - Intro 2 - and press Continue!',
        'Intro 2': 'Is everybody ready to start the game? Then start the next video - Open the door - and press Continue!',

        // the lab
        'Deceased persons': 'Is everybody ready for the next challenge? Then start the next video  - Password - and press Continue!',
        'Differences': 'Watch the next video and press \'xxxxxxx  Differences\' if you are both ready for the next challenge!',
        'Drone': 'Is everybody ready for the next challenge? Then start the next video  - Poison - and press Continue!',
        'Enclose': 'Well done, you solved it together! Press Continue to watch your total score and challenge your friends!',
        'Escape': 'Watch the next video and press \'xxxxxxx   Escape\' if you are both ready for the next challenge!',
        'Open the door': 'Is everybody ready for the next challenge? Then start the next video  - Deceased Persons - and press Continue. ',
        'Password': 'Is everybody ready for the next challenge? Then start the next video  - Drone - and press Continue!',
        'Poison': 'Is everybody ready for the next challenge? Then start the next video  - Who did it - and press Continue!',
        'Tools and wires': 'Watch the next video and press \'xxxxxxx  Tools and wires\' if you are both ready for the next challenge!',
        'Who did it': 'Is everybody ready for the next challenge? Then start the next video  - Enclose - and press Continue!',

        // paradise
        'The Blackout': 'Are you ready for the next challenge? Then start the next video  - 3. The Witnesses - and press Continue!',
        'The Traces': 'Are you ready for the next challenge? Then start the next video - 9. The Security Tapes - and press Continue!',
        'The Key': 'Are you ready for the next challenge? Then start the next video -  5. The Interrogation - and press Continue!',
        'Whodunnit': 'Well done, you solved it together! Press Continue to watch your total score and challenge your friends!',
        'The Missing Items': 'Are you ready for the next challenge? Then start the next video -  8. The Traces - and press Continue!',
        'The Knight': 'Are you ready for the next challenge? Then start the next video  - 2. The Blackout - and press Continue. ',
        'The Witnesses': 'Are you ready for the next challenge? Then start the next video  - 4. The Key - and press Continue!',
        'The Interrogation': 'Are you both ready for the next challenge? Then start the next video  - 6. The Card Game - and press Continue!',
        'The Security Tapes': 'Are you ready for the next challenge? Then start the next video - 10. Whodunnit - and press Continue!',
        'The Card Game': 'Are you ready for the next challenge? Then start the next video  - 7. The Missing Items - and press Continue!',
    },
    puzzleCard: {
        difficultyPlayed: "Difficulty played:",
        hintsUsed: "Number of hints used:",
        solutionsTried: "Number of solutions tried:",
        expires: "Expires",
        seeStats: "See stats"
    },
    problemModal: {
        sorry: "We're really sorry about the hiccup! But don't worry, we're here to guide it back on track.",
        clickContinue: "By clicking continue, you will reset this challenge and you should be able to continue.",
        areYouSure: "Are you sure?",
        reset: "Reset",
        guestNeedRejoin: "The guest needs to re-join the game again. Please share the link with the guest player and ask them to join.",
        inviteContinue: "Invited, continue!",
        doneOrSupport: "That's it! Now let's reload the page and the game will go on. In case this issue persist, contact the support.",
        reloadPage: "Reload page",
        problem: "Problem?",
    },
    errorModal: {
        difficultyChanged: {
            title: "Difficulty changed",
            description: "The host has changed the difficulty of the game. You will need to rejoin this puzzle in order to continue.",
            button: "Rejoin"
        },
        unknown: {
            title: "Error",
        }
    },
    navbar: {
        inviteFriend: "Invite a friend",
        hintMessages: "Messages",
        instructions: "Instructions",
        instructionsUrl: "https://couchclues.com/instructions/",
        problem: "Problem?",
        quit: "Quit",
    },
    difficultyModal: {
        areYouSure: "Are you sure you want to change difficulty to",
        dialogContent: "By changing the difficulty you will reset your current challenge. \n Your guest player will need to reload the page to continue. Are you sure you want to proceed?",
        cancel: "Cancel",
        confirm: "Confirm",
    },
    gameSeat: {
        hostSubtitle: "Main Player",
        guestSubtitle: "Guest Player",
        waitingForPlayer: "Waiting for player...",
        help: "Help?",
        youTag: "YOU",
        emptyTitle: "Seat is empty",
        emptySubtitle: "Guest"
    },
    puzzleWaitingStartModal: {
        titleHostPov: "Waiting for players to join",
        titleGuestPov: "Waiting for puzzle to start",
        titleError: "Join error",
        puzzleIsFull: "Puzzle is full",
        startGame: "Start game",
        waitForStartAlert: "Wait for the main player to start the game",
        startingIn: "Starting in {{ secondsRemaining }}",
        kickedOut: "You have been kicked out from this puzzle.",
    },
    puzzleWaitingAnswersModal: {
        title: "Waiting for other players"
    },
    areYouStillThereModal: {
        title: "Are you still playing?",
        confirm: "Yes"
    },
    ongoingGameInviteModal: {
        invite: {
            title: "Invite more players",
            description: "In order to invite new guests to the ongoing game you will need restart this puzzle.",
            areYouSure: "Are you sure you want to do this?",
            buttonCancel: "Cancel",
            buttonConfirm: "Yes, restart",
        },
        restarted: {
            title: "Puzzle restarted",
            description: "Game restarted. Ask players to refresh the page to continue",
            buttonContinue: "Continue"
        }
    },
    playerProblemHelp: {
        title: "Problem?",
        copyTextLabel: "Share this link for the player to rejoin",
        or: "or",
        buttonKick: "Kick player"
    },
    youHaveBeenKickedModal: {
        title: "Oops!",
        description: "You have been kicked out of this puzzle. Ask for the host to invite you if you are ready to rejoin.",
        buttonOk: "Ok"
    },
    joinNewMemberModal: {
        title: "{{ name }} has invited you to play",
        input: {
            name: {
                label: "Enter your name"
            },
            email: {
                label: "Enter your email",
                error: "Please enter a valid email"
            }
        },
        errorFullRoom: "Error: The puzzle is already full",
        termsConditionsLabel: "I agree to the CouchClues <Link>terms and conditions</Link>",
        submitButtonText: "Join"
    },
    puzzleCountdown: {
        skipButtonText: "Start right away",
    }
})

export default enGeneral;


export const getInviteLink = (gameId, groupId) => {
    let url = window.location.protocol + '//' + window.location.host + "/";
    return url.concat(gameId).concat("/").concat(groupId).concat("/guest");
}

export const getMemberInviteLink = (gameId, groupId, memberId) => {
    let url = window.location.protocol + '//' + window.location.host + "/";
    return url.concat(gameId).concat("/").concat(groupId).concat("/").concat(memberId);
}

export const delay = ms => new Promise(res => setTimeout(res, ms));

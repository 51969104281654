// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enGame from './i18n/en_game';
import enTermsConditions from './i18n/en_terms_conditions';
import nlTermsConditions from './i18n/nl_terms_conditions';
import enPuzzleCommon from "./i18n/en_puzzle_common";
import enPuzzleToxicCase from "./i18n/en_puzzle_a_toxic_case";
import enPuzzleParadise from "./i18n/en_puzzle_paradise";
import nlPuzzleCommon from "./i18n/nl_puzzle_common";
import nlPuzzleToxicCase from "./i18n/nl_puzzle_a_toxic_case";
import nlPuzzleParadise from "./i18n/nl_puzzle_paradise";
import nlGame from "./i18n/nl_game";
import enGeneral from "./i18n/en_general";
import nlGeneral from "./i18n/nl_general";

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                termsConditions: enTermsConditions,
                resultsNavigation: {
                    faqUrl: "https://couchclues.com/#faq",
                    shopUrl: "https://couchclues.com/chapters/a-toxic-case/full/"
                },
                game: enGame,
                puzzle: {
                    ...enPuzzleCommon,
                    ...enPuzzleToxicCase,
                    ...enPuzzleParadise
                },
                ...enGeneral,
            },
            nl: {
                termsConditions: nlTermsConditions,
                resultsNavigation: {
                    faqUrl: "https://couchclues.com/nl/#faq",
                    shopUrl: "https://couchclues.com/nl/chapters/a-toxic-case/full/"
                },
                game: nlGame,
                puzzle: {
                    ...nlPuzzleCommon,
                    ...nlPuzzleToxicCase,
                    ...nlPuzzleParadise
                },
                ...nlGeneral,
            }
        },
        fallbackLng: "en",
        debug: false,

        // have a common namespace used around the full app
        ns: [
            "game",
            "tagOptionsDialog",
            "taggerCarousel",
            "resultsDialog",
            "puzzleCard",
            "problemModal",
            "navbar",
            "difficultyModal"
        ],
        defaultNS: "game",

        // keySeparator: false,

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;

import {alpha, Button, Stack} from "@mui/material";
import {modalStyle} from "../../style/modalStyle";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {handleLineBreaks} from "../../util/textFormat";

const SolutionExplanationModal = ({ open, onClose, solution }) => {
    const { t } = useTranslation('resultsDialog');
    const {t: tPuzzle} = useTranslation("puzzle");

    return (
        <Modal
            open={open}
            slotProps={{ backdrop: { sx: { background: alpha('#000', 0.9) }} }}
        >
            <Stack sx={modalStyle} gap={2}>
                <Typography variant="h6" component="h2">
                    {t('solutionExplanation.modal.title')}
                </Typography>
                <Typography variant="body">
                    {handleLineBreaks(tPuzzle(solution))}
                </Typography>
                <Button variant="contained" onClick={onClose}>
                    {t('solutionExplanation.modal.confirm')}
                </Button>
            </Stack>
        </Modal>
    )
}

export default SolutionExplanationModal;
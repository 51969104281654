import {createContext, useContext, useEffect, useMemo} from "react";
import {useRoomContext} from "./RoomContextProvider";
import {usePuzzleContext} from "./PuzzleContextProvider";
import useRetryPuzzle from "../hooks/useRetryPuzzle";

export const PuzzleWaitingAnswersContext = createContext({
});

export const usePuzzleWaitingAnswersContext = () => useContext(PuzzleWaitingAnswersContext);

const PuzzleWaitingAnswersContextProvider = ({children}) => {
    const {roomId, myMemberId} = useRoomContext();
    const {
        puzzleId,
        gameData,
        startPollingState,
        stopPollingState,
        puzzleStateResult
    } = usePuzzleContext();
    const gameplays = gameData?.puzzle.gameplayByMemberId;
    const myGameplay = !!myMemberId && gameData
        ? gameplays?.[myMemberId]
        : null;

    const { data } = puzzleStateResult;
    const answerIndex = data?.retries;
    const hasAllAnswers = data?.players.every(player => player.providedAnswers.at(answerIndex))
    const myAnswer = myGameplay?.messages.filter(it => it.sender !== 'game').at(answerIndex);

    const requestPuzzleRetryResult = useRetryPuzzle();

    const {
        mutate: requestPuzzleRetry,
    } = requestPuzzleRetryResult;

    useEffect(() => {
        if (myAnswer) {
            startPollingState();
        } else {
            stopPollingState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myAnswer, puzzleStateResult, requestPuzzleRetryResult]);

    const puzzleFinishContextValues = useMemo(() => (
            {
                myGameplay,
                myAnswer,
                hasAllAnswers,
                onTryAgain: () => requestPuzzleRetry({roomId, puzzleId}),
            }
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [myGameplay, myAnswer, requestPuzzleRetryResult, puzzleStateResult]);

    return (
        <PuzzleWaitingAnswersContext.Provider value={puzzleFinishContextValues}>
            {children}
        </PuzzleWaitingAnswersContext.Provider>
    )
}

export default PuzzleWaitingAnswersContextProvider;
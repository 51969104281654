const nlTermsConditions=  ({
    title: "Terms and conditions",
    body: `
        <strong>Some term name #1</strong> 
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vehicula hendrerit est in molestie. Mauris tempus pellentesque feugiat. Etiam sollicitudin, odio id efficitur dapibus, risus felis pretium justo, quis consequat augue libero vel justo. Donec consequat accumsan posuere. Suspendisse aliquam ipsum a tortor dapibus, et lobortis enim luctus.
         <br /><br />
         Nulla venenatis elit quis enim volutpat volutpat. Nullam laoreet placerat erat ut viverra. Fusce sollicitudin sit amet metus in luctus. Morbi pretium lectus erat, eu pretium massa dignissim nec. Duis laoreet eleifend erat maximus maximus. Sed congue tellus dignissim ligula feugiat pretium. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p> 
        
        <strong>Some term name #2</strong> 
        <p>Maecenas a consectetur nisi, vel <i>ullamcorper enim</i>. Sed auctor est nunc, eget congue nisi mollis venenatis. Duis sodales pellentesque aliquet. Quisque blandit vehicula ipsum, non dignissim nunc tempus ornare. Etiam vel tincidunt lorem. Nunc aliquet lectus nisl, et suscipit massa scelerisque eget.</p>
        <p>Suspendisse potenti. Fusce ac cursus tortor. Integer ultricies ultrices nunc. Vestibulum bibendum aliquet sem, consequat maximus massa rutrum sit amet. Nunc id orci non arcu lobortis ultricies eget sit amet lorem. Mauris porttitor rutrum euismod. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Integer dignissim ex quis varius vulputate. In at fringilla velit. Sed tempor, ex non euismod accumsan, lacus elit rutrum mi, eu imperdiet metus augue nec mi.</p>`
})

export default nlTermsConditions;
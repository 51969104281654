import {useMutation} from "@tanstack/react-query";
import gameApiV2 from "../api/gameApiV2";

const useSkipCountdown = () => {
    const mutation = useMutation({
        mutationFn: ({roomId, puzzleId}) => {
            return gameApiV2.skipStartCountdown(roomId, puzzleId)
        },
    })

    return ({
        ...mutation
    })
}

export default useSkipCountdown;
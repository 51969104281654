const nlPuzzleParadise = (
    {
        'translate.game.paradise.instructions.instruction1': `
            Hoe werkt het spel?<br/>
            <br/>1. Paradise is een spel voor 2 spelers en gaat over een vermissingszaak. Om het spel te spelen heeft iedere speler zijn eigen smartphone nodig.
            <br/>2. Beide spelers krijgen steeds dezelfde opdracht, maar ontvangen verschillende informatie.
            <br/>3. Om de opdracht uit te voeren, kijken jullie samen naar dezelfde video. Jullie zullen de informatie die je hebt ontvangen moeten bespreken en samen moeten werken.
            <br/>4. De videos kun je bekijken via Vimeo of het CouchClues Youtube kanaal. We raden aan om het op je TV te bekijken of te streamen, maar je kan elk scherm gebr/uiken wat je wil.
            <br/>5. Iedere video start met een deel van het verhaal en daarna beelden die betrekking hebben op je opdracht. De beelden die je nodig hebt zullen steeds worden herhaald, zodat je een nieuwe kans hebt de zaak op te lossen.
        `,
        'translate.game.paradise.instructions.instruction2': `
            Hints en scores<br/>
            <br/>1. Zodra je een opdracht hebt gekregen start er een timer. Je kunt proberen de opdrachten zo snel mogelijk uit te voeren om een hoge score te halen, maar je kunt dit ook negeren en het rustig op je eigen tijd doen.
            <br/>2. Als het niet lukt om de opdracht uit te voeren, kun je in het menu rechtsboven een hint gebr/uiken. De uitleg helpt als je geen idee hebt hoe je moet beginnen en de andere hints zijn er voor als je niet verder komt.
            <br/>3. Beide spelers kunnen dezelfde hint bekijken zonder dat dit extra punten kost.
            <br/>4. Kom je er met de hints niet uit, dan kun je op Oplossing klikken om de oplossing en de uitleg te zien.
        `,
        'translate.game.paradise.instructions.instruction3': `
            Hoe start je het spel?<br/>
            <br/>1. De speler die het spel heeft gekocht ontvangt een link. Met de link kan Speler 1 het spel opstarten.
            <br/>2. Als speler 1 het spel opstart krijgt deze een nieuwe link, waarmee een gast speler kan worden uitgenodigd.
            <br/>3. Het spel start met twee intro opdrachten om het spel te leren kennen.
            <br/>4. Als je een bug of een ander probleem ervaart, kan Speler 1 op de knop Problemen klikken om de huidige opdracht opnieuw op te starten.
        `,
        'translate.game.paradise.instructions.btnText': 'Begrepen!',

        'translate.game.paradise': 'A Toxic Case',

        'translate.the_blackout_standard.player.media2': "https://couchclues.com/wp-content/uploads/nl2smainprescription.webp",
        'translate.the_blackout_standard.player.media3': "https://couchclues.com/wp-content/uploads/nl2smainoneheart.webp",
        'translate.the_blackout_standard.player.media4': "https://couchclues.com/wp-content/uploads/nl2smaintattooface.webp",
        'translate.the_blackout_standard.guest.media2': "https://couchclues.com/wp-content/uploads/nl2sguestglasses.webp",
        'translate.the_blackout_standard.guest.media3': "https://couchclues.com/wp-content/uploads/nl2sguestearring.webp",
        'translate.the_blackout_standard.guest.media4': "https://couchclues.com/wp-content/uploads/nl2sguestrahuljim.webp",
        'translate.the_blackout_standard.guest.media5': "https://couchclues.com/wp-content/uploads/nl2sguest30years.webp",

        'translate.the_blackout_challenging.player.media2': "https://couchclues.com/wp-content/uploads/nl2mainage.webp",
        'translate.the_blackout_challenging.player.media3': "https://couchclues.com/wp-content/uploads/nl2mainnotblonde.webp",
        'translate.the_blackout_challenging.player.media4': "https://couchclues.com/wp-content/uploads/nl2mainnotfamily.webp",
        'translate.the_blackout_challenging.player.media5': "https://couchclues.com/wp-content/uploads/nl2mainoneheart.webp",
        'translate.the_blackout_challenging.player.media6': "https://couchclues.com/wp-content/uploads/nl2mainrahul.webp",
        'translate.the_blackout_challenging.guest.media2': "https://couchclues.com/wp-content/uploads/nl2guestglasses.webp",
        'translate.the_blackout_challenging.guest.media3': "https://couchclues.com/wp-content/uploads/nl2guestheart.webp",
        'translate.the_blackout_challenging.guest.media4': "https://couchclues.com/wp-content/uploads/nl2guestmedicines.webp",
        'translate.the_blackout_challenging.guest.media5': "https://couchclues.com/wp-content/uploads/nl2guestnephew.webp",
        'translate.the_blackout_challenging.guest.media6': "https://couchclues.com/wp-content/uploads/nl2guesttattoos.webp",

        'translate.whodunnit_standard.player.media': "https://couchclues.com/wp-content/uploads/nl10sboth.webp",
        'translate.whodunnit_standard.guest.media': "https://couchclues.com/wp-content/uploads/nl10sboth.webp",

        'translate.whodunnit_challenging.player.media': "https://couchclues.com/wp-content/uploads/nl10main.webp",
        'translate.whodunnit_challenging.guest.media': "https://couchclues.com/wp-content/uploads/nl10guest.webp",

        'translate.the_knight_standard.player.media': "https://couchclues.com/wp-content/uploads/nl1both.webp",
        'translate.the_knight_standard.player.media2': "https://couchclues.com/wp-content/uploads/nl1main.webp",
        'translate.the_knight_standard.guest.media': "https://couchclues.com/wp-content/uploads/nl1both.webp",
        'translate.the_knight_standard.guest.media2': "https://couchclues.com/wp-content/uploads/nl1guest.webp",

        'translate.the_knight_challenging.player.media': "https://couchclues.com/wp-content/uploads/nl1both.webp",
        'translate.the_knight_challenging.player.media2': "https://couchclues.com/wp-content/uploads/nl1main.webp",
        'translate.the_knight_challenging.guest.media': "https://couchclues.com/wp-content/uploads/nl1both.webp",
        'translate.the_knight_challenging.guest.media2': "https://couchclues.com/wp-content/uploads/nl1guest.webp",

        'translate.the_witnesses_standard.player.media': "https://couchclues.com/wp-content/uploads/nl3smap.webp",
        'translate.the_witnesses_standard.player.media2': "https://couchclues.com/wp-content/uploads/nl3mainsymbols.webp",
        'translate.the_witnesses_standard.player.media3': "https://couchclues.com/wp-content/uploads/nl3mainitems.webp",
        'translate.the_witnesses_standard.player.media4': "https://couchclues.com/wp-content/uploads/nl3marker.webp",
        'translate.the_witnesses_standard.guest.media': "https://couchclues.com/wp-content/uploads/nl3smap.webp",
        'translate.the_witnesses_standard.guest.media2': "https://couchclues.com/wp-content/uploads/nl3guestsymbols.webp",
        'translate.the_witnesses_standard.guest.media3': "https://couchclues.com/wp-content/uploads/nl3guestitems.webp",
        'translate.the_witnesses_standard.guest.media4': "https://couchclues.com/wp-content/uploads/nl3marker.webp",

        'translate.the_witnesses_challenging.player.media': "https://couchclues.com/wp-content/uploads/nl3smap.webp",
        'translate.the_witnesses_challenging.player.media2': "https://couchclues.com/wp-content/uploads/nl3mainsymbols.webp",
        'translate.the_witnesses_challenging.player.media3': "https://couchclues.com/wp-content/uploads/nl3mainitems.webp",
        'translate.the_witnesses_challenging.player.media4': "https://couchclues.com/wp-content/uploads/nl3marker.webp",
        'translate.the_witnesses_challenging.guest.media': "https://couchclues.com/wp-content/uploads/nl3smap.webp",
        'translate.the_witnesses_challenging.guest.media2': "https://couchclues.com/wp-content/uploads/nl3guestsymbols.webp",
        'translate.the_witnesses_challenging.guest.media3': "https://couchclues.com/wp-content/uploads/nl3guestitems.webp",
        'translate.the_witnesses_challenging.guest.media4': "https://couchclues.com/wp-content/uploads/nl3marker.webp",

        'translate.the_interrogation_standard.player.media': "https://couchclues.com/wp-content/uploads/nl5both.webp",
        'translate.the_interrogation_standard.player.media2': "https://couchclues.com/wp-content/uploads/nl5main.webp",
        'translate.the_interrogation_standard.guest.media': "https://couchclues.com/wp-content/uploads/nl5both.webp",
        'translate.the_interrogation_standard.guest.media2': "https://couchclues.com/wp-content/uploads/nl5guest.webp",

        'translate.the_interrogation_challenging.player.media': "https://couchclues.com/wp-content/uploads/nl5both.webp",
        'translate.the_interrogation_challenging.player.media2': "https://couchclues.com/wp-content/uploads/nl5main.webp",
        'translate.the_interrogation_challenging.guest.media': "https://couchclues.com/wp-content/uploads/nl5both.webp",
        'translate.the_interrogation_challenging.guest.media2': "https://couchclues.com/wp-content/uploads/nl5guest.webp",

        'translate.game.paradise.puzzle_name.intro1': "Intro 1",
        'translate.game.paradise.puzzle_name.intro2': "Intro 2",
        'translate.game.paradise.puzzle_name.the_knight': "The Knight",
        'translate.game.paradise.puzzle_name.the_blackout': "The Blackout",
        'translate.game.paradise.puzzle_name.the_witnesses': "The Witnesses",
        'translate.game.paradise.puzzle_name.the_key': "The Key",
        'translate.game.paradise.puzzle_name.the_interrogation': "The Interrogation",
        'translate.game.paradise.puzzle_name.the_card_game': "The Card Game",
        'translate.game.paradise.puzzle_name.the_missing_items': "The Missing Items",
        'translate.game.paradise.puzzle_name.the_traces': "The Traces",
        'translate.game.paradise.puzzle_name.the_security_tapes': "The Security Tapes",
        'translate.game.paradise.puzzle_name.whodunnit': "Whodunnit",

        'translate.paradise.1_intro.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/coverintro1.webp",

        'translate.paradise.1_intro.2p.message.title': "Hi {{playerName}}! Welcome to Couch Clues! Before you start, make sure that both players have a pen and paper.  You will learn the game by playing two quick intro challenges with {{partnerName}}. Open the menu on the top right of this screen, read the instructions and set up the video.",
        'translate.paradise.1_intro.345p.message.title': "Hi {{playerName}}! Welcome to Couch Clues! Before you start, make sure that each player has a pen and paper.  You will learn the game by playing two quick intro challenges with your fellow players. Open the menu on the top right of this screen, read the instructions and set up the video.",
        'translate.paradise.1_intro.message2.title': "Have you set up the video? Then start the Playlist A Toxic Case. It should start with Intro 1. Select ENG subtitles and follow the instructions on the Intro 1 video. Your goal is to send me a 6 digit code.",
        'translate.paradise.1_intro.message2.errors': "That is not the right answer, but don't worry, this is just practice. Just a quick check. Are you both watching Youtube or Vimeo? Have you found the channel 'CouchClues' and the Playlist 'A Toxic Case'? And are you watching Intro 1? Then open the menu on the top right of your app, open explanation, then hint 1 and then hint 2.",
        'translate.paradise.1_intro.message3.title': "Well done! Now you can pause the video and click the results button below. Your score is determined by the time, hints and wrong answers. If someone uses a hint, all players can look at it and it will count only once.",

        'translate.paradise.1_intro.hint.explanation': "Every puzzle has an explanation. An explanation will explain the puzzle if you have no idea what to do. For this intro 1 challenge, you have to find the same 6 digit number. To find it, all should click hint 1.",
        'translate.paradise.1_intro.23p.player.hint.hint1': "The first two digits of the code are 96. Make sure to share this information. Communication is key in this game! Now check hint 2.",
        'translate.paradise.1_intro.2p.guest01.hint.hint1': "The last two digits are 32. Make sure to share this with your partner. This game is all about talking to each other! Now check hint 2.",
        'translate.paradise.1_intro.345p.guest01.hint.hint1': "The 5th digit is a 3. Make sure to share this with your partners. Communication is key in this game! Now check hint 2.",
        'translate.paradise.1_intro.345p.guest02.hint.hint1': "The last digit is a 2. Make sure to share this with your partners. Communication is key in this game! Now check hint 2.",
        'translate.paradise.1_intro.45p.player.hint.hint1': "The first digit is a 9. Make sure to share this with your partners. Communication is key in this game! Now check hint 2.",
        'translate.paradise.1_intro.45p.guest03.hint.hint1': "The second digit is a 6. Make sure to share this with your partners. Communication is key in this game! Now check hint 2.",
        'translate.paradise.1_intro.5p.guest04.hint.hint1': "The third digit is a 1. Make sure to share this with your partner. Communication is key in this game! Now check hint 2.",

        'translate.paradise.1_intro.2p.hint.hint2': "Did your partner tell you the other numbers? The middle digits are 11. You can now solve the puzzle, or check the solution to be sure. If you choose to view the solution in the actual game, you won't earn any points for that challenge.",
        'translate.paradise.1_intro.34p.hint.hint2': "Did your partners tell you the other numbers? The middle digits are 11. You can now solve the puzzle, or check the solution to be sure. If you choose to view the solution in the actual game, you won't earn any points for that challenge.",
        'translate.paradise.1_intro.5p.hint.hint2': "Did your partners tell you the other numbers? The fourth digit is 1. You can now solve the puzzle, or check the solution to be sure. If you choose to view the solution in the actual game, you won't earn any points for that challenge.",

        'translate.paradise.1_intro.234p.hint.solution': "The solution is 961132. <br> Each player received a different first hint showing part of the code. If you shared all the information you would have gotten the first two digits (96) and the last two digits (32). The second hint showed the same information for all, which is that the middle digits were 11.",
        'translate.paradise.1_intro.5p.hint.solution': "The solution is 961132. <br> Each player received a different first hint showing part of the code. If you shared all the information you would have gotten the first three digits (961) and the last two digits (32). The second hint showed the same information for all, which is that the fourth is a 1.",

        'translate.paradise.2_intro.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/coverintro2.webp",

        'translate.paradise.2_intro.timer.title': "Intro 2 - A Toxic Case",
        'translate.paradise.2_intro.timer.subtitle': "You will receive more information in:",
        'translate.paradise.2_intro.message1.title': "Now it's time for a real practice puzzle. You will receive some images below, try to zoom in on them by tapping on the image and then pinching your fingers.",
        'translate.paradise.2_intro.message2.title': "Look at the Intro 2 video. Your goal is to find a five digit code.",
        'translate.paradise.2_intro.message2.error': "That is not the right code. To try again you have to use the TAG ANSWERS link in the message above to select a different answer.",
        'translate.paradise.2_intro.message3.title': "That's right! You will now continue to the real game. Have fun together!",

        'translate.paradise.2_intro.timer.message1': 'During the game you sometimes get an additional message like this one. These messages contain information from the detective that can help you. If you do not want to receive these messages, the main player can turn them off in the menu.',

        'translate.paradise.2_intro.hint.explanation': "Your goal is to find a 5 digit number. You will have to tell each other what you see on your phone and match that with what you see on television. Select the labels in the right order and press submit.",
        'translate.paradise.2_intro.hint.hint1': "The first digit is 5.",
        'translate.paradise.2_intro.hint.hint2': "The order on the television is Yellow, Red, Blue, Green, Grey. Which numbers match these colours?",
        'translate.paradise.2_intro.hint.solution': "The solution is Five, Nine, Seven, One, Four. You will have to select the labels in that order. <br> The order of the colours on the television is Yellow, Red, Blue, Green,Grey. The yellow number is a 5, the red number is a 9, the blue number a 7, the green number a 1 and the grey number a 4.",

        'translate.paradise.2_intro.four.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/intro2four.webp",
        'translate.paradise.2_intro.five.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/intro2five.webp",
        'translate.paradise.2_intro.seven.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/intro2seven.webp",
        'translate.paradise.2_intro.one.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/intro2one.webp",
        'translate.paradise.2_intro.nine.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/intro2nine.webp",

        'translate.paradise.2_intro.option1': "One",
        'translate.paradise.2_intro.option2': "Two",
        'translate.paradise.2_intro.option3': "Three",
        'translate.paradise.2_intro.option4': "Four",
        'translate.paradise.2_intro.option5': "Five",
        'translate.paradise.2_intro.option6': "Six",
        'translate.paradise.2_intro.option7': "Seven",
        'translate.paradise.2_intro.option8': "Eight",
        'translate.paradise.2_intro.option9': "Nine",
        'translate.paradise.2_intro.option10': "Zero",

        'translate.the_blackout_standard.timer.title': "2. Deceased persons - A Toxic Case",
        'translate.the_blackout_standard.timer.subtitle': "De volgende opdracht start over:",
        'translate.the_blackout_standard.player.timer.message1': 'Ik heb nog wat notities gevonden in zijn prullenbak. Hij wist van 9 personen zeker dat ze niet vergiftigd waren. De notitie dat maar 1 van de vergiftigde personen een hartaandoening heeft is blijkbaar heel belangrijk.',
        'translate.the_blackout_standard.player.timer.message2': 'Op de achterkant van de foto van Simon Carlson stond dat hij vergiftigd was. Kunnen jullie de andere persoon vinden?',
        'translate.the_blackout_standard.guest.timer.message1': 'Ik heb nog wat notities gevonden in zijn prullenbak. Hij wist van 9 personen zeker dat ze niet vergiftigd waren. De notitie dat maar 1 van de vergiftigde personen een hartaandoening heeft is blijkbaar heel belangrijk.',
        'translate.the_blackout_standard.guest.timer.message2': 'Op de achterkant van de foto van Simon Carlson stond dat hij vergiftigd was. Kunnen jullie de andere persoon vinden?',

        'translate.the_blackout_standard.player.hint.explanation': "Op het scherm zie je foto's van 12 mensen. Jouw doel is om te beredeneren welke 2 mensen zijn vergiftigd. Elke speler heeft verschillende aanwijzingen en je hebt alle aanwijzingen nodig om de 2 mensen te vinden die je zoekt. Probeer eerst de mensen te vinden die zeker niet vergiftigd zijn.",
        'translate.the_blackout_standard.player.hint.hint1': "9 personen zijn zeker niet vergiftigd. Mensen met tattoages op hun gezicht, met een bril (ongeluk, dus niet vergiftigd) en Jim en Rahul (gebruiken geen medicatie).",
        'translate.the_blackout_standard.player.hint.hint2': "Een van de twee vergiftigde personen is Simon Carlson. Dit betekent dat de andere persoon geen hartaandoening heeft.",
        'translate.the_blackout_standard.player.hint.solution': "Simon en Sam. <br> De mensen met een tattoage in hun gezicht en de personen met een bril (zijn overleden aan een ongeluk, dus niet vergiftigd) en Rahul en Jim (gebruiken geen medicatie), zijn niet vergiftigd. Omdat er 30 jaar tussen zit, is een van de twee resterende personen Simon. De jongere persoon kan dan geen hartaandoening (oorbel) hebben. Dus valt Anna af en bijft Sam over.",
        'translate.the_blackout_standard.guest.hint.explanation': "Op het scherm zie je foto's van 12 mensen. Jouw doel is om te beredeneren welke 2 mensen zijn vergiftigd. Elke speler heeft verschillende aanwijzingen en je hebt alle aanwijzingen nodig om de 2 mensen te vinden die je zoekt. Probeer eerst de mensen te vinden die zeker niet vergiftigd zijn.",
        'translate.the_blackout_standard.guest.hint.hint1': "9 personen zijn zeker niet vergiftigd. Mensen met tattoages op hun gezicht, met een bril (ongeluk, dus niet vergiftigd) en Jim en Rahul (gebruiken geen medicatie)..",
        'translate.the_blackout_standard.guest.hint.hint2': "Een van de twee vergiftigde personen is Simon Carlson. Dit betekent dat de andere persoon geen hartaandoening heeft.",
        'translate.the_blackout_standard.guest.hint.solution': "Simon en Sam. <br> De mensen met een tattoage in hun gezicht en de personen met een bril (zijn overleden aan een ongeluk, dus niet vergiftigd) en Rahul en Jim (gebruiken geen medicatie), zijn niet vergiftigd. Omdat er 30 jaar tussen zit, is een van de twee resterende personen Simon. De jongere persoon kan dan geen hartaandoening (oorbel) hebben. Dus valt Anna af en bijft Sam over.",
        'translate.the_blackout_standard.message1.title': "Wat een rommel! John onderzocht sterfgevallen zonder duidelijke doodsoorzaak. Ik stuur je zijn aantekeningen.",
        'translate.the_blackout_standard.message2.title': "Er zijn er 2 vergiftigd. Laat me weten of jullie kunnen achterhalen wie dat zijn. Ik zal kijken of ik nog meer informatie kan vinden.",
        'translate.the_blackout_standard.message2.error': "Ik heb het bekeken, maar volgens mij klopt dit niet met zijn aantekeningen. Kunnen jullie er nog een keer naar kijken? Gebruik dezelfde TAG ANTWOORDEN link uit het bericht hierboven.",
        'translate.the_blackout_standard.message3.title': "Dank! Dat lijkt te kloppen met aantekeningen. Misschien kan ik iets over deze mensen vinden op zijn laptop.",
        'translate.the_blackout_standard.guest_message1.title': "Wat een rommel! John onderzocht sterfgevallen zonder duidelijke doodsoorzaak. Ik stuur je zijn aantekeningen.",
        'translate.the_blackout_standard.guest_message2.title': "Er zijn er 2 vergiftigd. Laat me weten of jullie kunnen achterhalen wie dat zijn. Ik zal kijken of ik nog meer informatie kan vinden.",
        'translate.the_blackout_standard.guest_message2.error': "Ik heb het bekeken, maar volgens mij klopt dit niet met zijn aantekeningen. Kunnen jullie er nog een keer naar kijken? Gebruik dezelfde TAG ANTWOORDEN link uit het bericht hierboven.",
        'translate.the_blackout_standard.guest_message3.title': "Dank! Dat lijkt te kloppen met zijn aantekeningen. Misschien kan ik iets over deze mensen vinden op zijn laptop.",

        'translate.the_blackout_challenging.timer.title': "2. Deceased persons - A Toxic Case",
        'translate.the_blackout_challenging.timer.subtitle': "De volgende opdracht start over:",
        'translate.the_blackout_challenging.player.timer.message1': 'Ik heb nog wat notities gevonden in zijn prullenbak. Het lijkt erop dat Charlie Yang en Joe Lopez niet vergiftigd zijn.',
        'translate.the_blackout_challenging.player.timer.message2': 'Zie net dat op de achterkant van de foto van Simon Carlson staat dat hij vergiftigd was. Kunnen jullie de andere persoon vinden?',
        'translate.the_blackout_challenging.guest.timer.message1': 'Ik heb nog wat notities gevonden in zijn prullenbak. Het lijkt erop dat Charlie Yang en Joe Lopez niet vergiftigd zijn.',
        'translate.the_blackout_challenging.guest.timer.message2': 'Zie net dat op de achterkant van de foto van Simon Carlson staat dat hij vergiftigd was. Kunnen jullie de andere persoon vinden?',
        'translate.the_blackout_challenging.player.hint.explanation': "Op het scherm zie je foto's van 12 mensen. Jouw doel is om te beredeneren welke 2 mensen zijn vergiftigd. Elke speler heeft verschillende aanwijzingen en je hebt alle aanwijzingen nodig om de 2 mensen te vinden die je zoekt. Focus je eerst op het leeftijdsverschil en wie er een hartaandoening hebben.",
        'translate.the_blackout_challenging.player.hint.hint1': "Charlie Yang en Joe Lopez zijn niet vergiftigd.",
        'translate.the_blackout_challenging.player.hint.hint2': "Een van de twee vergiftigde personen is Simon Carlson.",
        'translate.the_blackout_challenging.player.hint.solution': "Simon en Sam. <br> De mensen die blond zijn, een bril hebben (zijn overleden aan een ongeluk, dus niet vergiftigd) en Rahul (gebruikt geen medicatie), zijn niet vergiftigd. Omdat er 30 jaar tussen zit, is een van de twee personen Joe of Simon. Als het Joe zou zijn, moet de jongere persoon een hartaandoening hebben. Dit geldt alleen voor Charlie, maar die heeft een tattoo (en minimaal een van de 2 heeft geen tattoo). Dus kan de oudere persoon niet Joe zijn, maar moet het Simon zijn. De jongere persoon kan dan geen hartaandoening hebben en kan geen familie zijn (dus niet Jim). Dus bijft Sam over.",
        'translate.the_blackout_challenging.guest.hint.explanation': "Op het scherm zie je foto's van 12 mensen. Jouw doel is om te beredeneren welke 2 mensen zijn vergiftigd. Elke speler heeft verschillende aanwijzingen en je hebt alle aanwijzingen nodig om de 2 mensen te vinden die je zoekt. Focus je eerst op het leeftijdsverschil en wie er een hartaandoening hebben.",
        'translate.the_blackout_challenging.guest.hint.hint1': "Charlie Yang en Joe Lopez zijn niet vergiftigd.",
        'translate.the_blackout_challenging.guest.hint.hint2': "Een van de twee vergiftigde personen is Simon Carlson.",
        'translate.the_blackout_challenging.guest.hint.solution': "Simon en Sam. <br> De mensen die blond zijn, een bril hebben (zijn overleden aan een ongeluk, dus niet vergiftigd) en Rahul (gebruikt geen medicatie), zijn niet vergiftigd. Omdat er 30 jaar tussen zit, is een van de twee personen Joe of Simon. Als het Joe zou zijn, moet de jongere persoon een hartaandoening hebben. Dit geldt alleen voor Charlie, maar die heeft een tattoo (en minimaal een van de 2 heeft geen tattoo). Dus kan de oudere persoon niet Joe zijn, maar moet het Simon zijn. De jongere persoon kan dan geen hartaandoening hebben en kan geen familie zijn (dus niet Jim). Dus bijft Sam over.",
        'translate.the_blackout_challenging.message1.title': "Wat een rommel! John onderzocht sterfgevallen zonder duidelijke doodsoorzaak. Ik stuur je zijn aantekeningen.",
        'translate.the_blackout_challenging.message2.title': "Er zijn er 2 vergiftigd. Laat me weten of jullie kunnen achterhalen wie dat zijn. Ik onderzoek ondertussen zijn werkkamer.",
        'translate.the_blackout_challenging.message2.error': "Ik heb het bekeken, maar volgens mij klopt dit niet met zijn aantekeningen. Kunnen jullie er nog een keer naar kijken? Gebruik dezelfde TAG ANTWOORDEN link uit het bericht hierboven.",
        'translate.the_blackout_challenging.message3.title': "Dat klopt met zijn aantekeningen. Misschien kan ik iets over deze mensen vinden op zijn laptop?",
        'translate.the_blackout_challenging.guest_message1.title': "Wat een rommel! John onderzocht sterfgevallen zonder duidelijke doodsoorzaak. Ik stuur je zijn aantekeningen.",
        'translate.the_blackout_challenging.guest_message2.title': "Er zijn er 2 vergiftigd. Laat me weten of jullie kunnen achterhalen wie dat zijn. Ik onderzoek ondertussen zijn werkkamer.",
        'translate.the_blackout_challenging.guest_message2.error': "Ik heb het bekeken, maar volgens mij klopt dit niet met zijn aantekeningen. Kunnen jullie er nog een keer naar kijken? Gebruik dezelfde TAG ANTWOORDEN link uit het bericht hierboven.",
        'translate.the_blackout_challenging.guest_message3.title': "Dank! Dat lijkt te kloppen met zijn aantekeningen. Misschien kan ik iets over deze mensen vinden op zijn laptop.",

        'translate.the_traces_standard.player.hint.explanation': "Your goals is to find all zones where your items are missing. To do so, you need to describe exactly what you see to the other player. The person that is missing an item needs to tag the zone where this item is missing. So if you (main player) misses an item on the top left, you will tag zone 51. If the other player misses an item top left, they will tag another number (but not 51). To solve it you must enter all the zones where items are missing (for both players)",
        'translate.the_traces_standard.player.hint.hint1': "Each player is missing 3 items.",
        'translate.the_traces_standard.player.hint.hint2': "One of the items you are missing is in zone 43. You are not missing any items in zone 11.",
        'translate.the_traces_standard.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
        'translate.the_traces_standard.guest.hint.explanation': "Your goals is to find all zones where your items are missing. To do so, you need to describe exactly what you see to the other player. The person that is missing an item needs to tag the zone where this item is missing. So if you (main player) misses an item on the top left, you will tag zone 51. If the other player misses an item top left, they will tag another number (but not 51). To solve it you must enter all the zones where items are missing (for both players)",
        'translate.the_traces_standard.guest.hint.hint1': "Each player is missing 3 items.",
        'translate.the_traces_standard.guest.hint.hint2': "One of the items you are missing is in zone 57. You are not missing any items in zone 77.",
        'translate.the_traces_standard.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
        'translate.the_traces_standard.message1.title': "She's gone. But she left a couple of pictures. Apparantly she is using some of this junk.",
        'translate.the_traces_standard.message2.error': "I cannot find anything based on these results. Please check again",
        'translate.the_traces_standard.message2.title': "I divided the photo's up into different areas. Let me know the areas where you are missing items.",
        'translate.the_traces_standard.message3.title': "I divided the photo's up into different areas. Let me know the areas where you are missing items.",
        'translate.the_traces_standard.guest_message1.title': "She's gone. But she left a couple of pictures. Apparantly she is using some of this junk.",
        'translate.the_traces_standard.guest_message2.title': "I divided the photo's up into different areas. Let me know the areas where you are missing items.",
        'translate.the_traces_standard.guest_message2.error': "I cannot find anything based on these results. Please check again",
        'translate.the_traces_standard.guest_message3.title': "OK, I looked these items up in the database. They have been used in a shed somewhere...",


        'translate.the_traces_challenging.player.hint.explanation': "Your goals is to find all zones where your items are missing. To do so, you need to describe exactly what you see to the other player. The person that is missing an item needs to tag the zone where this item is missing. So if you (main player) misses an item on the top left, you will tag zone 51. If the other player misses an item top left, they will tag another number (but not 51). To solve it you must enter all the zones where items are missing (for both players)",
        'translate.the_traces_challenging.player.hint.hint1': "Each player is missing 3 items.",
        'translate.the_traces_challenging.player.hint.hint2': "One of the items you are missing is in zone 43. You are not missing any items in zone 11.",
        'translate.the_traces_challenging.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
        'translate.the_traces_challenging.guest.hint.explanation': "Your goals is to find all zones where your items are missing. To do so, you need to describe exactly what you see to the other player. The person that is missing an item needs to tag the zone where this item is missing. So if you (main player) misses an item on the top left, you will tag zone 51. If the other player misses an item top left, they will tag another number (but not 51). To solve it you must enter all the zones where items are missing (for both players)",
        'translate.the_traces_challenging.guest.hint.hint1': "Each player is missing 3 items.",
        'translate.the_traces_challenging.guest.hint.hint2': "One of the items you are missing is in zone 57. You are not missing any items in zone 77.",
        'translate.the_traces_challenging.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
        'translate.the_traces_challenging.message1.title': "She's gone. But she left a couple of pictures. Apparantly she is using some of this junk.",
        'translate.the_traces_challenging.message2.error': "I cannot find anything based on these results. Please check again",
        'translate.the_traces_challenging.message2.title': "I divided the photo's up into different areas. Let me know the areas where you are missing items.",
        'translate.the_traces_challenging.message3.title': "I divided the photo's up into different areas. Let me know the areas where you are missing items.",
        'translate.the_traces_challenging.guest_message1.title': "She's gone. But she left a couple of pictures. Apparantly she is using some of this junk.",
        'translate.the_traces_challenging.guest_message2.title': "I divided the photo's up into different areas. Let me know the areas where you are missing items.",
        'translate.the_traces_challenging.guest_message2.error': "I cannot find anything based on these results. Please check again",
        'translate.the_traces_challenging.guest_message3.title': "OK, I looked these items up in the database. They have been used in a shed somewhere...",

        'translate.the_key_standard.timer.title': "4. Drone - A Toxic Case",
        'translate.the_key_standard.timer.subtitle': "De volgende opdracht start over:",
        'translate.the_key_standard.player.timer.message1': 'Ik heb gekeken naar de wegen en zo de eerste 2 zones gevonden. De drone vliegt eerst over zone Y en daarna over zone L.',
        'translate.the_key_standard.player.timer.message2': 'Er zijn een aantal zones waar de drone niet komt. Dit zijn zones C,E,P,Q,T en V. Ik moet wel nog de juiste volgorde te weten zodat ik kan timen wanneer ik moet gaan.',
        'translate.the_key_standard.guest.timer.message1': 'Ik heb gekeken naar de wegen en zo de eerste 2 zones gevonden. De drone vliegt eerst over zone Y en daarna over zone L.',
        'translate.the_key_standard.guest.timer.message2': 'Er zijn een aantal zones waar de drone niet komt. Dit zijn zones C,E,P,Q,T en V. Ik moet wel nog de juiste volgorde te weten zodat ik kan timen wanneer ik moet gaan.',
        'translate.the_key_standard.player.hint.explanation': "De drone vliegt in 6 zones. Het doel is de juiste zones en de juiste volgorde te vinden. Dit doe je door te kijken naar de vorm van de wegen en de gebouwen.",
        'translate.the_key_standard.player.hint.hint1': "De eerste zone is zone Y en de tweede zone is zone L.",
        'translate.the_key_standard.player.hint.hint2': "De zones waar de drone niet overheen vliegt zijn C, E, P, Q, T, V.",
        'translate.the_key_standard.player.hint.solution': "De volgorde is Y - L - B - A - M - S. <br> In de eerste zone zie je drie gebouwen, twee kleinere en een grotere in het midden met gras aan de rechterkant. Het gebouw aan de rechterkant heeft een vorm waarin rechtsboven en linksonder een vierkant mist. Jij hebt dit gebouw op jouw kaart en jouw medespeler heeft het lange gebouw in het midden. De 2e zone heeft een weg die schuin loopt, dus dat moet zone L zijn. De derde zone laat 3 gebouwen zien, waarvan het middelste gebouw een vorm heeft die bij jou zichtbaar is (B). De vierde zone zie je een groot gebouw dat ook op jouw kaart zichtbaar is (A). De vijfde zone laat drie gebouwen zien die in qua vorm aflopen van links naar rechts (M). De zesde zone laat twee gebouwen waarvan jij er een kan herkennen in zone S.",
        'translate.the_key_standard.guest.hint.explanation': "De drone vliegt in 6 zones. Het doel is de juiste zones en de juiste volgorde te vinden. Dit doe je door te kijken naar de vorm van de wegen en de gebouwen.",
        'translate.the_key_standard.guest.hint.hint1': "De eerste zone is zone Y en de tweede zone is zone L.",
        'translate.the_key_standard.guest.hint.hint2': "De zones waar de drone niet overheen vliegt zijn C, E, P, Q, T, V.",
        'translate.the_key_standard.guest.hint.solution': "De volgorde is Y - L - B - A - M - S. <br> In de eerste zone zie je drie gebouwen, twee kleinere en een grotere in het midden met gras aan de rechterkant. Het gebouw aan de rechterkant heeft een vorm waarin rechtsboven en linksonder een vierkant mist. Jouw medespeler heeft dit gebouw op de kaart en jij hebt het lange gebouw in het midden op jouw kaart. De 2e zone heeft een weg die schuin loopt, dus dat moet zone L zijn. De derde zone laat 3 gebouwen zien, waarvan het middelste gebouw een vorm heeft die bij jouw medespeler goed te zien is (B). De vierde zone zie je twee gebouwen onder elkaar die op jouw kaart staan (A). De vijfde zone laat drie gebouwen zien die in qua vorm aflopen van links naar rechts (M). De zesde zone laat twee gebouwen waarvan jij er een kan herkennen in zone S.",
        'translate.the_key_standard.message1.title': "Dit gebied wordt bewaakt door een drone. Het is gelukt om de beelden te hacken, maar ik weet nog niet hoe ik ongezien binnen moet komen. John heeft 2 verschillende schetsen gemaakt van het gebied. Ik stuur jou een schets van de kantoren en {{partnerName}} een schets van de laboratoria in dit gebied. Kijk naar zowel de gebouwen als de wegen.",
        'translate.the_key_standard.message2.title': "Laat me weten in welke zones de drone vliegt en in welke volgorde, zodat ik een manier kan vinden om erlangs te komen.",
        'translate.the_key_standard.message2.error': "Ik zag de drone ergens anders vliegen dan wat je appte. We moeten er zeker van zijn. Gebruik de TAG ANTWOORD link uit het hierboven nog een keer en laat het me weten.",
        'translate.the_key_standard.message3.title': "Dat lijkt te kloppen. Ik ga een poging wagen!",
        'translate.the_key_standard.guest_message1.title': "Dit gebied wordt bewaakt door een drone. Het is gelukt om de beelden te hacken, maar ik weet nog niet hoe ik ongezien binnen moet komen. John heeft 2 verschillende schetsen gemaakt van het gebied. Ik stuur jou een schets van de laboratoria en {{partnerName}} een schets van de kantoren in dit gebied. Kijk naar zowel de gebouwen als de wegen",
        'translate.the_key_standard.guest_message2.title': "Laat me weten in welke zones de drone vliegt en in welke volgorde, zodat ik een manier kan vinden om erlangs te komen.",
        'translate.the_key_standard.guest_message2.error': "Ik zag de drone ergens anders vliegen dan wat je appte. We moeten er zeker van zijn. Gebruik de TAG ANTWOORD link uit het hierboven nog een keer en laat het me weten.",
        'translate.the_key_standard.guest_message3.title': "Dat lijkt te kloppen. Ik ga een poging wagen!",

        'translate.the_key_challenging.timer.title': "4. Drone - A Toxic Case",
        'translate.the_key_challenging.timer.subtitle': "De volgende opdracht start over:",
        'translate.the_key_challenging.player.timer.message1': 'Ik heb gekeken naar de wegen en zo de eerste 2 zones gevonden. De drone vliegt eerst over zone Y en daarna over zone L.',
        'translate.the_key_challenging.player.timer.message2': 'Er zijn een aantal zones waar de drone niet komt. Dit zijn zones C,E,P,Q,T en V. Ik moet wel nog de juiste volgorde te weten zodat ik kan timen wanneer ik moet gaan.',
        'translate.the_key_challenging.guest.timer.message1': 'Ik heb gekeken naar de wegen en zo de eerste 2 zones gevonden. De drone vliegt eerst over zone Y en daarna over zone L.',
        'translate.the_key_challenging.guest.timer.message2': 'Er zijn een aantal zones waar de drone niet komt. Dit zijn zones C,E,P,Q,T en V. Ik moet wel nog de juiste volgorde te weten zodat ik kan timen wanneer ik moet gaan.',
        'translate.the_key_challenging.player.hint.explanation': "De drone vliegt in 6 zones. Het doel is de juiste zones en de juiste volgorde te vinden. Dit doe je door te kijken naar de vorm van de wegen en de gebouwen. Elke speler heeft andere gebouwen op de kaart staan.",
        'translate.the_key_challenging.player.hint.hint1': "De eerste zone is zone Y en de tweede zone is zone L.",
        'translate.the_key_challenging.player.hint.hint2': "De zones waar de drone niet overheen vliegt zijn C, E, P, Q, T, V.",
        'translate.the_key_challenging.player.hint.solution': "De volgorde is Y - L - B - A - M - S. <br> In de eerste zone zie je drie gebouwen, twee kleinere en een grotere in het midden met gras aan de rechterkant. Het gebouw aan de rechterkant heeft een vorm waarin rechtsboven en linksonder een vierkant mist. Jij hebt dit gebouw op jouw kaart en jouw medespeler heeft het lange gebouw in het midden. De 2e zone heeft een weg die schuin loopt, dus dat moet zone L zijn. De derde zone laat 3 gebouwen zien, waarvan het middelste gebouw een vorm heeft die bij jou zichtbaar is (B). De vierde zone zie je een groot gebouw dat ook op jouw kaart zichtbaar is (A). De vijfde zone laat drie gebouwen zien die in qua vorm aflopen van links naar rechts (M). De zesde zone laat twee gebouwen waarvan jij er een kan herkennen in zone S.",
        'translate.the_key_challenging.guest.hint.explanation': "De drone vliegt in 6 zones. Het doel is de juiste zones en de juiste volgorde te vinden. Dit doe je door te kijken naar de vorm van de wegen en de gebouwen. Elke speler heeft andere gebouwen op de kaart staan.",
        'translate.the_key_challenging.guest.hint.hint1': "De eerste zone is zone Y en de tweede zone is zone L.",
        'translate.the_key_challenging.guest.hint.hint2': "De zones waar de drone niet overheen vliegt zijn C, E, P, Q, T, V.",
        'translate.the_key_challenging.guest.hint.solution': "De volgorde is Y - L - B - A - M - S. <br> In de eerste zone zie je drie gebouwen, twee kleinere en een grotere in het midden met gras aan de rechterkant. Het gebouw aan de rechterkant heeft een vorm waarin rechtsboven en linksonder een vierkant mist. Jouw medespeler heeft dit gebouw op de kaart en jij hebt het lange gebouw in het midden op jouw kaart. De 2e zone heeft een weg die schuin loopt, dus dat moet zone L zijn. De derde zone laat 3 gebouwen zien, waarvan het middelste gebouw een vorm heeft die bij jouw medespeler goed te zien is (B). De vierde zone zie je twee gebouwen onder elkaar die op jouw kaart staan (A). De vijfde zone laat drie gebouwen zien die in qua vorm aflopen van links naar rechts (M). De zesde zone laat twee gebouwen waarvan jij er een kan herkennen in zone S.",
        'translate.the_key_challenging.message1.title': "Dit gebied wordt bewaakt door een drone. Het is gelukt om de beelden te hacken, maar ik weet nog niet hoe ik ongezien binnen moet komen. John heeft schetsen van het gebied gemaakt, ik stuur ze door.",
        'translate.the_key_challenging.message2.title': "Laat me weten in welke zones de drone vliegt en in welke volgorde, dan kan ik een manier vinden om erlangs te komen.",
        'translate.the_key_challenging.message2.error': "Ik zag de drone ergens anders vliegen dan wat je appte. We moeten er zeker van zijn. Controleer het nog een keer en laat het me weten.",
        'translate.the_key_challenging.message3.title': "Dat lijkt te kloppen. Ik ga een poging wagen!",
        'translate.the_key_challenging.guest_message1.title': "Dit gebied wordt bewaakt door een drone. Het is gelukt om de beelden te hacken, maar ik weet nog niet hoe ik ongezien binnen moet komen. John heeft schetsen van het gebied gemaakt, ik stuur ze door.",
        'translate.the_key_challenging.guest_message2.error': "Ik zag de drone ergens anders vliegen dan wat je appte. We moeten er zeker van zijn. Controleer het nog een keer en laat het me weten.",
        'translate.the_key_challenging.guest_message2.title': "Laat me weten in welke zones de drone vliegt en in welke volgorde, zodat ik een manier kan vinden om erlangs te komen.",
        'translate.the_key_challenging.guest_message3.title': "Dat lijkt te kloppen. Ik ga een poging wagen!",

        'translate.whodunnit_standard.timer.title': "7. Enclose - A Toxic Case",
        'translate.whodunnit_standard.timer.subtitle': "De volgende opdracht start over:",
        'translate.whodunnit_standard.player.timer.message1': 'Ik heb geteld hoe vaak de deuren open en dicht gaan. Ze gaat 8 keer een andere kamer in, waaronder ruimte 270 waar ze start.',
        'translate.whodunnit_standard.player.timer.message2': 'Jennifer zegt dat ze niets heeft gehoord, ook niet in de kamers naast haar. Dit betekent dat Linda niet in kamers 222 en 240 is geweest. Hebben jullie haar op de camera gezien in kamer 201?',
        'translate.whodunnit_standard.guest.timer.message1': 'Ik heb geteld hoe vaak de deuren open en dicht gaan. Ze gaat 8 keer een andere kamer in, waaronder ruimte 270 waar ze start.',
        'translate.whodunnit_standard.guest.timer.message2': 'Jennifer zegt dat ze niets heeft gehoord, ook niet in de kamers naast haar. Dit betekent dat Linda niet in kamers 222 en 240 is geweest. Hebben jullie haar op de camera gezien in kamer 201?',
        'translate.whodunnit_standard.player.hint.explanation': "Jullie doel is om de route die Linda neemt in de juiste volgorde aan te geven. Probeer eerst uit te zoeken waar Jennifer en Justin (de onderzoeker) zitten. Wanneer horen ze iets en wanneer niet?",
        'translate.whodunnit_standard.player.hint.hint1': "Justin, de onderzoeker, zit in het toilet dat vast zit aan ruimte 281. Hij hoort alleen iets in de kamer naast hem nadat Linda Steve is gepasseerd. In totaal moeten we 8 kamers vinden, inclusief het startpunt 270.",
        'translate.whodunnit_standard.player.hint.hint2': "Jennifer zegt dat ze niets heeft gehoord, ook niet in de kamers naast haar. Dit betekent dat Linda niet in kamers 222 en 240 is geweest. Ze is niet op camera gezien in 201, dus ze moet door ruimte 217 naar de gang zijn gegaan.",
        'translate.whodunnit_standard.player.hint.solution': "De route is 270 - 228 - 266 - 204 - 217 - 281 - 247 - 292. <br> Je ziet Linda eerst in een grote ruimte, wat geen kantoor is. Daarna zie je haar even niet (dus is ze niet naar 290 gegaan) en dan weer op camera in een ruimte met ramen waarbij er aan beide kanten deuren naar buiten opengaan. Dit moet ruimte 266 zijn, dus moet ze langs 228 zijn gegaan. Op enig moment daarna is Linda in de gang. Eerst hoort Steve haar en daarna hoort Justin haar pas voor het eerst in de ruimte naast hem. Justin zit dus in het toilet naast 281 en niet naast 270, 228 of 204, want die grenzen aan ruimtes waar ze eerder is geweest. Daarna is ze op camera weer te zien in ruimte 292 (dus moet ze door 247 zijn gegaan) en vanuit daar weer terug waar ze begon (270). Omdat Jennifer niets heeft gehoord in de ruimtes naast haar, is de enige mogelijkheid dat Jennifer in de opslagruimte zat naast 222 and 240. Dit betekent dat Linda door ruimte 217 naar de gang is gegaan. Als ze door 201 was gegaan, was ze gezien op camera.",
        'translate.whodunnit_standard.guest.hint.explanation': "Jullie doel is om de route die Linda neemt in de juiste volgorde aan te geven. Probeer eerst uit te zoeken waar Jennifer en Justin (de onderzoeker) zitten. Wanneer horen ze iets en wanneer niet?",
        'translate.whodunnit_standard.guest.hint.hint1': "Justin, de onderzoeker, zit in het toilet dat vast zit aan ruimte 281. Hij hoort alleen iets in de kamer naast hem nadat Linda Steve is gepasseerd. In totaal moeten we 8 kamers vinden, inclusief het startpunt 270.",
        'translate.whodunnit_standard.guest.hint.hint2': "Jennifer zegt dat ze niets heeft gehoord, ook niet in de kamers naast haar. Dit betekent dat Linda niet in kamers 222 en 240 is geweest. Ze is niet op camera gezien in 201, dus ze moet door ruimte 217 naar de gang zijn gegaan.",
        'translate.whodunnit_standard.guest.hint.solution': "De route is 270 - 228 - 266 - 204 - 217 - 281 - 247 - 292. <br> Je ziet Linda eerst in een grote ruimte, wat geen kantoor is. Daarna zie je haar even niet (dus is ze niet naar 290 gegaan) en dan weer op camera in een ruimte met ramen waarbij er aan beide kanten deuren naar buiten opengaan. Dit moet ruimte 266 zijn, dus moet ze langs 228 zijn gegaan. Op enig moment daarna is Linda in de gang. Eerst hoort Steve haar en daarna hoort Justin haar pas voor het eerst in de ruimte naast hem. Justin zit dus in het toilet naast 281 en niet naast 270, 228 of 204, want die grenzen aan ruimtes waar ze eerder is geweest. Daarna is ze op camera weer te zien in ruimte 292 (dus moet ze door 247 zijn gegaan) en vanuit daar weer terug waar ze begon (270). Omdat Jennifer niets heeft gehoord in de ruimtes naast haar, is de enige mogelijkheid dat Jennifer in de opslagruimte zat naast 222 and 240. Dit betekent dat Linda door ruimte 217 naar de gang is gegaan. Als ze door 201 was gegaan, was ze gezien op camera.",
        'translate.whodunnit_standard.message1.title': "We moeten de bommen onschadelijk maken. Ik heb contact met twee mensen op deze verdieping die ons kunnen helpen. Jennifer, een andere bewaker en Justin, een van de onderzoekers. Ik heb ze alles verteld wat ik weet.",
        'translate.whodunnit_standard.message2.title': "Kunnen jullie uitzoeken welke route ze loopt? Ze start in kamer 270. Kijk of je de kamers op camera kan herkennen en luister goed wat Justin en Jennifer zeggen.",
        'translate.whodunnit_standard.message2.error': "Ik weet niet zeker of dit klopt. We hebben maar 1 kans, kunnen jullie het nog een keer controleren? Gebruik de TAG ANTWOORD link hierboven en laat me weten welke route ze neemt. We moeten er zeker van zijn.",
        'translate.whodunnit_standard.message3.title': "Ik hoop dat het klopt, we moeten nu iets doen! Als Linda 204 binnengaat, kan Justin naar 266 om de bom onschadelijk te maken. Ik wacht zo lang mogelijk en schakel de bom uit in 270. Bekijk eerst de volgende video (8. The End) voordat je op Score klikt.",
        'translate.whodunnit_standard.guest_message1.title': "We moeten de bommen onschadelijk maken. Ik heb contact met twee mensen op deze verdieping die ons kunnen helpen. Jennifer, een andere bewaker en Justin, een van de onderzoekers. Ik heb ze alles verteld wat ik weet.",
        'translate.whodunnit_standard.guest_message2.title': "Kunnen jullie uitzoeken welke route ze loopt? Ze start in kamer 270. Kijk of je de kamers op camera kan herkennen en luister goed wat Justin en Jennifer zeggen.",
        'translate.whodunnit_standard.guest_message2.error': "Ik weet niet zeker of dit klopt. We hebben maar 1 kans, kunnen jullie het nog een keer controleren? Gebruik de TAG ANTWOORD link hierboven en laat me weten welke route ze neemt. We moeten er zeker van zijn.",
        'translate.whodunnit_standard.guest_message3.title': "Ik hoop dat het klopt, we moeten nu iets doen! Als Linda 204 binnengaat, kan Justin naar 266 om de bom onschadelijk te maken. Ik wacht zo lang mogelijk en schakel de bom uit in 270. Bekijk eerst de volgende video (8. The End) voordat je op Score klikt.",

        'translate.whodunnit_challenging.timer.title': "7. Enclose - A Toxic Case",
        'translate.whodunnit_challenging.timer.subtitle': "De volgende opdracht start over:",
        'translate.whodunnit_challenging.player.timer.message1': 'Ik heb geteld hoe vaak de deuren open en dicht gaan. Ze gaat 8 keer een andere kamer in, waaronder ruimte 270 waar ze start. Probeer uit zoeken van welke kamers we camera beelden hebben door te kijken naar de positie van de deuren.',
        'translate.whodunnit_challenging.player.timer.message2': 'We zien Linda nooit op camera in kamer 201 verschijnen. Jennifer zit volgens mij in de opslag rechtsboven. Ze zegt dat ze niets heeft gehoord, ook niet in de kamers naast haar.',
        'translate.whodunnit_challenging.guest.timer.message1': 'Ik heb geteld hoe vaak de deuren open en dicht gaan. Ze gaat 8 keer een andere kamer in, waaronder ruimte 270 waar ze start. Probeer uit zoeken van welke kamers we camera beelden hebben door te kijken naar de positie van de deuren.',
        'translate.whodunnit_challenging.guest.timer.message2': 'We zien Linda nooit op camera in kamer 201 verschijnen. Jennifer zit volgens mij in de opslag rechtsboven. Ze zegt dat ze niets heeft gehoord, ook niet in de kamers naast haar.',
        'translate.whodunnit_challenging.player.hint.explanation': "Jullie doel is om de route die Linda neemt in de juiste volgorde aan te geven. Probeer eerst uit te zoeken waar Justin en Jennifer zitten. Wanneer horen ze iets en wanneer niet? En horen ze iets in de kamers die aan hen grenzen? Wat zegt dit over waar ze zitten?",
        'translate.whodunnit_challenging.player.hint.hint1': "Justin, de onderzoeker zit in het toilet naast ruimte 281. Hij hoort alleen iets in de kamer naast hem als Linda Steve is gepasseerd. Jennifer heeft de hele tijd niets gehoord, ook niet in de ruimtes naast haar. Op welke plek kan zij zitten?",
        'translate.whodunnit_challenging.player.hint.hint2': "Jennifer zit in de opslagruimte rechtsboven op de kaart. Ze heeft niets gehoord, ook niet in de ruimtes naast haar. Dit betekent dat Linda door ruimte 217 is gegaan naar de gang met de bewegingsdetector.",
        'translate.whodunnit_challenging.player.hint.solution': "De route is 270 - 228 - 266 - 204 - 217 - 281 - 247 - 292. <br> Je ziet Linda eerst in een grote ruimte, wat geen kantoor is. Daarna zie je haar even niet (dus is ze niet naar 290 gegaan) en dan weer op camera in een ruimte met ramen waarbij er aan beide kanten deuren naar buiten opengaan. Dit moet ruimte 266 zijn, dus moet ze langs 228 zijn gegaan. Op enig moment daarna is Linda in de gang. Eerst hoort Steve haar en daarna hoort Justin haar pas voor het eerst in de ruimte naast hem. Justin zit dus in het toilet naast 281 en niet naast 270, 228 of 204, want die grenzen aan ruimtes waar ze eerder is geweest. Daarna is ze op camera weer te zien in ruimte 292 (dus moet ze door 247 zijn gegaan) en vanuit daar weer terug waar ze begon (270). Omdat Jennifer niets heeft gehoord in de ruimtes naast haar, is de enige mogelijkheid dat Jennifer in de opslagruimte zat naast 222 and 240. Dit betekent dat Linda door ruimte 217 naar de gang is gegaan. Als ze door 201 was gegaan, was ze gezien op camera.",
        'translate.whodunnit_challenging.guest.hint.explanation': "Jullie doel is om de route die Linda neemt in de juiste volgorde aan te geven. Probeer eerst uit te zoeken waar Justin en Jennifer zitten. Wanneer horen ze iets en wanneer niet? En horen ze iets in de kamers die aan hen grenzen? Wat zegt dit over waar ze zitten?",
        'translate.whodunnit_challenging.guest.hint.hint1': "Justin, de onderzoeker zit in het toilet naast ruimte 281. Hij hoort alleen iets in de kamer naast hem als Linda Steve is gepasseerd. Jennifer heeft de hele tijd niets gehoord, ook niet in de ruimtes naast haar. Op welke plek kan zij zitten?",
        'translate.whodunnit_challenging.guest.hint.hint2': "Jennifer zit in de opslagruimte rechtsboven op de kaart. Ze heeft niets gehoord, ook niet in de ruimtes naast haar. Dit betekent dat Linda door ruimte 217 is gegaan naar de gang met de bewegingsdetector.",
        'translate.whodunnit_challenging.guest.hint.solution': "De route is 270 - 228 - 266 - 204 - 217 - 281 - 247 - 292. <br> Je ziet Linda eerst in een grote ruimte, wat geen kantoor is. Daarna zie je haar even niet (dus is ze niet naar 290 gegaan) en dan weer op camera in een ruimte met ramen waarbij er aan beide kanten deuren naar buiten opengaan. Dit moet ruimte 266 zijn, dus moet ze langs 228 zijn gegaan. Op enig moment daarna is Linda in de gang. Eerst hoort Steve haar en daarna hoort Justin haar pas voor het eerst in de ruimte naast hem. Justin zit dus in het toilet naast 281 en niet naast 270, 228 of 204, want die grenzen aan ruimtes waar ze eerder is geweest. Daarna is ze op camera weer te zien in ruimte 292 (dus moet ze door 247 zijn gegaan) en vanuit daar weer terug waar ze begon (270). Omdat Jennifer niets heeft gehoord in de ruimtes naast haar, is de enige mogelijkheid dat Jennifer in de opslagruimte zat naast 222 and 240. Dit betekent dat Linda door ruimte 217 naar de gang is gegaan. Als ze door 201 was gegaan, was ze gezien op camera.",
        'translate.whodunnit_challenging.message1.title': "We moeten de bommen onschadelijk maken. Ik heb contact met twee mensen op deze verdieping die ons kunnen helpen. Jennifer, een andere bewaker, en Justin, een van de onderzoekers. Ik heb ze alles verteld wat ik weet.",
        'translate.whodunnit_challenging.message2.title': "Kunnen jullie uitzoeken welke route ze loopt? Probeer eerst te kijken van welke kamers we camera toegang hebben.",
        'translate.whodunnit_challenging.message2.error': "Ik weet niet zeker of dit klopt. We hebben maar 1 kans, kunnen jullie het nog een keer controleren? Gebruik de TAG ANTWOORD link hierboven en laat me weten welke route ze neemt. We moeten er zeker van zijn.",
        'translate.whodunnit_challenging.message3.title': "Ik hoop dat het klopt, we moeten nu iets doen! Als Linda 204 binnengaat, kan Justin naar 266 om de bom onschadelijk te maken. Ik wacht zo lang mogelijk en schakel de bom uit in 270. Bekijk eerst de volgende video (8. The End) voordat je op Score klikt.",
        'translate.whodunnit_challenging.guest_message1.title': "We moeten de bommen onschadelijk maken. Ik heb contact met twee mensen op deze verdieping die ons kunnen helpen. Jennifer, een andere bewaker en Justin, een van de onderzoekers. Ik heb ze alles verteld wat ik weet.",
        'translate.whodunnit_challenging.guest_message2.title': "Kunnen jullie uitzoeken welke route ze loopt? Probeer eerst te kijken van welke kamers we camera toegang hebben.",
        'translate.whodunnit_challenging.guest_message2.error': "Ik weet niet zeker of dit klopt. We hebben maar 1 kans, kunnen jullie het nog een keer controleren? Gebruik de TAG ANTWOORD link hierboven en laat me weten welke route ze neemt. We moeten er zeker van zijn.",
        'translate.whodunnit_challenging.guest_message3.title': "Ik hoop dat het klopt, we moeten nu iets doen! Als Linda 204 binnengaat, kan Justin naar 266 om de bom onschadelijk te maken. Ik wacht zo lang mogelijk en schakel de bom uit in 270. Bekijk eerst de volgende video (8. The End) voordat je op Score klikt.",


        'translate.the_missing_items_standard.player.hint.explanation': "You both see another part of the floorplan. Tell your partner when someone in the video moves on your part of the map and where this person goes to. Try to remember which rooms are locked.",
        'translate.the_missing_items_standard.player.hint.hint1': "The first shot starts in the hallway and he tries to open door 318. It is locked. There are 8 doors locked in total",
        'translate.the_missing_items_standard.player.hint.hint2': "He didn't check rooms 315 and 319 so we don't know if they are locked. There are 2 other doors that he didn't check. The rest is all locked.",
        'translate.the_missing_items_standard.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
        'translate.the_missing_items_standard.guest.hint.explanation': "You both see another part of the floorplan. Tell your partner when someone in the video moves on your part of the map and where this person goes to. Try to remember which rooms are locked.",
        'translate.the_missing_items_standard.guest.hint.hint1': "The first shot starts in the hallway and he tries to open door 318. It is locked. There are 8 doors locked in total",
        'translate.the_missing_items_standard.guest.hint.hint2': "He didn't check rooms 315 and 319 so we don't know if they are locked. There are 2 other doors that he didn't check. The rest is all locked.",
        'translate.the_missing_items_standard.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
        'translate.the_missing_items_standard.message1.title': "So that's what happened. Maybe she is still here. I wonder how John ended up in that lab.",
        'translate.the_missing_items_standard.message2.title': "Let me know which rooms are locked, so I can narrow down where she is",
        'translate.the_missing_items_standard.message2.error': "Wait. Were these the right doors?",
        'translate.the_missing_items_standard.message3.title': "Let me know which rooms are locked, so I can narrow down where she is",
        'translate.the_missing_items_standard.guest_message1.title': "So that's what happened. Maybe she is still here. I wonder how John ended up in that lab.",
        'translate.the_missing_items_standard.guest_message2.title': "Let me know which rooms are locked, so I can narrow down where she is",
        'translate.the_missing_items_standard.guest_message2.error': "Wait. Were these the right doors?",
        'translate.the_missing_items_standard.guest_message3.title': "OK, she must have been in one of those four rooms. I'll try to find her",


        'translate.the_missing_items_challenging.player.hint.explanation': "You both see another part of the floorplan. Tell your partner when someone in the video moves on your part of the map and where this person goes to. Try to remember which rooms are locked.",
        'translate.the_missing_items_challenging.player.hint.hint1': "The first shot starts in the hallway and he tries to open door 318. It is locked. There are 8 doors locked in total",
        'translate.the_missing_items_challenging.player.hint.hint2': "He didn't check rooms 315 and 319 so we don't know if they are locked. There are 2 other doors that he didn't check. The rest is all locked.",
        'translate.the_missing_items_challenging.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
        'translate.the_missing_items_challenging.guest.hint.explanation': "You both see another part of the floorplan. Tell your partner when someone in the video moves on your part of the map and where this person goes to. Try to remember which rooms are locked.",
        'translate.the_missing_items_challenging.guest.hint.hint1': "The first shot starts in the hallway and he tries to open door 318. It is locked. There are 8 doors locked in total",
        'translate.the_missing_items_challenging.guest.hint.hint2': "He didn't check rooms 315 and 319 so we don't know if they are locked. There are 2 other doors that he didn't check. The rest is all locked.",
        'translate.the_missing_items_challenging.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
        'translate.the_missing_items_challenging.message1.title': "So that's what happened. Maybe she is still here. I wonder how John ended up in that lab.",
        'translate.the_missing_items_challenging.message2.title': "Let me know which rooms are locked, so I can narrow down where she is",
        'translate.the_missing_items_challenging.message2.error': "Wait. Were these the right doors?",
        'translate.the_missing_items_challenging.message3.title': "Let me know which rooms are locked, so I can narrow down where she is",
        'translate.the_missing_items_challenging.guest_message1.title': "So that's what happened. Maybe she is still here. I wonder how John ended up in that lab.",
        'translate.the_missing_items_challenging.guest_message2.title': "Let me know which rooms are locked, so I can narrow down where she is",
        'translate.the_missing_items_challenging.guest_message2.error': "Wait. Were these the right doors?",
        'translate.the_missing_items_challenging.guest_message3.title': "OK, she must have been in one of those four rooms. I'll try to find her",

        'translate.the_knight_standard.timer.title': "1. Open the Door - A Toxic Case",
        'translate.the_knight_standard.timer.subtitle': "The volgende opdracht start over:",
        'translate.the_knight_standard.player.timer.message1': 'Misschien heb je dit al gezien, maar op de beelden zie ik hem elke dag dezelfde vier dingen doen. Is er een verband tussen de volgorde van de dingen die hij doet en de code?',
        'translate.the_knight_standard.player.timer.message2': 'Ik denk dat ik iets heb gevonden. Als je kijkt naar het getal 4, zie je dat het het eerste cijfer is in de code voor vrijdag, het laatste getal in de code voor zaterdag en het tweede getal in de code voor zondag. Op de bewakingsbeelden zie ik dat het eerste wat hij doet op vrijdag, het laatste wat hij doet op zaterdag en het tweede wat hij doet op zondag hetzelfde zijn.',
        'translate.the_knight_standard.player.timer.message3': 'Kijk naar de tijden op de klok. Hij gebruikt de tijd op de klok als onderdeel van de code van die dag.',
        'translate.the_knight_standard.guest.timer.message1': 'Misschien heb je dit al gezien, maar op de beelden zie ik hem elke dag dezelfde vier dingen doen. Is er een verband tussen de volgorde van de dingen die hij doet en de code?',
        'translate.the_knight_standard.guest.timer.message2': 'Ik denk dat ik iets heb gevonden. Als je kijkt naar het getal 4, zie je dat het het eerste cijfer is in de code voor vrijdag, het laatste getal in de code voor zaterdag en het tweede getal in de code voor zondag. Op de bewakingsbeelden zie ik dat het eerste wat hij doet op vrijdag, het laatste wat hij doet op zaterdag en het tweede wat hij doet op zondag hetzelfde zijn.',
        'translate.the_knight_standard.guest.timer.message3': 'Kijk naar de tijden op de klok. Hij gebruikt de tijd op de klok als onderdeel van de code van die dag.',

        'translate.the_knight_standard.player.hint.explanation': "Je moet een 4 cijferige code doorsturen. Hiervoor moet je een verband ontdekken tussen de codes van vrijdag, zaterdag en zondag en de bewakingsbeelden van die dagen. Elke dag zijn er vier dingen die John doet. Elk getal van die dag is verbonden met een activiteit die hij doet. Je doel is te ontdekken hoe dit werkt en wat de code voor vandaag zou zijn.",
        'translate.the_knight_standard.player.hint.hint1': "Hij gebruikt alle dagen de cijfers 1,4 en 9. Alleen het andere cijfer wisselt. Het cijfer 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Op de bewakingsbeelden is het eerste wat hij doet op vrijdag, het laatste wat hij doet op zaterdag en het tweede wat hij doet op zondag hetzelfde.",
        'translate.the_knight_standard.player.hint.hint2': "John gebruikt de tijd op de klok als onderdeel van zijn code voor die dag. Bijvoorbeeld als het 8 uur op de klok is, gebruikt hij een 8 in de code.",
        'translate.the_knight_standard.player.hint.solution': "1459 is the code for vandaag. <br> John doet elke dag dezelfde dingen in een verschillende volgorde. Hij blaast de kaars uit, kijkt naar de klok, veegt zijn voeten en hangt iets aan de kapstok. Elke activiteit heeft zijn eigen getal. Hij blaast de kaars als eerste uit op vrijdag, als laatste op zaterdag en als tweede op zondag. Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Dus het uitblazen van de kaarsen staat voor het getal 4, hij blaast dit vandaag als tweede uit en is dus het tweede getal in de code voor vandaag. Dit werkt hetzelfde voor de andere getallen. Het enige verschil is de klok. Hier gebruikt hij steeds de tijd die dan op de klok staat als code. Dus op vrijdag eindigt de code met een 8, zaterdag is het tweede getal een 2 en vandaag is het derde getal een 5.",
        'translate.the_knight_standard.guest.hint.explanation': "Je moet een 4 cijferige code doorsturen. Hiervoor moet je een verband ontdekken tussen de codes van vrijdag, zaterdag en zondag en de bewakingsbeelden van die dagen. Elke dag zijn er vier dingen die John doet. Elk getal van die dag is verbonden met een activiteit die hij doet. Je doel is te ontdekken hoe dit werkt en wat de code voor vandaag zou zijn.",
        'translate.the_knight_standard.guest.hint.hint1': "Hij gebruikt alle dagen de cijfers 1,4 en 9. Alleen het andere cijfer wisselt. Het cijfer 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Op de bewakingsbeelden is het eerste wat hij doet op vrijdag, het laatste wat hij doet op zaterdag en het tweede wat hij doet op zondag hetzelfde.",
        'translate.the_knight_standard.guest.hint.hint2': "John gebruikt de tijd op de klok als onderdeel van zijn code voor die dag. Bijvoorbeeld als het 8 uur op de klok is, gebruikt hij een 8 in de code.",
        'translate.the_knight_standard.guest.hint.solution': "1459 is the code for vandaag. <br> John doet elke dag dezelfde dingen in een verschillende volgorde. Hij blaast de kaars uit, kijkt naar de klok, veegt zijn voeten en hangt iets aan de kapstok. Elke activiteit heeft zijn eigen getal. Hij blaast de kaars als eerste uit op vrijdag, als laatste op zaterdag en als tweede op zondag. Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Dus het uitblazen van de kaarsen staat voor het getal 4, hij blaast dit vandaag als tweede uit en is dus het tweede getal in de code voor vandaag. Dit werkt hetzelfde voor de andere getallen. Het enige verschil is de klok. Hier gebruikt hij steeds de tijd die dan op de klok staat als code. Dus op vrijdag eindigt de code met een 8, zaterdag is het tweede getal een 2 en vandaag is het derde getal een 5.",
        'translate.the_knight_standard.message1.title': "Ik kom er niet in. Hij heeft me de afgelopen dagen geappt, maar zijn eerdere codes werken niet. Ik zal jullie zijn berichten doorsturen.",
        'translate.the_knight_standard.message2.title': "Ik heb een 4 cijferige code nodig. Kunnen jullie achterhalen wat de code is voor vandaag?",
        'translate.the_knight_standard.message2.error': "Geprobeerd, maar deze code werkt niet. Heb je nog een ander idee wat de code zou kunnen zijn?",
        'translate.the_knight_standard.message3.title': "Dat is de juiste code, bedankt! Ik ga naar binnen.",
        'translate.the_knight_standard.guest_message1.title': "Ik kom er niet in. Hij heeft me de afgelopen dagen geappt, maar zijn eerdere codes werken niet. Ik zal jullie zijn berichten doorsturen.",
        'translate.the_knight_standard.guest_message2.title': "Ik heb een 4 cijferige code nodig. Kunnen jullie achterhalen wat de code is voor vandaag?",
        'translate.the_knight_standard.guest_message2.error': "Geprobeerd, maar deze code werkt niet. Heb je nog een ander idee wat de code zou kunnen zijn?",
        'translate.the_knight_standard.guest_message3.title': "Dat is de juiste code, bedankt! Ik ga naar binnen.",

        'translate.the_knight_challenging.timer.title': "1. Open the Door - A Toxic Case",
        'translate.the_knight_challenging.timer.subtitle': "De volgende opdracht start over:",
        'translate.the_knight_challenging.player.timer.message1': 'John wist waarschijnlijk al dat ik zijn bewakingsbeelden zou kunnen bekijken, dus er moet een verband zijn tussen die beelden en de codes die hij gebruikt.',
        'translate.the_knight_challenging.player.timer.message2': 'Ik denk dat ik iets heb gevonden. Als je kijkt naar het getal 4, zie je dat het het eerste cijfer is in de code voor vrijdag, het laatste getal in de code voor zaterdag en het tweede getal in de code voor zondag. Op de bewakingsbeelden zie ik dat het eerste wat hij doet op vrijdag, het laatste wat hij doet op zaterdag en het tweede wat hij doet op zondag hetzelfde zijn.',
        'translate.the_knight_challenging.player.timer.message3': 'Kijk naar de tijden op de klok. Hij gebruikt de tijd op de klok als onderdeel van de code van die dag.',
        'translate.the_knight_challenging.guest.timer.message1': 'John wist waarschijnlijk al dat ik zijn bewakingsbeelden zou kunnen bekijken, dus er moet een verband zijn tussen die beelden en de codes die hij gebruikt.',
        'translate.the_knight_challenging.guest.timer.message2': 'Ik denk dat ik iets heb gevonden. Als je kijkt naar het getal 4, zie je dat het het eerste cijfer is in de code voor vrijdag, het laatste getal in de code voor zaterdag en het tweede getal in de code voor zondag. Op de bewakingsbeelden zie ik dat het eerste wat hij doet op vrijdag, het laatste wat hij doet op zaterdag en het tweede wat hij doet op zondag hetzelfde zijn.',
        'translate.the_knight_challenging.guest.timer.message3': 'Kijk naar de tijden op de klok. Hij gebruikt de tijd op de klok als onderdeel van de code van die dag.',
        'translate.the_knight_challenging.player.hint.explanation': "Je moet een code van 4 getallen doorsturen. Je moet het verband zoeken tussen de codes van vrijdag, zaterdag en zondag en de bewakingsbeelden van die dagen. Elke dag zijn er vier dingen die John doet. Kun je een verband vinden met de vier cijferige code van die dag?",
        'translate.the_knight_challenging.player.hint.hint1': "Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Wat doet John het eerst op vrijdag, het laatst op zaterdag en als tweede op zondag?",
        'translate.the_knight_challenging.player.hint.hint2': "John gebruikt de tijd op de klok als onderdeel van zijn code voor die dag.",
        'translate.the_knight_challenging.player.hint.solution': "1459 is the code for vandaag. <br> John doet elke dag dezelfde dingen in een verschillende volgorde. Hij blaast de kaars uit, kijkt naar de klok, veegt zijn voeten en hangt iets aan de kapstok. Elke activiteit heeft zijn eigen getal. Hij blaast de kaars als eerste uit op vrijdag, als laatste op zaterdag en als tweede op zondag. Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Dus het uitblazen van de kaarsen staat voor het getal 4, hij blaast dit vandaag als tweede uit en is dus het tweede getal in de code voor vandaag. Dit werkt hetzelfde voor de andere getallen. Het enige verschil is de klok. Hier gebruikt hij steeds het tijdstip dat die dag op de klok staat als code. Dus op vrijdag eindigt de code met een 8, zaterdag is het tweede getal een 2 en vandaag is het derde getal een 5.",
        'translate.the_knight_challenging.guest.hint.explanation': "Je moet een code van 4 getallen doorsturen. Je moet het verband zoeken tussen de codes van vrijdag, zaterdag en zondag en de bewakingsbeelden van die dagen. Elke dag zijn er vier dingen die John doet. Kun je een verband vinden met de vier cijferige code van die dag?",
        'translate.the_knight_challenging.guest.hint.hint1': "Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Wat doet John het eerst op vrijdag, het laatst op zaterdag en als tweede op zondag?",
        'translate.the_knight_challenging.guest.hint.hint2': "John gebruikt de tijd op de klok als onderdeel van zijn code voor die dag.",
        'translate.the_knight_challenging.guest.hint.solution': "1459 is the code for vandaag. <br> John doet elke dag dezelfde dingen in een verschillende volgorde. Hij blaast de kaars uit, kijkt naar de klok, veegt zijn voeten en hangt iets aan de kapstok. Elke activiteit heeft zijn eigen getal. Hij blaast de kaars als eerste uit op vrijdag, als laatste op zaterdag en als tweede op zondag. Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Dus het uitblazen van de kaarsen staat voor het getal 4, hij blaast dit vandaag als tweede uit en is dus het tweede getal in de code voor vandaag. Dit werkt hetzelfde voor de andere getallen. Het enige verschil is de klok. Hier gebruikt hij steeds het tijdstip dat die dag op de klok staat als code. Dus op vrijdag eindigt de code met een 8, zaterdag is het tweede getal een 2 en vandaag is het derde getal een 5.",
        'translate.the_knight_challenging.message1.title': "Ik kom er niet in. Hij heeft me de afgelopen dagen geappt, maar zijn eerdere codes werken niet. Ik zal jullie zijn berichten doorsturen.",
        'translate.the_knight_challenging.message2.title': "Ik heb een 4 cijferige code nodig. Kunnen jullie achterhalen wat de code is voor vandaag?",
        'translate.the_knight_challenging.message2.error': "Geprobeerd, maar deze code werkt niet. Heb je nog een ander idee wat de code zou kunnen zijn?",
        'translate.the_knight_challenging.message3.title': "Dat is de juiste code, bedankt! Ik ga naar binnen.",
        'translate.the_knight_challenging.guest_message1.title': "Ik kom er niet in. Hij heeft me de afgelopen dagen geappt, maar zijn eerdere codes werken niet. Ik zal jullie zijn berichten doorsturen.",
        'translate.the_knight_challenging.guest_message2.title': "Ik heb een 4 cijferige code nodig. Kunnen jullie achterhalen wat de code is voor vandaag?",
        'translate.the_knight_challenging.guest_message2.error': "Geprobeerd, maar deze code werkt niet. Heb je nog een ander idee wat de code zou kunnen zijn?",
        'translate.the_knight_challenging.guest_message3.title': "Dat is de juiste code, bedankt! Ik ga naar binnen.",

        'translate.the_witnesses_standard.timer.title': "3. Password - A Toxic Case",
        'translate.the_witnesses_standard.timer.subtitle': "De volgende opdracht start over:",
        'translate.the_witnesses_standard.player.timer.message1': 'Volgens mij heeft hij die markers expres zo laten liggen. Maar wat is de link tussen die markers en de symbolen?',
        'translate.the_witnesses_standard.player.timer.message2': 'Er liggen 5 markers in de kamer. Een daarvan wijst naar een boek. Het boek ligt op de positie in de kamer die is aangegeven met X.',
        'translate.the_witnesses_standard.player.timer.message3': 'Volgens mij wijzen die markers naar een horloge, een kom, een vaas, een boek en een fles. Voor boek is de 4e letter een k, dus zou het woord moeten beginnen met een "k"',
        'translate.the_witnesses_standard.guest.timer.message1': 'Volgens mij heeft hij die markers expres zo laten liggen. Maar wat is de link tussen die markers en de symbolen?',
        'translate.the_witnesses_standard.guest.timer.message2': 'Er liggen 5 markers in de kamer. Een daarvan wijst naar een boek. Het boek ligt op de positie in de kamer die is aangegeven met X.',
        'translate.the_witnesses_standard.guest.timer.message3': 'Volgens mij wijzen die markers naar een horloge, een kom, een vaas, een boek en een fles. Voor boek is de 4e letter een k, dus zou het woord moeten beginnen met een "k"',
        'translate.the_witnesses_standard.player.hint.explanation': "Het antwoord is een woord met 5 letters. Om het te vinden moet je 5 verschillende voorwerpen vinden die in de kamer liggen. Een van de notities geeft een aanwijzing waar je deze kan vinden en wat de volgorde is. De andere notitie geeft aan welke letter je moet gebruiken van elk voorwerp.",
        'translate.the_witnesses_standard.player.hint.hint1': "Er liggen 5 markers in de kamer. Een daarvan wijst naar een boek. Het boek ligt op de positie in de kamer die is aangegeven met X.",
        'translate.the_witnesses_standard.player.hint.hint2': "De 5 markers wijzen naar een horloge, een kom, een vaas, een boek en een fles. De vierde letter van 'boek' is een 'k'. Dus het woord begint met een 'k'.",
        'translate.the_witnesses_standard.player.hint.solution': "Het wachtwoord is kamer. <br> De notitie met de symbolen geeft de plekken weer waar de voorwerpen in de kamer liggen en in welke volgorde ze moeten worden gebruikt. Op elke van deze plekken ligt een markeerstift die wijst naar een voorwerp. De andere notitie verschilt voor elke speler en geeft aan welke letter je nodig hebt van elk voorwerp. Op plek 1 ligt een markeerstift die wijst naar een boek. De vierde letter van boek is 'k'. Op plek 2 ligt een vaas (3e letter: 'a'). Op plek 3 een kom (3e letter: 'm'). Op plek 4 een fles (3e letter: 'e') en op plek 5 een horloge (3e letter: 'r').",
        'translate.the_witnesses_standard.guest.hint.explanation': "Het antwoord is een woord met 5 letters. Om het te vinden moet je 5 verschillende voorwerpen vinden die in de kamer liggen. Een van de notities geeft een aanwijzing waar je deze kan vinden en wat de volgorde is. De andere notitie geeft aan welke letter je moet gebruiken van elk voorwerp.",
        'translate.the_witnesses_standard.guest.hint.hint1': "Er liggen 5 markers in de kamer. Een daarvan wijst naar een boek. Het boek ligt op de positie in de kamer die is aangegeven met X.",
        'translate.the_witnesses_standard.guest.hint.hint2': "De 5 markers wijzen naar een horloge, een kom, een vaas, een boek en een fles. De vierde letter van 'boek' is een 'k'. Dus het woord begint met een 'k'.",
        'translate.the_witnesses_standard.guest.hint.solution': "Het wachtwoord is kamer. <br> De notitie met de symbolen geeft de plekken weer waar de voorwerpen in de kamer liggen en in welke volgorde ze moeten worden gebruikt. Op elke van deze plekken ligt een markeerstift die wijst naar een voorwerp. De andere notitie verschilt voor elke speler en geeft aan welke letter je nodig hebt van elk voorwerp. Op plek 1 ligt een markeerstift die wijst naar een boek. De vierde letter van boek is 'k'. Op plek 2 ligt een vaas (3e letter: 'a'). Op plek 3 een kom (3e letter: 'm'). Op plek 4 een fles (3e letter: 'e') en op plek 5 een horloge (3e letter: 'r').",
        'translate.the_witnesses_standard.message1.title': "Inloggen lukt niet, ik heb zijn wachtwoord nodig. Ik heb wat notities gevonden, verder vooral veel spullen.",
        'translate.the_witnesses_standard.message2.title': "Volgens mij hebben we een wachtwoord van 5 letters nodig. Ik denk dat we 5 spullen van zijn lijst moeten vinden van elk ding een letter moeten gebruiken om een woord te vormen. Maar wat hoort bij welk symbool?",
        'translate.the_witnesses_standard.message2.error': "Dit wachtwoord werkt niet. Heb je nog een alternatief?",
        'translate.the_witnesses_standard.message3.title': "Kamer...? Hoe dan ook, ik zit erin. Eens kijken wat hij aan het doen was",
        'translate.the_witnesses_standard.guest_message1.title': "Inloggen lukt niet, ik heb zijn wachtwoord nodig. Ik heb wat notities gevonden, verder vooral veel spullen.",
        'translate.the_witnesses_standard.guest_message2.title': "Volgens mij hebben we een wachtwoord van 5 letters nodig. Ik denk dat we 5 spullen van zijn lijst moeten vinden van elk ding een letter moeten gebruiken om een woord te vormen. Maar wat hoort bij welk symbool?",
        'translate.the_witnesses_standard.guest_message2.error': "Dit wachtwoord werkt niet. Heb je nog een alternatief?",
        'translate.the_witnesses_standard.guest_message3.title': "Kamer...? Hoe dan ook, ik zit erin. Eens kijken wat hij aan het doen was",

        'translate.the_witnesses_challenging.timer.title': "3. Password - A Toxic Case",
        'translate.the_witnesses_challenging.timer.subtitle': "De volgende opdracht start over:",
        'translate.the_witnesses_challenging.player.timer.message1': 'Volgens mij heb ik iets gevonden. Hij heeft 5 markers laten liggen, allemaal in een hele specifieke positie.',
        'translate.the_witnesses_challenging.player.timer.message2': 'Ik heb een letter van het woord gevonden. Een van de markers wijst naar het boek. Het boek ligt op positie X in de kamer. De vierde letter van boek is "k", dus het woord begint met een "k".',
        'translate.the_witnesses_challenging.guest.timer.message1': 'Volgens mij heb ik iets gevonden. Hij heeft 5 markers laten liggen, allemaal in een hele specifieke positie.',
        'translate.the_witnesses_challenging.guest.timer.message2': 'Ik heb een letter van het woord gevonden. Een van de markers wijst naar het boek. Het boek ligt op positie X in de kamer. De vierde letter van boek is "k", dus het woord begint met een "k".',
        'translate.the_witnesses_challenging.player.hint.explanation': "Het antwoord is een woord met 5 letters. Om het te vinden moet je 5 verschillende voorwerpen vinden die in de kamer liggen. Een van de notities geeft een aanwijzing waar je deze kan vinden en wat de volgorde is. De andere notitie geeft aan welke letter je moet gebruiken van elk voorwerp.",
        'translate.the_witnesses_challenging.player.hint.hint1': "Zoek de 5 markeerstiften. Waar liggen ze en waar wijzen ze naar?",
        'translate.the_witnesses_challenging.player.hint.hint2': "Er ligt een markeerstift op plek X die wijst naar een boek. De vierde letter van 'boek' is een 'k'. Dus het woord begint met een 'k'.",
        'translate.the_witnesses_challenging.player.hint.solution': "Het wachtwoord is kamer. <br> De notitie met de symbolen geeft de plekken weer waar de voorwerpen in de kamer liggen en in welke volgorde ze moeten worden gebruikt. Op elke van deze plekken ligt een markeerstift die wijst naar een voorwerp. De andere notitie verschilt voor elke speler en geeft aan welke letter je nodig hebt van elk voorwerp. Op plek 1 ligt een markeerstift die wijst naar een boek. De vierde letter van boek is 'k'. Op plek 2 ligt een vaas (3e letter: 'a'). Op plek 3 een kom (3e letter: 'm'). Op plek 4 een fles (3e letter: 'e') en op plek 5 een horloge (3e letter: 'r').",
        'translate.the_witnesses_challenging.guest.hint.explanation': "Het antwoord is een woord met 5 letters. Om het te vinden moet je 5 verschillende voorwerpen vinden die in de kamer liggen. Een van de notities geeft een aanwijzing waar je deze kan vinden en wat de volgorde is. De andere notitie geeft aan welke letter je moet gebruiken van elk voorwerp.",
        'translate.the_witnesses_challenging.guest.hint.hint1': "Zoek de 5 markeerstiften. Waar liggen ze en waar wijzen ze naar?",
        'translate.the_witnesses_challenging.guest.hint.hint2': "Er ligt een markeerstift op plek X die wijst naar een boek. De vierde letter van 'boek' is een 'k'. Dus het woord begint met een 'k'.",
        'translate.the_witnesses_challenging.guest.hint.solution': "Het wachtwoord is kamer. <br> De notitie met de symbolen geeft de plekken weer waar de voorwerpen in de kamer liggen en in welke volgorde ze moeten worden gebruikt. Op elke van deze plekken ligt een markeerstift die wijst naar een voorwerp. De andere notitie verschilt voor elke speler en geeft aan welke letter je nodig hebt van elk voorwerp. Op plek 1 ligt een markeerstift die wijst naar een boek. De vierde letter van boek is 'k'. Op plek 2 ligt een vaas (3e letter: 'a'). Op plek 3 een kom (3e letter: 'm'). Op plek 4 een fles (3e letter: 'e') en op plek 5 een horloge (3e letter: 'r').",
        'translate.the_witnesses_challenging.message1.title': "Inloggen lukt niet, ik heb zijn wachtwoord nodig. Ik heb wat notities gevonden, verder vooral veel spullen.",
        'translate.the_witnesses_challenging.message2.title': "Ik stuur de notities naar jullie door. Volgens mij zoeken we een wachtwoord van 5 letters.",
        'translate.the_witnesses_challenging.message2.error': "Dit wachtwoord werkt niet. Heb je nog een alternatief?",
        'translate.the_witnesses_challenging.message3.title': "Kamer...? Hoe dan ook, ik zit erin. Eens kijken wat hij aan het doen was",
        'translate.the_witnesses_challenging.guest_message1.title': "Inloggen lukt niet, ik heb zijn wachtwoord nodig. Ik heb wat notities gevonden, verder vooral veel spullen. Eeen plant, post-it, lepel, fles, kom, laptop, vaas en een multomap.",
        'translate.the_witnesses_challenging.guest_message2.title': "Ik stuur de notities naar jullie door. Volgens mij zoeken we een wachtwoord van 5 letters.",
        'translate.the_witnesses_challenging.guest_message2.error': "Dit wachtwoord werkt niet. Heb je nog een alternatief?",
        'translate.the_witnesses_challenging.guest_message3.title': "Kamer...? Hoe dan ook, ik zit erin. Eens kijken wat hij aan het doen was",

        'translate.the_interrogation_standard.timer.title': "5. Poison - A Toxic Case",
        'translate.the_interrogation_standard.timer.subtitle': "The volgende opdracht start over:",
        'translate.the_interrogation_standard.player.timer.message1': 'Ik zou zeggen dat zijn pupillen een gebruikelijk formaat hebben. Maar hoe is hij in contact gekomen met dit gif?',
        'translate.the_interrogation_standard.player.timer.message2': 'Hij heeft zweet op zijn voorhoofd. Misschien een effect van het gif?',
        'translate.the_interrogation_standard.guest.timer.message1': 'Ik zou zeggen dat zijn pupillen een gebruikelijk formaat hebben. Maar hoe is hij in contact gekomen met dit gif?',
        'translate.the_interrogation_standard.guest.timer.message2': 'Hij heeft zweet op zijn voorhoofd. Misschien een effect van het gif?',
        'translate.the_interrogation_standard.player.hint.explanation': "Je doel is om het nummer van het juiste gif door te sturen. Het gif heeft 5 verschillende eigenschappen (de 5 kolommen in de tabel) en beide spelers hebben verschillende informatie. Je zult de informatie van de spelers moeten combineren met aanwijzingen uit de video.",
        'translate.the_interrogation_standard.player.hint.hint1': "Er zijn 5 aanwijzingen in de video te vinden. Er ligt een koffie kopje op de vloer, hij heeft zweet op zijn voorhoofd, zijn vingers maken een vuist, er komt kwijl uit zijn mond en je kan zijn pupillen zien.",
        'translate.the_interrogation_standard.player.hint.hint2': "Het koffiekopje is een aanwijzing dat het gif via zijn mond zijn lichaam is binnengekomen. Er is niets ongebruikelijks te zien aan zijn pupillen.",
        'translate.the_interrogation_standard.player.hint.solution': "71164 is het gif dat past bij deze situatie. <br> John heeft zijn vingers in een vuist (pugnus - luchtwegen), er komt kwijl uit zijn mond (kitosis - minuten), er is niets ongebruikelijks aan zijn pupillen (andere types) en hij heeft zweet op zijn voorhoofd (synthetisch). Het koffiekopje geeft aan dat hij via de mond in contact is gekomen met het gif.",
        'translate.the_interrogation_standard.guest.hint.explanation': "Je doel is om het nummer van het juiste gif door te sturen. Het gif heeft 5 verschillende eigenschappen (de 5 kolommen in de tabel) en beide spelers hebben verschillende informatie. Je zult de informatie van de spelers moeten combineren met aanwijzingen uit de video.",
        'translate.the_interrogation_standard.guest.hint.hint1': "Er zijn 5 aanwijzingen in de video te vinden. Er ligt een koffie kopje op de vloer, hij heeft zweet op zijn voorhoofd, zijn vingers maken een vuist, er komt kwijl uit zijn mond en je kan zijn pupillen zien.",
        'translate.the_interrogation_standard.guest.hint.hint2': "Het koffiekopje is een aanwijzing dat het gif via zijn mond zijn lichaam is binnengekomen. Er is niets ongebruikelijks te zien aan zijn pupillen.",
        'translate.the_interrogation_standard.guest.hint.solution': "71164 is het gif dat past bij deze situatie. <br> John heeft zijn vingers in een vuist (pugnus - luchtwegen), er komt kwijl uit zijn mond (kitosis - minuten), er is niets ongebruikelijks aan zijn pupillen (andere types) en hij heeft zweet op zijn voorhoofd (synthetisch). Het koffiekopje geeft aan dat hij via de mond in contact is gekomen met het gif.",
        'translate.the_interrogation_standard.message1.title': "Hij is dood, geen duidelijke verwondingen. Ik vermoed dat hij is vergiftigd.",
        'translate.the_interrogation_standard.message2.title': "Kunnen jullie uitzoeken of er een type gif bestaat dat past bij deze situatie? Ik heb alleen het bijbehorende nummer nodig. Ik stuur jullie wat informatie door.",
        'translate.the_interrogation_standard.message2.error': "Dat gif past niet helemaal bij wat ik hier zie. Heb je nog een alternatief?",
        'translate.the_interrogation_standard.message3.title': "Dat lijkt te kloppen met wat ik hier zie. Ik denk dat het hetzelfde gif is waardoor die andere 2 mensen zijn overleden. Het is geen kant en klaar gif, misschien reageert het op een andere vloeistof?",
        'translate.the_interrogation_standard.guest_message1.title': "Hij is dood, geen duidelijke verwondingen. Ik vermoed dat hij is vergiftigd.",
        'translate.the_interrogation_standard.guest_message2.title': "Kunnen jullie uitzoeken of er een type gif bestaat dat past bij deze situatie? Ik heb alleen het bijbehorende nummer nodig. Ik stuur jullie wat informatie door.",
        'translate.the_interrogation_standard.guest_message2.error': "Dat gif past niet helemaal bij wat ik hier zie. Heb je nog een alternatief?",
        'translate.the_interrogation_standard.guest_message3.title': "Dat lijkt te kloppen met wat ik hier zie. Ik denk dat het hetzelfde gif is waardoor die andere 2 mensen zijn overleden. Het is geen kant en klaar gif, misschien reageert het op een andere vloeistof?",

        'translate.the_interrogation_challenging.timer.title': "5. Poison - A Toxic Case",
        'translate.the_interrogation_challenging.timer.subtitle': "The volgende opdracht start over:",
        'translate.the_interrogation_challenging.player.timer.message1': 'Ik zou zeggen dat zijn pupillen een gebruikelijk formaat hebben. Maar hoe is hij in contact gekomen met dit gif?',
        'translate.the_interrogation_challenging.player.timer.message2': 'Hij heeft zweet op zijn voorhoofd. Misschien een effect van het gif?',
        'translate.the_interrogation_challenging.guest.timer.message1': 'Ik zou zeggen dat zijn pupillen een gebruikelijk formaat hebben. Maar hoe is hij in contact gekomen met dit gif?',
        'translate.the_interrogation_challenging.guest.timer.message2': 'Hij heeft zweet op zijn voorhoofd. Misschien een effect van het gif?',
        'translate.the_interrogation_challenging.player.hint.explanation': "Je doel is om het nummer van het juiste gif door te sturen. Het gif heeft 5 verschillende eigenschappen (de 5 kolommen in de tabel) en beide spelers hebben verschillende informatie. Je zult de informatie van de spelers moeten combineren met aanwijzingen uit de video.",
        'translate.the_interrogation_challenging.player.hint.hint1': "Er zijn 5 aanwijzingen in de video te vinden. Er ligt een koffie kopje op de vloer, hij heeft zweet op zijn voorhoofd, zijn vingers maken een vuist, er komt kwijl uit zijn mond en je kan zijn pupillen zien.",
        'translate.the_interrogation_challenging.player.hint.hint2': "Het koffiekopje is een aanwijzing dat het gif via zijn mond zijn lichaam is binnengekomen. Er is niets ongebruikelijks te zien aan zijn pupillen.",
        'translate.the_interrogation_challenging.player.hint.solution': "71164 is het gif dat past bij deze situatie. <br> John heeft zijn vingers in een vuist (pugnus - luchtwegen), er komt kwijl uit zijn mond (kitosis - minuten), er is niets ongebruikelijks aan zijn pupillen (andere types) en hij heeft zweet op zijn voorhoofd (synthetisch). Het koffiekopje geeft aan dat hij via de mond in contact is gekomen met het gif.",
        'translate.the_interrogation_challenging.guest.hint.explanation': "Het doel is om het nummer van het juiste vergif door te sturen. Het vergif heeft 5 verschillende eigenschappen (de 5 kolommen in de tabel) en beide spelers hebben verschillende informatie. Alle aanwijzingen zijn in de video te zien of daaruit af te leiden.",
        'translate.the_interrogation_challenging.guest.hint.hint1': "Er zijn 5 aanwijzingen in de video te vinden. Er ligt een koffie kopje op de vloer, hij heeft zweet op zijn voorhoofd, zijn vingers maken een vuist, er komt kwijl uit zijn mond en je kan zijn pupillen zien.",
        'translate.the_interrogation_challenging.guest.hint.hint2': "Het koffiekopje is een aanwijzing dat het gif via zijn mond zijn lichaam is binnengekomen. Er is niets ongebruikelijks te zien aan zijn pupillen.",
        'translate.the_interrogation_challenging.guest.hint.solution': "71164 is het gif dat past bij deze situatie. <br> John heeft zijn vingers in een vuist (pugnus - luchtwegen), er komt kwijl uit zijn mond (kitosis - minuten), er is niets ongebruikelijks aan zijn pupillen (andere types) en hij heeft zweet op zijn voorhoofd (synthetisch). Het koffiekopje geeft aan dat hij via de mond in contact is gekomen met het gif.",
        'translate.the_interrogation_challenging.message1.title': "Hij is dood, geen duidelijke verwondingen. Ik vermoed dat hij is vergiftigd.",
        'translate.the_interrogation_challenging.message2.title': "Kunnen jullie uitzoeken of er een type gif bestaat dat past bij deze situatie? Ik heb alleen het bijbehorende nummer nodig. Ik stuur jullie wat informatie door.",
        'translate.the_interrogation_challenging.message2.error': "Dat gif past niet helemaal bij wat ik hier zie. Heb je nog een alternatief?",
        'translate.the_interrogation_challenging.message3.title': "Dat lijkt te kloppen met wat ik hier zie. Ik denk dat het hetzelfde gif is waardoor die andere 2 mensen zijn overleden. Het is geen kant en klaar gif, misschien reageert het op een andere vloeistof?",
        'translate.the_interrogation_challenging.guest_message1.title': "Hij is dood, geen duidelijke verwondingen. Ik vermoed dat hij is vergiftigd.",
        'translate.the_interrogation_challenging.guest_message2.title': "Kunnen jullie uitzoeken of er een type gif bestaat dat past bij deze situatie? Ik heb alleen het bijbehorende nummer nodig. Ik stuur jullie wat informatie door.",
        'translate.the_interrogation_challenging.guest_message2.error': "Dat gif past niet helemaal bij wat ik hier zie. Heb je nog een alternatief?",
        'translate.the_interrogation_challenging.guest_message3.title': "Dat lijkt te kloppen met wat ik hier zie. Ik denk dat het hetzelfde gif is waardoor die andere 2 mensen zijn overleden. Het is geen kant en klaar gif, misschien reageert het op een andere vloeistof?",

        'translate.the_security_tapes_standard.player.hint.explanation': "You have to figure out 4 combinations of an explosive type, a type of detonator and a wire colour. There are 5 combinations shown in the video, for example a switch next to a nail bomb, or a white wire connected to a clay bomb. Based on these clues you can deduct the rest.",
        'translate.the_security_tapes_standard.player.hint.hint1': "Which detonator matches the green wire and the yellow oil can explosive? The timer uses either orange, grey or white wires, the phone is connected to grey wire. The switch is connected to a nail bomb.",
        'translate.the_security_tapes_standard.player.hint.hint2': "The clay explosive has a white wire and uses a timer to detonate. It could not be a mobile phone (connected to a grey wire), nor a switch (connected to a nail explosive). The oil can has a green wire so that one cannot be connected to the timer. You have a gas can, a mobile phone with grey wire, a nail bomb connected to a switch and an orange wire left. Which combination can be made?",
        'translate.the_security_tapes_standard.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
        'translate.the_security_tapes_standard.guest.hint.explanation': "You have to figure out 4 combinations of an explosive type, a type of detonator and a wire colour. There are 5 combinations shown in the video, for example a switch next to a nail bomb, or a white wire connected to a clay bomb. Based on these clues you can deduct the rest.",
        'translate.the_security_tapes_standard.guest.hint.hint1': "Which detonator matches the green wire and the yellow oil can explosive? The timer uses either orange, grey or white wires, the phone is connected to grey wire. The switch is connected to a nail bomb.",
        'translate.the_security_tapes_standard.guest.hint.hint2': "The clay explosive has a white wire and uses a timer to detonate. It could not be a mobile phone (connected to a grey wire), nor a switch (connected to a nail explosive). The oil can has a green wire so that one cannot be connected to the timer. You have a gas can, a mobile phone with grey wire, a nail bomb connected to a switch and an orange wire left. Which combination can be made?",
        'translate.the_security_tapes_standard.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
        'translate.the_security_tapes_standard.message1.title': "She was making a bomb. We have to figure out which type so we are able to prevent it from detonating.",
        'translate.the_security_tapes_standard.message2.title': "I have sent you pictures for each explosive she is using. For each explosive, we need to figure out which detonator and which colour wire she is planning to use.",
        'translate.the_security_tapes_standard.message2.error': "No, it's not right. I see some combinations that are possible. Can you check again?",
        'translate.the_security_tapes_standard.message3.title': "OK, this makes sense. Now I can look up how to diffuse them. Let's go!",


        'translate.the_security_tapes_challenging.player.hint.explanation': "You have to figure out 4 combinations of an explosive type, a type of detonator and a wire colour. There are 5 combinations shown in the video, for example a switch next to a nail bomb, or a white wire connected to a clay bomb. Based on these clues you can deduct the rest.",
        'translate.the_security_tapes_challenging.player.hint.hint1': "Which detonator matches the green wire and the yellow oil can explosive? The timer uses either orange, grey or white wires, the phone is connected to grey wire. The switch is connected to a nail bomb.",
        'translate.the_security_tapes_challenging.player.hint.hint2': "The clay explosive has a white wire and uses a timer to detonate. It could not be a mobile phone (connected to a grey wire), nor a switch (connected to a nail explosive). The oil can has a green wire so that one cannot be connected to the timer. You have a gas can, a mobile phone with grey wire, a nail bomb connected to a switch and an orange wire left. Which combination can be made?",
        'translate.the_security_tapes_challenging.player.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
        'translate.the_security_tapes_challenging.guest.hint.explanation': "You have to figure out 4 combinations of an explosive type, a type of detonator and a wire colour. There are 5 combinations shown in the video, for example a switch next to a nail bomb, or a white wire connected to a clay bomb. Based on these clues you can deduct the rest.",
        'translate.the_security_tapes_challenging.guest.hint.hint1': "Which detonator matches the green wire and the yellow oil can explosive? The timer uses either orange, grey or white wires, the phone is connected to grey wire. The switch is connected to a nail bomb.",
        'translate.the_security_tapes_challenging.guest.hint.hint2': "The clay explosive has a white wire and uses a timer to detonate. It could not be a mobile phone (connected to a grey wire), nor a switch (connected to a nail explosive). The oil can has a green wire so that one cannot be connected to the timer. You have a gas can, a mobile phone with grey wire, a nail bomb connected to a switch and an orange wire left. Which combination can be made?",
        'translate.the_security_tapes_challenging.guest.hint.solution': "xxxx ENTER SOLUTION ANSWER HERE",
        'translate.the_security_tapes_challenging.message1.title': "She was making a bomb. We have to figure out which type so we are able to prevent it from detonating.",
        'translate.the_security_tapes_challenging.message2.title': "I have sent you pictures for each explosive she is using. For each explosive, we need to figure out which detonator and which colour wire she is planning to use.",
        'translate.the_security_tapes_challenging.message2.error': "No, it's not right. I see some combinations that are possible. Can you check again?",
        'translate.the_security_tapes_challenging.message3.title': "OK, this makes sense. Now I can look up how to diffuse them. Let's go!",

        'translate.the_card_game_standard.timer.title': "6. Who did it - A Toxic Case",
        'translate.the_card_game_standard.timer.subtitle': "De volgende opdracht start over:",
        'translate.the_card_game_standard.player.timer.message1': '{{playerName}}, kun jij kijken naar het aantal kopjes aan het begin en eind van elke video? Ik zal ook proberen om wat aantekeningen te maken.',
        'translate.the_card_game_standard.player.timer.message2': 'Ik heb wat aantekeningen gemaakt die ik met je kan delen. Nummer 31 eindigt met 1 kopje, maar zonder deurmat. 98 begint met 2 kopjes zonder deurmat en eindigt met 1 kopje en een deurmat. 22 begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.',
        'translate.the_card_game_standard.player.timer.message3': 'De tweede video zou moeten beginnen met 1 kopje en zonder deurmat en eindigen met 2 kopjes en met een deurmat. De derde video is nummer 22. Deze begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.',
        'translate.the_card_game_standard.guest.timer.message1': '{{playerName}}, kun jij kijken of er rechts in beeld een deurmat aan het begin en eind van elke video ligt? Ik zal ook proberen om wat aantekeningen te maken.',
        'translate.the_card_game_standard.guest.timer.message2': 'Ik heb wat aantekeningen gemaakt die ik met je kan delen. Nummer 31 eindigt met 1 kopje, maar zonder deurmat. 98 begint met 2 kopjes zonder deurmat en eindigt met 1 kopje en een deurmat. 22 begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.',
        'translate.the_card_game_standard.guest.timer.message3': 'De tweede video zou moeten beginnen met 1 kopje en zonder deurmat en eindigen met 2 kopjes en met een deurmat. De derde video is nummer 22. Deze begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.',
        'translate.the_card_game_standard.player.hint.explanation': "Er zijn 6 video opnames die in de juiste volgorde moeten worden geplaatst. Kijk zelf goed naar de deurmat die rechts in beeld op de grond ligt en laat je medespeler letten op de koffiekopjes. Wat is de situatie aan het begin en einde van elke video?",
        'translate.the_card_game_standard.player.hint.hint1': "Nummer 31 eindigt met 1 kopje maar zonder deurmat. 98 begint met 2 kopjes zonder deurmat en eindigt met 1 kopje en een deurmat. 22 begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.",
        'translate.the_card_game_standard.player.hint.hint2': "De tweede video zou moeten beginnen met 1 kopje en zonder deurmat en eindigen met 2 kopjes en met een deurmat. De derde video is nummer 22. Deze begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.",
        'translate.the_card_game_standard.player.hint.solution': "De volgorde is 31-84-22-17-98-56. <br> Als je kijkt naar het aantal kopjes op het aanrecht en of er rechts in beeld een deurmat ligt, kun je zien dat het einde van de ene video aansluit op het begin van een andere. Er is maar 1 volgorde mogelijk. 31: begin (geen kopjes en wel een deurmat), einde (1 kopje en geen deurmat).  84 (begin: 1 kopje en geen deurmat), einde (2 kopjes en een deurmat).  22: begin (2 kopjes en een deurmat), einde (3 kopjes en een deurmat). 17: begin (3 kopjes en een deurmat, einde (2 kopjes en geen deurmat). 98: begin (2 kopjes en geen deurmat), einde (1 kopje en wel een deurmat). 56: begin (1 kopje en een deurmat) einde (1 kopje en een deurmat).",
        'translate.the_card_game_standard.guest.hint.explanation': "Er zijn 6 video opnames die in de juiste volgorde moeten worden geplaatst. Kijk zelf goed naar de koffiekopjes en laat je medespeler letten op de deurmat die rechts op de grond ligt. Wat is de situatie aan het begin en einde van elke video?",
        'translate.the_card_game_standard.guest.hint.hint1': "Nummer 31 eindigt met 1 kopje maar zonder deurmat. 98 begint met 2 kopjes zonder deurmat en eindigt met 1 kopje en een deurmat. 22 begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.",
        'translate.the_card_game_standard.guest.hint.hint2': "De tweede video zou moeten beginnen met 1 kopje en zonder deurmat en eindigen met 2 kopjes en met een deurmat. De derde video is nummer 22. Deze begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.",
        'translate.the_card_game_standard.guest.hint.solution': "De volgorde is 31-84-22-17-98-56. <br> Als je kijkt naar het aantal kopjes op het aanrecht en of er rechts in beeld een deurmat ligt, kun je zien dat het einde van de ene video aansluit op het begin van een andere. Er is maar 1 volgorde mogelijk. 31: begin (geen kopjes en wel een deurmat), einde (1 kopje en geen deurmat).  84 (begin: 1 kopje en geen deurmat), einde (2 kopjes en een deurmat).  22: begin (2 kopjes en een deurmat), einde (3 kopjes en een deurmat). 17: begin (3 kopjes en een deurmat, einde (2 kopjes en geen deurmat). 98: begin (2 kopjes en geen deurmat), einde (1 kopje en wel een deurmat). 56: begin (1 kopje en een deurmat) einde (1 kopje en een deurmat).",
        'translate.the_card_game_standard.message1.title': "Er staat een bewakingscamera in de hoek. Werkt nog met tapes. Als we de volgorde van de beelden weten zien we misschien wie John heeft vergiftigd.",
        'translate.the_card_game_standard.message2.title': "Kijk of je iets ziet wat ons kan helpen om de beelden in de juiste volgorde te plaatsen.",
        'translate.the_card_game_standard.message2.error': "Deze volgorde klopt niet volgens mij, de videos sluiten niet op elkaar aan. Kun je nog een keer kijken? Gebruik bovenstaande TAG ANTWOORDEN link opnieuw.",
        'translate.the_card_game_standard.message3.title': "Dank, zo sluiten ze op elkaar aan. Ik zal ze even achter elkaar zetten.",
        'translate.the_card_game_standard.guest_message1.title': "Er staat een bewakingscamera in de hoek. Werkt nog met tapes. Als we de volgorde van de beelden weten zien we misschien wie John heeft vergiftigd.",
        'translate.the_card_game_standard.guest_message2.title': "Kijk of je iets ziet wat ons kan helpen om de beelden in de juiste volgorde te plaatsen.",
        'translate.the_card_game_standard.guest_message2.error': "Deze volgorde klopt niet volgens mij, de videos sluiten niet op elkaar aan. Kun je nog een keer kijken? Gebruik bovenstaande TAG ANTWOORDEN link opnieuw.",
        'translate.the_card_game_standard.guest_message3.title': "Dank, zo sluiten ze op elkaar aan. Ik zal ze even achter elkaar zetten.",

        'translate.the_card_game_challenging.timer.title': "6. Who did it - A Toxic Case",
        'translate.the_card_game_challenging.timer.subtitle': "De volgende opdracht start over:",
        'translate.the_card_game_challenging.player.timer.message1': 'Ik heb wat aantekeningen gemaakt die ik met je kan delen. De eerste video begint zonder kopjes op het aanrecht en met een deurmat rechts in beeld. Aan het einde van deze video staat er 1 kopje op het aanrecht en is de deurmat weg.',
        'translate.the_card_game_challenging.player.timer.message2': 'De tweede video zou moeten beginnen met 1 kopje en zonder deurmat en eindigen met 2 kopjes en met een deurmat. De derde video is nummer 22. Deze begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.',
        'translate.the_card_game_challenging.guest.timer.message1': 'Ik heb wat aantekeningen gemaakt die ik met je kan delen. De eerste video begint zonder kopjes op het aanrecht en met een deurmat rechts in beeld. Aan het einde van deze video staat er 1 kopje op het aanrecht en is de deurmat weg.',
        'translate.the_card_game_challenging.guest.timer.message2': 'De tweede video zou moeten beginnen met 1 kopje en zonder deurmat en eindigen met 2 kopjes en met een deurmat. De derde video is nummer 22. Deze begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.',
        'translate.the_card_game_challenging.player.hint.explanation': "Er zijn 6 video opnames die in de juiste volgorde moeten worden geplaatst. Kijk zelf goed naar de deurmat rechts in beeld en laat je medespeler letten op de koffiekopjes. Wat is de situatie aan het begin en einde van elke video?",
        'translate.the_card_game_challenging.player.hint.hint1': "De eerste video start zonder koffiekopjes op het aanrecht en met rechts een deurmat in beeld. Aan het einde van deze video staat er een kopje en is de deurmat weg.",
        'translate.the_card_game_challenging.player.hint.hint2': "De tweede video start met 1 koffiekopje en zonder mat en eindigt met 2 kopjes en een mat. De derde video is 22. Aan het begin van deze video staan er 2 kopjes en een deurmat en aan het einde staan er drie kopjes en een mat op de grond",
        'translate.the_card_game_challenging.player.hint.solution': "De volgorde is 31-84-22-17-98-56. <br> Als je kijkt naar het aantal kopjes op het aanrecht en of er rechts in beeld een deurmat ligt, kun je zien dat het einde van de ene video aansluit op het begin van een andere. Er is maar 1 volgorde mogelijk. 31: begin (geen kopjes en wel een deurmat), einde (1 kopje en geen deurmat).  84 (begin: 1 kopje en geen deurmat), einde (2 kopjes en een deurmat).  22: begin (2 kopjes en een deurmat), einde (3 kopjes en een deurmat). 17: begin (3 kopjes en een deurmat, einde (2 kopjes en geen deurmat). 98: begin (2 kopjes en geen deurmat), einde (1 kopje en wel een deurmat). 56: begin (1 kopje en een deurmat) einde (1 kopje en een deurmat).",
        'translate.the_card_game_challenging.guest.hint.explanation': "Er zijn 6 video opnames die in de juiste volgorde moeten worden geplaatst. Kijk zelf goed naar de koffiekopjes en laat je medespeler letten op de deurmat rechts in beeld. Wat is de situatie aan het begin en einde van elke video?",
        'translate.the_card_game_challenging.guest.hint.hint1': "De eerste video start zonder koffiekopjes op het aanrecht en met rechts een deurmat in beeld. Aan het einde van deze video staat er 1 kopje en is de deurmat weg.",
        'translate.the_card_game_challenging.guest.hint.hint2': "De tweede video begint met 1 koffiekopje en zonder deurmat en eindigt met 2 kopjes en een deurmat. De derde video is 22. Aan het begin van deze video staan er 2 kopjes en een deurmat en aan het einde staan er drie kopjes en een mat op de grond.",
        'translate.the_card_game_challenging.guest.hint.solution': "De volgorde is 31-84-22-17-98-56. <br> Als je kijkt naar het aantal kopjes op het aanrecht en of er rechts in beeld een deurmat ligt, kun je zien dat het einde van de ene video aansluit op het begin van een andere. Er is maar 1 volgorde mogelijk. 31: begin (geen kopjes en wel een deurmat), einde (1 kopje en geen deurmat).  84 (begin: 1 kopje en geen deurmat), einde (2 kopjes en een deurmat).  22: begin (2 kopjes en een deurmat), einde (3 kopjes en een deurmat). 17: begin (3 kopjes en een deurmat, einde (2 kopjes en geen deurmat). 98: begin (2 kopjes en geen deurmat), einde (1 kopje en wel een deurmat). 56: begin (1 kopje en een deurmat) einde (1 kopje en een deurmat).",
        'translate.the_card_game_challenging.message1.title': "Er staat een bewakingscamera in de hoek. Werkt nog met tapes.",
        'translate.the_card_game_challenging.message2.title': "Als we de volgorde van de beelden weten zien we misschien wie John heeft vergiftigd.",
        'translate.the_card_game_challenging.message2.error': "Deze volgorde klopt niet volgens mij, de videos sluiten niet op elkaar aan. Kun je nog een keer kijken? Gebruik bovenstaande TAG ANTWOORDEN link opnieuw.",
        'translate.the_card_game_challenging.message3.title': "Dank, zo sluiten ze op elkaar aan. Ik zal ze even achter elkaar zetten.",
        'translate.the_card_game_challenging.guest_message1.title': "Er staat een bewakingscamera in de hoek. Werkt nog met tapes.",
        'translate.the_card_game_challenging.guest_message2.title': "Als we de volgorde van de beelden weten zien we misschien wie John heeft vergiftigd.",
        'translate.the_card_game_challenging.guest_message2.error': "Deze volgorde klopt niet volgens mij, de videos sluiten niet op elkaar aan. Kun je nog een keer kijken? Gebruik bovenstaande TAG ANTWOORDEN link opnieuw.",
        'translate.the_card_game_challenging.guest_message3.title': "Dank, zo sluiten ze op elkaar aan. Ik zal ze even achter elkaar zetten.",
    }
);

export default nlPuzzleParadise;

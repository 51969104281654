import {useQuery} from "@tanstack/react-query";
import {QUERY_KEY} from "../constants/queryKeys";
import gameApiV2 from "../api/gameApiV2";
import {useEffect} from "react";
import {useRecoilState} from "recoil";
import {maxPollAttemptsState, pollAttemptsState} from "../recoil_state";

const useCheckPuzzleState = (roomId, puzzleId, memberId, enabled) => {
    const [maxPollAttempts] = useRecoilState(maxPollAttemptsState);
    const [pollAttempts, setPollAttempts] = useRecoilState(pollAttemptsState);

    useEffect(() => {
        if (enabled) {
            setPollAttempts(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabled]);

    const result = useQuery({
        queryKey: QUERY_KEY.puzzleState(roomId, puzzleId, memberId),
        queryFn: () => {
            setPollAttempts(prev=> prev +1)
            return gameApiV2.fetchPuzzleState(roomId, puzzleId);
        },
        enabled: !!enabled && !!roomId && !!puzzleId && !!memberId && pollAttempts <= maxPollAttempts,
        refetchInterval: 1_000, // 1s
    })

    return ({
        ...result
    })
}

export default useCheckPuzzleState;
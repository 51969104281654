import {useMutation} from "@tanstack/react-query";
import gameApiV2 from "../api/gameApiV2";

const useKickMember = () => {
    const mutation = useMutation({
        mutationFn: ({roomId, memberId}) => {
            return gameApiV2.kickMember(roomId, memberId)
        },
    })

    return ({
        ...mutation
    })
}

export default useKickMember;
const enPuzzleParadise = (
    {
        'translate.game.paradise.instructions.instruction1': `
            How to play<br/>
            <br/>Paradise is a cooperative escape room about a missing person case. To play the game, each player needs their own smartphone.
            <br/>Each player will receive different information about the case and a question they will need to answer. Together you will watch a video about the case. You will need to work together to solve the challenge.
            <br/>Each video has an introduction, which is part of the story and then shows information about the question you have to answer. The information is repeated multiple times, so you won't have to restart the video.
        `,
        'translate.game.paradise.instructions.instruction2': `
            Rules and options<br/>
            <br/>When you start a puzzle, a timer will start. You can try to solve the challenges as fast as possible in order to receive the most points. Or you can ignore the score and take as long as you like.
            <br/>If you don't understand a challenge, you can get a hint. Each challenge has an explanation hint, which tells you more about what you need to do, and two hints that help you with the actual puzzle.
            <br/>If the hints are not enough to solve the puzzle, you can click the Solution option to read the answer.
            <br/>Your score is determined by the time you spent, wrong answers and which hints were used. It does not matter if more people look at the same hint.
            <br/>If you experience any problems, please ask the main player to press Problem. This will reset the challenge.
        `,
        'translate.game.paradise.instructions.instruction3': `
            Video setup<br/>
            <br/>There are two ways that you can watch the videos, on Vimeo (recommended) or Youtube. We recommend you watch them on your TV, but you can use any other screen as well.
            <br/>For Vimeo, we suggest using another device if you have one. Go to vimeo.com/showcase/EDIT, click Start Watching and stream it to your television.
            <br/>For Youtube, you can enter the following link in your browser: EDIT . 
            <br/>After you completed this setup you can click continue and start playing the game.
         `,
        'translate.game.paradise.instructions.btnText': 'Continue',

        'translate.game.paradise.puzzle_name.intro1': "Intro 1",
        'translate.game.paradise.puzzle_name.intro2': "Intro 2",
        'translate.game.paradise.puzzle_name.the_knight': "The Knight",
        'translate.game.paradise.puzzle_name.the_blackout': "The Blackout",
        'translate.game.paradise.puzzle_name.the_witnesses': "The Witnesses",
        'translate.game.paradise.puzzle_name.the_key': "The Key",
        'translate.game.paradise.puzzle_name.the_interrogation': "The Interrogation",
        'translate.game.paradise.puzzle_name.the_card_game': "The Card Game",
        'translate.game.paradise.puzzle_name.the_missing_items': "The Missing Items",
        'translate.game.paradise.puzzle_name.the_traces': "The Traces",
        'translate.game.paradise.puzzle_name.the_security_tapes': "The Security Tapes",
        'translate.game.paradise.puzzle_name.whodunnit': "Whodunnit",

        'translate.paradise.1_intro.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/coverintro1.webp",

        'translate.paradise.1_intro.2p.message.title': "Hi {{playerName}}! Welcome to Couch Clues! Before you start, make sure that both players have a pen and paper.  You will learn the game by playing two quick intro challenges with {{partnerName}}. Open the menu on the top right of this screen, read the instructions and set up the video.",
        'translate.paradise.1_intro.345p.message.title': "Hi {{playerName}}! Welcome to Couch Clues! Before you start, make sure that each player has a pen and paper.  You will learn the game by playing two quick intro challenges with your fellow players. Open the menu on the top right of this screen, read the instructions and set up the video.",
        'translate.paradise.1_intro.message2.title': "Have you set up the video? Then start the Playlist A Toxic Case. It should start with Intro 1. Select ENG subtitles and follow the instructions on the Intro 1 video. Your goal is to send me a 6 digit code.",
        'translate.paradise.1_intro.message2.errors': "That is not the right answer, but don't worry, this is just practice. Just a quick check. Are you both watching Youtube or Vimeo? Have you found the channel 'CouchClues' and the Playlist 'A Toxic Case'? And are you watching Intro 1? Then open the menu on the top right of your app, open explanation, then hint 1 and then hint 2.",
        'translate.paradise.1_intro.message3.title': "Well done! Now you can pause the video and click the results button below. Your score is determined by the time, hints and wrong answers. If someone uses a hint, all players can look at it and it will count only once.",

        'translate.paradise.1_intro.hint.explanation': "Every puzzle has an explanation. An explanation will explain the puzzle if you have no idea what to do. For this intro 1 challenge, you have to find the same 6 digit number. To find it, all should click hint 1.",
        'translate.paradise.1_intro.23p.player.hint.hint1': "The first two digits of the code are 96. Make sure to share this information. Communication is key in this game! Now check hint 2.",
        'translate.paradise.1_intro.2p.guest01.hint.hint1': "The last two digits are 32. Make sure to share this with your partner. This game is all about talking to each other! Now check hint 2.",
        'translate.paradise.1_intro.345p.guest01.hint.hint1': "The 5th digit is a 3. Make sure to share this with your partners. Communication is key in this game! Now check hint 2.",
        'translate.paradise.1_intro.345p.guest02.hint.hint1': "The last digit is a 2. Make sure to share this with your partners. Communication is key in this game! Now check hint 2.",
        'translate.paradise.1_intro.45p.player.hint.hint1': "The first digit is a 9. Make sure to share this with your partners. Communication is key in this game! Now check hint 2.",
        'translate.paradise.1_intro.45p.guest03.hint.hint1': "The second digit is a 6. Make sure to share this with your partners. Communication is key in this game! Now check hint 2.",
        'translate.paradise.1_intro.5p.guest04.hint.hint1': "The third digit is a 1. Make sure to share this with your partner. Communication is key in this game! Now check hint 2.",

        'translate.paradise.1_intro.2p.hint.hint2': "Did your partner tell you the other numbers? The middle digits are 11. You can now solve the puzzle, or check the solution to be sure. If you choose to view the solution in the actual game, you won't earn any points for that challenge.",
        'translate.paradise.1_intro.34p.hint.hint2': "Did your partners tell you the other numbers? The middle digits are 11. You can now solve the puzzle, or check the solution to be sure. If you choose to view the solution in the actual game, you won't earn any points for that challenge.",
        'translate.paradise.1_intro.5p.hint.hint2': "Did your partners tell you the other numbers? The fourth digit is 1. You can now solve the puzzle, or check the solution to be sure. If you choose to view the solution in the actual game, you won't earn any points for that challenge.",

        'translate.paradise.1_intro.234p.hint.solution': "The solution is 961132. <br> Each player received a different first hint showing part of the code. If you shared all the information you would have gotten the first two digits (96) and the last two digits (32). The second hint showed the same information for all, which is that the middle digits were 11.",
        'translate.paradise.1_intro.5p.hint.solution': "The solution is 961132. <br> Each player received a different first hint showing part of the code. If you shared all the information you would have gotten the first three digits (961) and the last two digits (32). The second hint showed the same information for all, which is that the fourth is a 1.",

        'translate.paradise.2_intro.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/coverintro2.webp",

        'translate.paradise.2_intro.timer.title': "Intro 2 - A Toxic Case",
        'translate.paradise.2_intro.timer.subtitle': "You will receive more information in:",
        'translate.paradise.2_intro.message1.title': "Now it's time for a real practice puzzle. You will receive some images below, try to zoom in on them by tapping on the image and then pinching your fingers.",
        'translate.paradise.2_intro.message2.title': "Look at the Intro 2 video. Your goal is to find a five digit code.",
        'translate.paradise.2_intro.message2.error': "That is not the right code. To try again you have to use the TAG ANSWERS link in the message above to select a different answer.",
        'translate.paradise.2_intro.message3.title': "That's right! You will now continue to the real game. Have fun together!",

        'translate.paradise.2_intro.timer.message1': 'During the game you sometimes get an additional message like this one. These messages contain information from the detective that can help you. If you do not want to receive these messages, the main player can turn them off in the menu.',

        'translate.paradise.2_intro.hint.explanation': "Your goal is to find a 5 digit number. You will have to tell each other what you see on your phone and match that with what you see on television. Select the labels in the right order and press submit.",
        'translate.paradise.2_intro.hint.hint1': "The first digit is 5.",
        'translate.paradise.2_intro.hint.hint2': "The order on the television is Yellow, Red, Blue, Green, Grey. Which numbers match these colours?",
        'translate.paradise.2_intro.hint.solution': "The solution is Five, Nine, Seven, One, Four. You will have to select the labels in that order. <br> The order of the colours on the television is Yellow, Red, Blue, Green,Grey. The yellow number is a 5, the red number is a 9, the blue number a 7, the green number a 1 and the grey number a 4.",

        'translate.paradise.2_intro.four.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/intro2four.webp",
        'translate.paradise.2_intro.five.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/intro2five.webp",
        'translate.paradise.2_intro.seven.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/intro2seven.webp",
        'translate.paradise.2_intro.one.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/intro2one.webp",
        'translate.paradise.2_intro.nine.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/intro2nine.webp",

        'translate.paradise.2_intro.option1': "One",
        'translate.paradise.2_intro.option2': "Two",
        'translate.paradise.2_intro.option3': "Three",
        'translate.paradise.2_intro.option4': "Four",
        'translate.paradise.2_intro.option5': "Five",
        'translate.paradise.2_intro.option6': "Six",
        'translate.paradise.2_intro.option7': "Seven",
        'translate.paradise.2_intro.option8': "Eight",
        'translate.paradise.2_intro.option9': "Nine",
        'translate.paradise.2_intro.option10': "Zero",

        'translate.the_knight.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIPcover1.webp",

        'translate.the_knight.timer.title': "1. The Knight - A Case In Paradise",
        'translate.the_knight.timer.subtitle': "The challenge will start in:",
        'translate.the_knight.message1.title': "Let’s figure out what happened here. We need to compare how it looks now with the camera recordings from yesterday. I wrote down the items. {{playerName}}, can you find which items on your list were moved?",
        'translate.the_knight.2p.message2.title': "Ask {{partnerName}} for the numbers of their items and let me know what changed.",
        'translate.the_knight.345p.message2.title': "Ask your partners for the numbers of their items and let me know what changed.",
        'translate.the_knight.message2.error': "That doesn't match what I'm seeing here. We need to look again. I think five items have been moved.",
        'translate.the_knight.message3.title': "Thank you. I will try to make sense of what happened here.",
        'translate.the_knight.timer.message1': 'There are five items that have been moved.',
        'translate.the_knight.timer.message2': 'The camera that shows the statue with a hat, two items can be seen that are moved. One item is moved on the camera position that shows the chessboard and two items have been moved on the camera position that shows the chair.',
        'translate.the_knight.hint.explanation': "You need to compare the security camera footage with the footage of Max moving around the room. There are four areas. Try to see if something changed in an area and if so, what changed. You will need to give the same answer, so ask the other player for their items. You can help them to see if something changed.",
        'translate.the_knight.hint.hint1': "There are five items that have been moved.",
        'translate.the_knight.hint.hint2': "The camera that shows the statue with a hat, two items can be seen that are moved. One item is moved on the camera position that shows the chessboard and two items have been moved on the camera position that shows the chair.",
        'translate.the_knight.hint.solution': "16,70,34,17,97. Five things have changed, bowl, candle holder, vase, book and rug. On the camera position that shows that statue with the hat, you can see that the bowl has fallen on the floor and that the candle holder has been turned around). On the camera position with the chessboard, you can see the top of the vase has been moved to another leg of the table. On the camera position with the chair, you see the book is flat instead of standing up and the rug has moved.",

        'translate.the_knight.ACIP1list2-m.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP1list2-m.webp",
        'translate.the_knight.ACIP1list2-g.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP1list2-g.webp",


        'translate.the_blackout.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIPcover2.webp",

        'translate.the_blackout.timer.title': "2. The Blackout - A Case in Paradise",
        'translate.the_blackout.timer.subtitle': "The challenge will start in:",
        'translate.the_blackout.message1.title': "So the blackout disabled the power to the alarm… Apparently, if all lights and fans are on, it causes a blackout",
        'translate.the_blackout.2p.player.message2.title': "Let’s see if we can recreate this blackout. All lights and fans are turned off now, so let me know what the easiest way is to turn them all on. {{playerName}}, you focus on the lights, {{partnerName}}, you focus on the fans.",
        'translate.the_blackout.2p.guest.message2.title': "Let’s see if we can recreate this blackout. All lights and fans are turned off now, so let me know what the easiest way is to turn them all on. {{partnerName}}, you focus on the fans, {{partnerName}}, you focus on the lights.",
        'translate.the_blackout.345p.message2.title': "Let’s see if we can recreate this blackout. All lights and fans are turned off now, so let me know what the easiest way is to turn them all on.",

        'translate.the_blackout.message2.error': "I think we need to check again. I suspect only 3 actions are needed to create a blackout. Can you check again for me, using the TAG ANSWER link above?",
        'translate.the_blackout.message3.title': "That makes sense. Now let's see if this actually causes a blackout.",
        'translate.the_blackout.timer.message1': 'There are only 3 actions needed to recreate this blackout.',
        'translate.the_blackout.timer.message2': 'You only need to use two of the remotes and one switch to cause a blackout.',
        'translate.the_blackout.hint.explanation': "There are three rooms (bar, lobby, hallway) and there is a lightswitch and a remote in each room. So there are 6 actions that can be taken. You need to notice the effects of each action and then figure out how to create the blackout. There are same lights and fans that can only be turned by one specific actions. Focus on those first.",
        'translate.the_blackout.hint.hint1': "There are only 3 actions needed to recreate this blackout.",
        'translate.the_blackout.hint.hint2': "You only need to use two of the remotes and one switch to cause a blackout.",
        'translate.the_blackout.hint.solution': "You can create a blackout using the switch in the hallway, the remote in the lobby and the remote in the bar. The overview of all effects is this: Bar Fan and Hall Light (Hallway switch)Bar Fan and Lobby Fan (Hallway remote)Bar Light and Hall Light (Lobby switch)Bar Light and Lobby Fan (Bar switch)Bar Light and Lobby Light (Bar remote)Lobby Fan and Hall Fan (Lobby remote)Using the remote in the lobby is the only way to turn the fan in the hall on. This also has the effect of turning the fan in the lobby on, which means that you cannot use the hallway remote or the switch in the bar (it would turn the fan in the lobby back off). Now only one way remains to turn on the fan in the bar (hallway switch), and after that you are left with the light in the lobby and the light in the bar which matches the bar remote.",

        'translate.the_blackout.ACIP2fan.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP2fan.webp",
        'translate.the_blackout.ACIP2light.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP2light.webp",

        'translate.the_witnesses.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIPcover3.webp",

        'translate.the_witnesses.timer.title': "3. The Witnesses - A Case in Paradise",
        'translate.the_witnesses.timer.subtitle': "The challenge will start in:",
        'translate.the_witnesses.message1.title': "These witnesses claim to be in their room during the blackout. Let’s see if their stories check out.",
        'translate.the_witnesses.message2.title': "Can you place each witness in a specific room at the moment of the Blackout?",
        'translate.the_witnesses.message2.error': "That doesn’t completely match with their statements. Please check it again using the TAG ANSWER link above.",
        'translate.the_witnesses.message3.title': "That makes sense. This means that each of these witnesses had an alibi during the blackout. Let’s meet the other guests that were present that night.",
        'translate.the_witnesses.timer.message1': 'Daniel is in room 201.',
        'translate.the_witnesses.timer.message2': 'Justin is in room 202. ',
        'translate.the_witnesses.hint.explanation': "You need to place each guest in the right room. Start by figuring out which people are on the top or bottom floor.",
        'translate.the_witnesses.hint.hint1': "Daniel is in room 201.",
        'translate.the_witnesses.hint.hint2': "Justin is in room 202.",
        'translate.the_witnesses.hint.solution': "102-204-201-104-103-202. Daniel says that there was noise in his room, but that he heard no one below him and Gabrielle didn’t hear anything below her. This places them both on the top floor, but not next to each other. Liz hears an opera singer, so she is below Gabrielle. Thomas makes noise and hears noise above him, so he is on the bottom floor, but not below Daniel or Gabrielle (they would have heard him otherwise). Jane hears nothing in the room above so she must be in 103. This leaves Justin as the person who made noise and was in the room above Thomas. Gabrielle didn’t hear anything next to her, so so must be in 204 and therefore Liz is in 104. Thomas is next to Jane and therefore in 102. This means Justin is in 202 and that leaves Daniel in 201.",

        'translate.the_witnesses.ACIP3Jane101.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP3Jane101.webp",
        'translate.the_witnesses.ACIP3Justin203.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP3Justin203.webp",
        'translate.the_witnesses.ACIP3Jane.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP3Jane.webp",
        'translate.the_witnesses.ACIP3Justin.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP3Justin.webp",
        'translate.the_witnesses.ACIP3rooms.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP3rooms.webp",
        'translate.the_witnesses.gap_fill_text': "Thomas is staying in room <gap />. Gabrielle is staying in room <gap />.  Daniel is staying in room <gap />. Liz is staying in room <gap />. Jane is staying in room <gap />. Justin is staying in room <gap />.",


        'translate.the_key.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIPcover4.webp",

        'translate.the_key.timer.title': "4. The Key - A Case in Paradise",
        'translate.the_key.timer.subtitle': "The challenge will start in:",
        'translate.the_key.message1.title': "Well, now we know why the door was open. Someone took the spare key. Let’s see what he remembers about that night.",
        'translate.the_key.message2.title': "Who had their hands on the key? And in what order?",
        'translate.the_key.message2.error': "I think it happened in a different way. The way he tells it, I think someone had the key twice. Use the TAG ANSWER link above and let me know.",
        'translate.the_key.message3.title': "That matches his story. So the Lady took it... Let’s hear what she has to say.",
        'translate.the_key.timer.message1': 'The lady took the bottle opener twice.',
        'translate.the_key.timer.message2': 'The Boy is the only one who never had the key.',
        'translate.the_key.hint.explanation': "You need to look closely at the video. Who took the bottle opener in the beginning and who had it in the end. You might not always see the opener, so sometimes you will have to deduct who might have taken it in the meantime.",
        'translate.the_key.hint.hint1': "The lady took the bottle opener twice.",
        'translate.the_key.hint.hint2': "The Boy is the only one who never had the key.",
        'translate.the_key.hint.solution': "Lady -  Caregiver - Baron - Peach - Lady. In the beginning of the scene you see the lady trying to open the bottle of the Boy. She messes up and leaves the key on the counter for the Caregiver to take it. You see the baron move towards the hands of the  Caregiver and then later you see Peach having the opener. Peach did not take it from the  Caregiver, so she would have taken it from the Baron. After Peach drops the opener, both Peach and the Lady drop to the floor, after which Peach shows empty hands.",

        'translate.the_key.characterlist.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIPcharacterlist.webp",
        'translate.the_key.gap_fill_text': "The <gap /> first took the key. Then <gap /> took it. The third person who took the key was <gap />. Then <gap /> took the key. In the end, the key ended up with <gap />.",


        'translate.the_interrogation.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIPcover5.webp",

        'translate.the_interrogation.timer.title': "5. The Interrogation - A Case in Paradise",
        'translate.the_interrogation.timer.subtitle': "The challenge will start in:",
        'translate.the_interrogation.message1.title': "So the Lady took the spare key. But why? Lets see if she is willing to tell us the truth. {{playerName}}, I will send you information about signs that indicate that she is lying.",
        'translate.the_interrogation.2p.message2.title': "If you focus on these two signs, {{partnerName}} will focus on the other signs. Let me know what she is lying about.",
        'translate.the_interrogation.345p.message2.title': "If you focus on this, your partners will focus on the other signs. Let me know what she is lying about.",
        'translate.the_interrogation.message2.error': "I believe that’s not the full story. I think there are five things she is lying about.",
        'translate.the_interrogation.message3.title': "This would mean she is not the thief, but she definitely knows more. Let’s see what she remembers about this card game.",
        'translate.the_interrogation.timer.message1': 'There are five things that she lies about.',
        'translate.the_interrogation.timer.message2': 'She touches her hair twice before answering, one time she looks up, one time she repeats the question and one time she touches her shoulder.',
        'translate.the_interrogation.hint.explanation': "You need to select the statements about which she is laying. To determine if she is lying you need to look out for the signs that each player have been given. Only if she uses one of these signs she is lying.",
        'translate.the_interrogation.hint.hint1': "There are five things that she lies about.",
        'translate.the_interrogation.hint.hint2': "She touches her hair twice before answering, one time she looks up, one time she repeats the question and one time she touches her shoulder.",
        'translate.the_interrogation.hint.solution': "AEIKL. There are five lies in total.  The first is that she doesn’t know what Max is talking about (she shortly touches her hair before answering). The second lie is that she just wanted to have a peek because she was just curious (she touches her shoulder). She third lie is that she caused the blackout by accident (she repeats the question). The fourth lie is that she has no motive to steal it (she quickly looks up). The final lie is that  she doesn’t know any of the other guests (she touches her hair).",

        'translate.the_interrogation.hairrepeat.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP5hairrepeat.webp",
        'translate.the_interrogation.lookingupshoulder.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP5lookingupshoulder.webp",
        'translate.the_interrogation.gap_fill_text': "She doesn’t know what Max is talking about (<gap />). She stole the key (<gap />). She did not steal the Knight (<gap />). She went inside (<gap />). She just wanted to have a peek (<gap />). She got scared and hid into a closet (<gap />). Someone came inside. She didn’t know who it was (<gap />). She hid until the lights came back on (<gap />). She caused the blackout by accident (<gap />). She has no interest in the value of the Knight (<gap />). She has no motive to steal the Knight  (<gap />). She doesn’t know any of the other guests (<gap />). She felt she was cheated during a card game (<gap />).",

        'translate.the_card_game.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIPcover6.webp",

        'translate.the_card_game.timer.title': "6. The Card Game - A Case in Paradise",
        'translate.the_card_game.timer.subtitle': "The challenge will start in:",
        'translate.the_card_game.message1.title': "So she says she was cheated. I think I recognize the game she is describing. I have sent you the rules below. Let’s hear what she can remember and see if we figure out how this game was played. If they were cheating we have to assume that the Boy and Peach knew each others cards, so feel free to communicate which cards you have.",
        'translate.the_card_game.2p.player.message2.title': "{{playerName}}, you play as the Boy and {{partnerName}}, you play as Peach. Let me know which cards were played in order for one person to win all 5 rounds.",
        'translate.the_card_game.2p.guest.message2.title': "{{playerName}}, you play as Peach and {{partnerName}}, you play as the Boy. Let me know which cards were played in order for one person to win all 5 rounds.",
        'translate.the_card_game.345p.guest.message2.title': "NOG EDIT.",

        'translate.the_card_game.message2.error': "That’s not right. They might have cheated with the cards, but the Lady said they did play by the rules. Only one person won all five rounds.",
        'translate.the_card_game.message3.title': "So that's how Peach won all five rounds. Classic. Lets hear what she has to say about this.",
        'translate.the_card_game.timer.message1': 'Only Peach has a Spades card, so she is the only one who can win all rounds. The Boy needs to find a way to lose some of his best cards so Peach can win each round. Think about which cards remain after you play each round.',
        'translate.the_card_game.timer.message2': 'The second card that is played by the dealer is a B (Jack) of Spades. The boy does not have any Spades, so he can play any card he wants. If he plays his Ace of Hearts, he can make sure he does not win with that card on a later turn.',
        'translate.the_card_game.hint.explanation': "You need to play the cards in an order that one player will win all five rounds. If you are unfamiliar with card games in general, please check the card rules first.",
        'translate.the_card_game.hint.hint1': "Only Peach has a Spades card, so she is the only one who can win all rounds. The Boy needs to find a way to lose some of his best cards so Peach can win each round. Think about which cards remain after you play each round.",
        'translate.the_card_game.hint.hint2': "The second card that is played by the dealer is a B (Jack) of Spades. The boy does not have any Spades, so he can play any card he wants. If he plays his Ace of Hearts, he can make sure he does not win with that card on a later turn.",
        'translate.the_card_game.hint.solution': "The Boy played EGFDC and Peach played MPNLO. The Lady plays a Jack (B) of Spades, so the only person who can will all five rounds is Peach. Peach has a Queen of Spades and the Boy has no Spades. Peach needs to win the first round with a 10 of Hearts, the second with a Queen (V) of Spades, the third with a King of Hearts, the fourth with a 7 of Hearts and the fifth with a Queen (V) of Clubs. The first card the Boy can play is only the 8 of Hearts, to stay below the 10 of hearts of Peach. The Boy has to play a Hearts, because Hearts is played by the dealer. On the second turn, because The boy does not have any Spades, he can now dump one of his best cards. He needs to play his Ace of Hearts, because if he keeps his Ace of Hearts he will win the round on the third of fourth card. The third round he now has only one Heart remaining, so he must play Jack (B) of Hearts. The fourth round he does not have any Hearts, so he can use this turn to dump his King of Clubs. The last turn he only has a Jack of Clubs which loses to Peaches Queen (V) of Clubs.",

        'translate.the_card_game.rules.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6rules.webp",
        'translate.the_card_game.Bclubsc.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6bclubsc.webp",
        'translate.the_card_game.Kclubsd.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6kclubsd.webp",
        'translate.the_card_game.8heartse.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP68heartse.webp",
        'translate.the_card_game.Bheartsf.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6bheartsf.webp",
        'translate.the_card_game.Aheartsg.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6aheartsg.webp",
        'translate.the_card_game.7heartsl.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP67heartsl.webp",
        'translate.the_card_game.10heartsm.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP610heartsm.webp",
        'translate.the_card_game.Kheartsn.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6kheartsn.webp",
        'translate.the_card_game.Vclubso.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6vclubso.webp",
        'translate.the_card_game.Vspadesp.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6vspadesp.webp",
        'translate.the_card_game.player.gap_fill_text': "He played <gap /> the first round. The second round he played <gap />. The third round he played <gap />. The fourth round <gap /> and the final round <gap />. Peach played 1. <gap />  2.  <gap /> 3.  <gap /> 4. <gap /> 5. <gap />.",
        'translate.the_card_game.guest.gap_fill_text': "She played <gap /> the first round. The second round she played <gap />. The third round she played <gap />. The fourth round <gap /> and the final round <gap />. The Boy played 1. <gap />  2.  <gap /> 3.  <gap /> 4. <gap /> 5. <gap />.",


        'translate.the_missing_items.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIPcover7.webp",

        'translate.the_missing_items.timer.title': "7. The Missing Items - A Case in Paradise",
        'translate.the_missing_items.timer.subtitle': "The challenge will start in:",
        'translate.the_missing_items.message1.title': "So this Boy steals items from other guests to give them to Peach? Let’s check out his room and see what he might have stolen. I have one note from the owner with notes about the missing items and another one from Peach with her favorite colors.",
        'translate.the_missing_items.2p.player.message2.title': "Let's find the items that match both the description of the owner and the favorite colours of Peach. {{playerName}}, I have sent you the list of her favorite colours. I sent {{partnerName}} a list with missing items from the owner.",
        'translate.the_missing_items.2p.guest.message2.title': "Let's find the items that match both the description of the owner and the favorite colours of Peach. {{playerName}}, I have sent you the list of the missing items from the owner. I sent {{partnerName}} a list with Peaches favorite colours.",
        'translate.the_missing_items.message2.error': "I looked around, but there are some items that do not match. Can you check it again? Use the TAG ANSWER link above. ",
        'translate.the_missing_items.message3.title': "At least one mystery is solved. Strange way to impress someone...",
        'translate.the_missing_items.timer.message1': 'I think there are five items here that actually match',
        'translate.the_missing_items.timer.message2': 'There is a white lipstick with TP on it lying on the sofa.',
        'translate.the_missing_items.hint.explanation': "You need to find which items match both descriptions from the owner and from peach and give the correct match for each item.",
        'translate.the_missing_items.hint.hint1': "There are five items that actually match.",
        'translate.the_missing_items.hint.hint2': "There is a white lipstick with TP on it lying on the sofa. No ball shaped, striped or items with an L. And nothing that matches with red, green or blue.",
        'translate.the_missing_items.2p.player.hint.solution': "The answer is 8, nothing, nothing, 4,3, nothing, 2, 6. There is a golden bracelet with a pearl on it, nothing that matches with red or green, black lipstick with UNG on it, nothing that matches with blue,  white lipstick with TP on it and a yellow lipstick with a rectangular shape.",
        'translate.the_missing_items.2p.guest.hint.solution': "The answer nothing, G, E, D, nothing H, nothing, A. There is no ball shaped item, a white lipstick with TP on it, black lipstick with UNG on it, a silver purse with a V on it, no item with stripes, a yellow lipstick with a rectangular shape, nothing with the letter L on it and a golden bracelet with a pearl on it.",

        'translate.the_missing_items.listpeach.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP7listpeach.webp",
        'translate.the_missing_items.listowner.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP7listowner.webp",
        'translate.the_missing_items.player.gap_fill_text': "Gold matches with <gap />. Red matches with <gap />. Green matches with <gap />. Silver matches with <gap />. Black matches with <gap />. Blue matches with <gap />. White matches with <gap />. Yellow matches with <gap />.",
        'translate.the_missing_items.guest.gap_fill_text': "An item shaped like a ball matches <gap />. An item with TP on it matches <gap />. An item from the brand UNG matches <gap />. An item with a V matches <gap />. An item with stripes matches <gap />. A rectangular item matches <gap />. An item with the letter L on it matches <gap />. An item with a pearl matches <gap />.",


        'translate.the_trace.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIPcover8.webp",
        'translate.the_traces.timer.title': "8. Traces - A Case in Paradise",
        'translate.the_traces.timer.subtitle': "The challenge will start in:",
        'translate.the_traces.message1.title': "I just lost him. Pretty fast runner… ",
        'translate.the_traces.message2.title': "Let’s see if we can figure out where he went. Focus on the traces that he left.",
        'translate.the_traces.message2.error': "I have walked that route, but it doesn’t completely matches the traces he left. I think there were four things that happened during his flight.",
        'translate.the_traces.message3.title': "That means he ended up with on shoe in the storage attic. Let’s see if we can find him there",
        'translate.the_traces.timer.message1': 'There are four things that happened during his flight, each causing a change in the  traces he left. First he knocked over a vase with dried flowers. I know he still had 2 shoes on in hallway 241-249.',
        'translate.the_traces.timer.message2': 'I beleive during his flight four things happened, but not in this order; he loses his jacket, knocks over a vase, loses one shoe and steps into a bucket of water. The dried flowers sticked to his jacket and fell off until he dropped his jacket.',
        'translate.the_traces.hint.explanation': "You need to figure out in which order the Boy passed through the floors. On some floors something happened that changed his traces. First try to figure out what happened during his flight. Then focus on how the traces change after each thing that happen to determine the right order.",
        'translate.the_traces.hint.hint1': "There are four things that happened during his flight, each causing a change in the  traces he left. First he knocked over a vase with dried flowers. He still had 2 shoes on in hallway 241-249.",
        'translate.the_traces.hint.hint2': "During his flight four things happened (not in this order); he loses his jacket, knocks over a vase, loses one shoe and steps into a bucket of water. The dried flowers sticked to his jacket and fell off until he dropped his jacket.",
        'translate.the_traces.hint.solution': "(261-269). (151-159). Attic (empty). (251-259). (241-249). (141-149). Attic (storage). The first thing he does is that he knocks over a vase. You can see traces of the dried flowers neer the bucket in the empty attic, so any halls with wet footsteps had to come after this moment and the knocking over the vase happened before. Near room 252 you see dried flowers and wet footsteps until he loses his jacket, after which only the wet footprints remain. Near room 243 you only see wet footprints. Near room 142 you see wet footprints and one shoe, after which the traces show only one foot instead of two. On attic A  you only see one trace of one foot, which leaves this as the place that he is hiding.",

           
        'translate.the_security_tape.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIPcover9.webp",
        'translate.the_security_tapes.timer.title': "9. The Tapes- A Case in Paradise",
        'translate.the_security_tapes.timer.subtitle': "The challenge will start in:",
        'translate.the_security_tapes.message1.title': "The lights in the lobby were already on, so only the remote in the lobby and the switch in the hallway were enough to cause a blackout.",
        'translate.the_security_tapes.message2.title': "We need to figure out two things. Where was everybody before the blackout started? And who caused the blackout? ",
        'translate.the_security_tapes.message2.error': "That doesn’t match what I’m seeing here on the tapes. Can you check again?",
        'translate.the_security_tapes.message3.title': "So this is where they all were right before the blackout. Let’s see if we can finally figure out who stole the Knight.",
        'translate.the_security_tapes.timer.message1': 'The caregiver first goes to the bar and stays there. I think we need to focus on the order people enter and leave the bar.',
        'translate.the_security_tapes.timer.message2': 'The order in which they end up somewhere before the blackout is Caregiver, Baron, Peach, Owner, Lady and Boy. Peach is never seen on the camera near room 247.',
        'translate.the_security_tapes.hint.explanation': "You need to figure out where each person ended up before the blackout and in which order they ended up there. Focus on one person at a time to find out where the ended up.",
        'translate.the_security_tapes.hint.hint1': "The Caregiver first goes to the bar and stays there. Focus on the order people enter and leave the bar and where you do not see people on camera.",
        'translate.the_security_tapes.hint.hint2': "The order in which they end up in room before the blackout is Caregiver, Baron, Peach, Owner, Lady and Boy.",
        'translate.the_security_tapes.hint.solution': "First, the Caregiver ended up in the bar. Then the Baron ended up in the bar. Next Peach ended up in room 248, then the Owner ended up in the office and after that, the Lady ended up in the hallway. Finally, the Boy ended up in the lobby. The Caregiver used the remote in the lobby and the Lady used the switch in the hallway. The Caregiver is the first person you see upstairs, then using the remote in the lobby and moving to the bar. Peach leaves the bar before the owner and you never see her on camera near 247. The owner enters his office before the Lady.",

        'translate.the_security_tapes.map.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIPmap.webp",
        'translate.the_security_tapes.characterlist.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIPcharacterlist.webp",
        'translate.the_security_tapes.gap_fill_text': "Who ended up where, and in what order? First, <gap /> ended up <gap />.  Then <gap /> ended up <gap />. Next <gap /> ended up <gap />, then <gap /> ended up <gap /> and after that, <gap /> ended up <gap />. Finally, <gap /> ended up <gap />. We know <gap /> used the remote in the lobby and <gap /> used the switch in the hallway. They must work together.",


        'translate.whodunnit.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIPcover10.webp",
        'translate.whodunnit.timer.title': "10. Whodunnit - A Case in Paradise",
        'translate.whodunnit.timer.subtitle': "The challenge will start in:",
        'translate.whodunnit.message1.title': "We have all the facts. It is time to put it all together.",
        'translate.whodunnit.message2.title': "Let me know what you think happened.",
        'translate.whodunnit.message2.error': "Parts of that sound right, but I believe it is not the full story. Did you notice which things changed after the blackout?",
        'translate.whodunnit.message3.title': "Thank you. Well spotted. I believe it is time to confront them. Watch 11. Confrontation, before finishing the game.",
        'translate.whodunnit.timer.message1': 'There are two main things that changed after the blackout. One is clearly visible, the other one is a detail that has to do with one of the suspects.',
        'translate.whodunnit.timer.message2': 'There is a trail that leads from the front door to the bar. Focus on the suspects in recording 8 to notice the difference before the blackout.',
        'translate.whodunnit.hint.explanation': "You need to put together the case. Use the words provided and focus on finding the thief. You need to point to one specific recording that shows a crucial detail.",
        'translate.whodunnit.hint.hint1': "There are two main things that changed after the blackout. One is clearly visible, the other is a detail that has to do with one of the suspects.",
        'translate.whodunnit.hint.hint2': "There is a trail that leads from the front door to the bar. Focus on the suspects in recording 8 to notice the difference before the blackout.",
        'translate.whodunnit.hint.solution': "During the blackout, the thief first took out the Boy using chloroform. As the thief moved on to the living room, the Lady heard the thief approach and fled into a closet. Next, the thief entered the living room, knocked over a bowl and scared the Lady. The scream alerted the Owner, which meant the thief had to flee through the window. The thief ended up in the bar. Recording 8 shows the Baron now has white socks instead of his red shoes. His shoes got muddy because he had to flee through the window, so he took them off.",

        'translate.whodunnit.photoshoes.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP10photoshoes.webp",
        'translate.whodunnit.map.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIPmap.webp",
        'translate.whodunnit.characterlist.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIPcharacterlist.webp",
        'translate.whodunnit.gap_fill_text': "During the blackout, the thief first took out <gap /> using chloroform. As the thief moved on to the living room, <gap /> heard the thief approach and fled into a closet. Next, the thief entered the living room, knocked over <gap /> and scared <gap />. The scream alerted <gap />, which meant the thief had to flee through <gap />. The thief ended up in <gap />. Recording <gap /> shows crucial evidence that the thief is <gap />.",

    }
);

export default enPuzzleParadise;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'yet-another-react-lightbox/styles.css';
// import i18n (needs to be bundled ;))
// eslint-disable-next-line no-unused-vars
import './i18n';
import App from "./App";
// eslint-disable-next-line no-unused-vars
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'; //DO NOT REMOVE

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
      <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {alpha, Button, Stack} from "@mui/material";
import {modalStyle} from "../../style/modalStyle";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import * as React from "react";
import {useRecoilState} from "recoil";
import {DEFAULT_MAX_POLL_ATTEMPTS, maxPollAttemptsState, pollAttemptsState} from "../../recoil_state";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {usePuzzleContext} from "../../context/PuzzleContextProvider";

const YouHaveBeenKickedModal = () => {
    const { t } = useTranslation('youHaveBeenKickedModal');
    const {
        isMyMemberKicked,
    } = usePuzzleContext();
    const [isMyMemberInformedAboutKick, setIsMyMemberInformedAboutKick] = useState(false);
    const onConfirm = () => setIsMyMemberInformedAboutKick(true);
    return (
        <Modal
            open={isMyMemberKicked && !isMyMemberInformedAboutKick}
            slotProps={{ backdrop: { sx: { background: alpha('#000', 0.9) }} }}
        >
            <Stack sx={modalStyle} gap={2}>
                <Typography variant="h6" component="h2">
                    {t('title')}
                </Typography>
                <Typography id="modal-problem-body-1" variant="body6" sx={{color: alpha('#fff', 0.6)}}>
                    {t('description')}
                </Typography>
                <Button variant="contained" onClick={onConfirm}>
                    {t('buttonOk')}
                </Button>
            </Stack>
        </Modal>
    )
}

export default YouHaveBeenKickedModal;
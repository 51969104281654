import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Button, Stack} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    overflow: 'scroll',
    height: '80%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    boxShadow: 24,
    p: 4,
    backgroundColor: "#1B2436",
    color: 'white',
    borderRadius: '20px',
};

export default function InstructionsModal({ open, toggle, translationPrefix }) {
    const {
        t: tPuzzle
    } = useTranslation('puzzle', { keyPrefix: `${translationPrefix}.instructions`});

    return (
            <Modal
                open={open}
                onClose={toggle}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack direction="column" spacing={2}>
                        <Typography variant="body6" sx={{ color: 'white' }}>
                            <Trans i18nKey={tPuzzle('instruction1')} />
                        </Typography>
                        <Typography variant="body6" sx={{ color: 'white' }}>
                            <Trans i18nKey={tPuzzle('instruction2')} />
                        </Typography>
                        <Typography variant="body6" sx={{ color: 'white' }}>
                            <Trans i18nKey={tPuzzle('instruction3')} />
                        </Typography>
                    </Stack>
                    <Button size="large" variant="contained" sx={{width: '100%', marginTop: 4}} onClick={toggle}>
                        {tPuzzle('btnText')}
                    </Button>
                </Box>
            </Modal>
    );
}
